import axios from "axios";
import { appConfig } from "../configs/app-config";
import { loggerService } from "./logger";
import { store } from "../state-management/store";
import { triggerNotification } from "../state-management/actions";

/**
 * This service is responsible for making HTTP calls to the remote server.
 */
class HttpService {
  /**
   * This function logs the error gracefully to the end user, depending on what environment the application is running on.
   * The error includes any error that might occur while making an API call.
   * @param   {string} err  the error object that contains the error information.
   */
  triggerErrorNotification(err) {
    if (appConfig.isDev) {
      if (err && err.hasOwnProperty("message")) {
        store.dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: err.response.data?.error_details?.emailAddress[0] ? err.response.data?.error_details?.emailAddress[0] :  err?.message,
          })
        );
      } else {
        store.dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Failed HTTP Call",
          })
        );
      }
    } else {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server Error",
        })
      );
    }
  }

  getAuthHeader(requestHeaders) {
    const access_token = store.getState().access_token;
    const id_token = store.getState().id_token;
    const options = {
      headers: {
        Authorization: `Bearer#${access_token}#${id_token}`,
        ...requestHeaders
      },
    };
    return options;
  }

  /**
   * Makes an HTTP GET request to the remote server.
   * @param {string} URL the URL of the GET endpoint.
   */
  async get(URL, headers={}) {
    const authHeader = this.getAuthHeader(headers);
    try {
      return await axios.get(URL, authHeader);
    } catch (error) {
      this.triggerErrorNotification(error);
      return error;
    }
  }

  /**
   * Makes an HTTP POST request to the remote server.
   * @param {string} URL the URL of the POST endpoint.
   */
  async post(URL, payload = {}, options = {}) {
    if (options && options.headers && options.headers.Authorization) {
      try {
        return await axios.post(URL, payload, options);
      } catch (error) {
        this.triggerErrorNotification(error);
        return error;
      }
    } else {
      const authHeader = this.getAuthHeader(options.headers);
      try {
        return await axios.post(URL, payload, authHeader);
      } catch (error) {
        this.triggerErrorNotification(error);
        return error;
      }
    }
  }

  async patch(URL, payload = {}, headers = {}) {
    const authHeader = this.getAuthHeader();
    try {
      return await axios.patch(URL, payload, authHeader);
    } catch (error) {
      this.triggerErrorNotification(error);
      return error;
    }
  }

  async put(URL, payload = {}, headers = {}) {
    const authHeader = this.getAuthHeader();
    authHeader.headers = {...authHeader.headers, ...headers}
    try {
      return await axios.put(URL, payload, authHeader);
    } catch (error) {
      this.triggerErrorNotification(error);
      return error;
    }
  }

  async downloadFile(URL, fileName) {
    const authHeader = this.getAuthHeader();

    try {
      const response = await axios.get(URL, {
        headers: authHeader.headers,
        responseType: "arraybuffer",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      this.triggerErrorNotification(error);
      return error;
    }
  }
}

loggerService.dev("Creating HTTP Service");
const httpService = new HttpService();

loggerService.dev("Exporting HTTP Service");
export { httpService };
