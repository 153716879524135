import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SummaryPanel } from "./components/summaryPanel";
import { Tile } from "./components/tile";

import "./home.scss";
import { store } from "../../../state-management/store";
import { reportsService } from "../../../services/reports";
import { useDispatch, useSelector } from "react-redux";
import {
  saveFormData,
  setCurrentRegion,
} from "../../../state-management/actions";

const HomePage = () => {
  const country = useSelector((state) => state?.user?.Country || "DE");
  const dispatch = useDispatch();
  const [tiles, setTiles] = useState([]);
  const navigate = useNavigate();
  const screenList = store.getState()?.userScreenList;
  const [userScreenList, setUserScreenList] = useState(screenList);
  const region = useSelector((state) => state?.selectedRegion);

  useEffect(() => {
    async function fetchFormContent() {
      const form = await reportsService.fetchCommentsForm(country);
      dispatch(saveFormData(form));
    }
    fetchFormContent();
    if (!region) {
      const selectedRegion = country === "DE" ? "DE" : "BENEFRUX";
      dispatch(setCurrentRegion(selectedRegion));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageTitle = (report) => {
    let currentItem = report;
    while (currentItem?.children?.length > 0) {
      currentItem = currentItem?.children?.[0];
    }
    return currentItem?.title;
  };

  useEffect(() => {
    //TODO parse dynamically using recursive method
    const cardsList = userScreenList.reduce((item, report) => {
      const pageTitle = getPageTitle(report);
      return [
        ...item,
        {
          moduleId: report.id,
          title: report.title,
          pageTitle: pageTitle,
          description: report.description,
          submoduleId: report.children && report.children[0].id,
          reportId: report.children
            ? report.children[0].children
              ? report.children[0].children[0].reportId
              : report.children[0].reportId
            : "",
          pageId: report.children
            ? report.children[0].children
              ? report.children[0].children[0].id
              : report.children[0].id
            : report.id,
          pageName: report.children
            ? report.children[0].children
              ? report.children[0].children[0].pageName
              : report.children[0].pageName
            : "",
          tableName: report.children
            ? report.children[0].children
              ? report.children[0].children[0].tableName
              : report.children[0].tableName
            : "",
        },
      ];
    }, []);
    setTiles(cardsList);
  }, [userScreenList]);

  store.subscribe(() => {
    const { userScreenList } = store.getState();
    setUserScreenList(userScreenList);
  });

  const onTileClick = ({
    moduleId,
    submoduleId,
    pageId,
    pageName,
    title,
    reportId,
    tableName,
    pageTitle,
  }) => {
    const selectedReport = {
      moduleId,
      submoduleId,
      pageId,
      pageName,
      title: pageTitle || title,
      reportId,
      tableName,
    };
    localStorage.setItem("reportsData", JSON.stringify(selectedReport));
    navigate("/reports");
  };
  return (
    <div className="container-fluid">
      <div className="row dashboard-page">
        <SummaryPanel />
        <div className="col-md-8 col-sm-8 col-xs-8 col-lg-8 col-xl-8 tiles-panel">
          <div className="container tiles-panel-container">
            <div className="row">
              {tiles.map((tile, index) => (
                <Tile
                  tileData={tile}
                  key={index}
                  onTileClick={() => onTileClick(tile)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HomePage };
