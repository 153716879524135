import React from "react";
import { ReportRequestScreen } from "./report-request-screen/report-request-screen";

const ReportRequestScreenContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container">
        <div className="row content-container">
          <ReportRequestScreen />
        </div>
      </div>
    </>
  );
};

export { ReportRequestScreenContainer };
