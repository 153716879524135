import { httpService } from "../services/http";
import { API } from "./utils";

class CountryConfig {
  async getCountryConfig() {
    const URL = `${API.masterData}/countryconfig/DE`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async updateCountryConfig(payload) {
    const URL = `${API.masterData}/master/countryconfig`;
    try {
      const response = await httpService.patch(URL, payload);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

const countryConfig = new CountryConfig();
export { countryConfig };
