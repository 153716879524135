import { MultiSelectTree } from "@progress/kendo-react-dropdowns";
import React, { useCallback, useMemo, useState } from "react";
import { processMultiSelectTreeData } from "../multi-select-tree/data-operations";
import { getter } from "@progress/kendo-react-common";

const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";

export const multiSelectionDropDownFields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

export const expandedState = (item, dataItemKey, expanded) => {
  const nextExpanded = expanded.slice();
  const keyGetter = getter(dataItemKey);
  const itemKey = keyGetter(item);
  const index = expanded.findIndex((currentKey) => {
    return currentKey === itemKey;
  });
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
  return nextExpanded;
};

export const MultiSelectionDropDown = ({
  data,
  values,
  onChange,
  filterable = false,
  showError = false,
  maxSelection = 100,
  customStyle = {},
  placeholder='Select ...'
}) => {
  const [filter, setFilter] = useState(null);

  const onFilterChange = (event) => setFilter(event.filter);

  const [expanded, setExpanded] = useState([
    data?.[0]?.[multiSelectionDropDownFields?.dataItemKey],
  ]);

  const onExpandChange = useCallback(
    (event) =>
      setExpanded(
        expandedState(
          event.item,
          multiSelectionDropDownFields?.dataItemKey,
          expanded
        )
      ),
    [expanded]
  );

  const restrictSelectionData = (dataToRender, maxSelection) => {
    const data = dataToRender?.map((item) => {
      const checkField = values?.filter((value) => value?.text === item?.text);
      return {
        ...item,
        checkField: checkField?.length > 0,
      };
    });
    const selectedItems = data?.filter((item) => item.checkField);
    const allOptionSelected = selectedItems?.filter((item) => item.id?.toString()?.toLowerCase() === 'all');
    if (selectedItems?.length >= maxSelection || allOptionSelected?.length > 0) {
      return data?.map((item) => {
        return { ...item, disabled: !item.checkField };
      });
    }

    return data;
  };

  let dataToRender = restrictSelectionData(data, maxSelection)
    // maxSelection > 0 ? restrictSelectionData(data, maxSelection) : data;

  const dropDownData = useMemo(
    () =>
      processMultiSelectTreeData(dataToRender, {
        expanded,
        value: values,
        filter,
        ...multiSelectionDropDownFields,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, expanded, values, filter]
  );

  return (
    <div>
      {renderMultiSelectionDropDown(
        dropDownData,
        values,
        onChange,
        filterable,
        showError,
        onExpandChange,
        onFilterChange,
        placeholder,
        customStyle
      )}
    </div>
  );
};

const renderMultiSelectionDropDown = (
  data,
  values,
  onChange,
  filterable,
  showError,
  onExpandChange,
  onFilterChange,
  placeholder,
  customStyle
) => {
  const disabled = data?.length <= 0
  const textField = "text";
  let style = customStyle;
  if (showError && !disabled && (values?.length === 0)) {
    style = { borderColor: "red", ...style };
  }

  const currentValues =
    values?.length > 1 ? [{ id: 0, text: `Multiple...` }] : values;

  return (
    <MultiSelectTree
      data={data}
      disabled={disabled}
      value={currentValues}
      onChange={onChange}
      placeholder={placeholder}
      textField={textField}
      dataItemKey={multiSelectionDropDownFields?.dataItemKey}
      checkField={multiSelectionDropDownFields?.checkField}
      checkIndeterminateField={
        multiSelectionDropDownFields?.checkIndeterminateField
      }
      expandField={multiSelectionDropDownFields?.expandField}
      subItemsField={multiSelectionDropDownFields?.subItemsField}
      onExpandChange={onExpandChange}
      filterable={filterable}
      onFilterChange={onFilterChange}
      style={style}
    />
  );
};
