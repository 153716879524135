import { httpService } from "./http";
import { loggerService } from "../services/logger";
import { API } from "./utils";
import { store } from "../state-management/store";

/**
 * A service that helps fetching the site information.
 */
class SitesService {
  /**
   * A function that fetches all the sites from remote server.
   */
  async fetchAllSites(region, rkSite) {
    const URL = `${API.siteInfo}/${region}?rkSite=${rkSite}`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchSiteList(region) {
    const user = store.getState().user;
    const URL = `${API.siteInfoNew}/${region}`;
    const headers = {
      "User-Name": `${user?.UserFirstName} ${user?.UserLastName}`,
      "User-Alias": user?.UniqueUserId,
      "User-Email": user?.UserEmail,
    };
    try {
      const response = await httpService.get(URL, headers);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchSiteStatuses(region) {
    const URL = API.siteStatuses;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchSiteSources(region) {
    const URL = API.siteSources;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * A function that fetches the selected site details from remote server.
   */
  async fetchSiteDetailsInfo(countryCode, globalSiteId) {
    const URL = `${API.siteInfoNew}/${countryCode}/${globalSiteId}`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * A function that fetches the selected site details from remote server.
   */
  async fetchSiteDetails(countryCode, globalSiteId) {
    const URL = `${API.sitedetails}/${countryCode}/${globalSiteId}`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async updateSiteInfo(countryCode, globalSiteId, payload) {
    const URL = `${API.siteInfoNew}/${countryCode}/${globalSiteId}/update`;
    const user = store.getState().user;
    const headers = {
      "User-Name": `${user?.UserFirstName} ${user?.UserLastName}`,
      "User-Alias": user?.UniqueUserId,
      "User-Email": user?.UserEmail,
    };
    try {
      const response = await httpService.post(URL, payload, { headers });
      if (response && response.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async updateRemarks(countryCode, globalSiteId, data) {
    const URL = `${API.updateRemarks}/${countryCode}/${globalSiteId}`;
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await httpService.put(URL, `${data}`, headers);
      if (response && response.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async downloadSiteExcel(region, rkSite) {
    const URL = `${API.downloadSiteData}/${region}?rkSite=${rkSite}`;
    await httpService.downloadFile(URL, "Site Information.xlsx");
  }

  /**
   * A function that fetches list of site ids of given region from remote server.
   */
  async fetchSiteIdList(country) {
    const URL = `${API.masterData}/master/sitelist/${country}`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchSiteIdListWithName(country) {
    const URL = `${API.masterData}/master/siteidnamelist/${country}`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchSiteIdsBasedOnRegions(payload) {
    const URL = `${API.issueSitesInfo}/${payload?.country}/${payload?.region}`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchRegions(country) {
    const URL = `${API.issueSitesInfo}/${country}/regions`;
    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

loggerService.dev("Creating Sites Service");
const siteService = new SitesService();

loggerService.dev("Exporting Sites Service");
export { siteService };
