import { getMonths } from "../../reportUtils";

export const getMonthIndex = (name) => {
  const monthsData = getMonths();
  const month = monthsData?.find((month) => month.text === name);
  return month ? month.id : 0;
};

export const thresholdYearSort = (a, b) => {
  if (a?.year < b?.year) {
    return -1;
  }
  if (a?.year > b?.year) {
    return 1;
  }
  return 0;
};

export const thresholdDaySort = (a, b) => {
  if (a?.day < b?.day) {
    return -1;
  }
  if (a?.day > b?.day) {
    return 1;
  }
  return 0;
};

export const thresholdMonthSort = (a, b) => {
  if (a?.monthIndex < b?.monthIndex) {
    return -1;
  }
  if (a?.monthIndex > b?.monthIndex) {
    return 1;
  }
  return 0;
};

export const getTankGroups = (masterData, filters, productSelectedValues) => {
  const siteData = filters?.allSiteIds?.find(
    (site) => site?.globalSiteId === filters?.selectedSiteId
  );
  let productTanks = [];
  productSelectedValues?.forEach((product) => {
    const tanks = siteData?.tanks?.filter(
      (tank) => (tank?.materialNumber === product?.id) && tank?.tankGroupId
    );
    productTanks?.push(...tanks);
  });

  let tankGroupData = [];

  if (productTanks?.length > 0) {
    const tankGroup = Object.groupBy(productTanks, (item) => item?.tankGroupId);

    tankGroupData = Object.keys(tankGroup)?.map((key) => {
      const items = tankGroup[key]?.map((tank) => ({
        text: tank?.tankId,
        id: `${tank?.tankId}-${tank?.tankGroupId}`,
        materialNumber: tank?.materialNumber,
        tankGroupId: tank?.tankGroupId,
        tankId: tank?.tankId,
        disabled: true,
      }));
      const groupItem = {
        text: key,
        id: key,
        items: items,
        group: true,
      };
      return groupItem;
    });
  }

  return tankGroupData;
};
