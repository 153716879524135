import "./tour.scss";

const TourPage = () => {
  return (
    <div className="tour-container">
      <div className="content">
        <img src={require("../../../assets/logo.png")} alt="shell" />
        <h1>Take a tour</h1>
        <p>
          The WSM analytics application comprises of three main pages as
          described below.
        </p>
      </div>
      <div className="row pages-thumbnail-row">
        <div className="col-md-4 col-lg-4 col-sm-4 thumb">
          <img
            className="thumb-img"
            src={require("../../../assets/login.png")}
            alt="login page"
          />
          <h5>1. Login Page</h5>
          <p>
            The login page lets you login to your account. Based on your role at
            Shell you can choose the appropriate login option.
          </p>
        </div>
        <div className="col-md-4 col-lg-4 col-sm-4 thumb">
          <img
            className="thumb-img"
            src={require("../../../assets/dashboard.png")}
            alt="dashboard page"
          />
          <h5>2. Dashboard</h5>
          <p>
            Once logged in, you'll see the dashboard. From here your can select
            the report you're interested in.
          </p>
        </div>
        <div className="col-md-4 col-lg-4 col-sm-4 thumb">
          <img
            className="thumb-img"
            src={require("../../../assets/reports.png")}
            alt="report page"
          />
          <h5>3. Report Page</h5>
          <p>
            The report page gives you a detailed view of the selected report.
            You can switch reports from the side panel on the left.
          </p>
        </div>
      </div>
    </div>
  );
};
export { TourPage };
