import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./site-information.scss";
import { Input, Loader } from "@progress/kendo-react-all";
import { triggerNotification } from "../../../../../state-management/actions";
import { useDispatch } from "react-redux";
import { siteService } from "../../../../../services/sites";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";

const SiteTankDetails = (props) => {
  const siteItem = props?.currentSiteItem;
  const siteDetailsItem = props?.selectedItem;
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentOffSet, setCurrentOffSet] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const [fields, setFields] = useState({
    materialNo: siteDetailsItem?.materialNo,
    productCode: siteDetailsItem?.productCode,
    productName: siteDetailsItem?.additionalProductInfo,
    tankGroup: siteDetailsItem?.tankGroup,
    tankName:
      siteDetailsItem?.deviceID ||
      siteDetailsItem?.tankName?.replace(/\D/g, ""),
    capacity: siteDetailsItem?.capacity ? `${siteDetailsItem?.capacity}` : "",
    workCapacity: siteDetailsItem?.capacity
      ? `${parseInt(siteDetailsItem?.capacity * 0.95)}`
      : "",
  });

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, 10);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {}, [fields?.selectedShipTo]);

  const LabelField = (props) => {
    return (
      <div className="deliveryRow">
        <span className="tank-label">{props?.label}</span>
        <span className="tank-val">{props?.value}</span>
      </div>
    );
  };

  const TextInputField = (props) => {
    const selectedField = `${props?.selectedField}`;
    const selectedValue = fields?.[selectedField]
      ? `${fields[selectedField]}`
      : "";
    const mandatory = props?.mandatory;

    const onChange = (event) => {
      const position = event.target.element?.selectionStart;
      setCursorPosition(position);
      const inputValue = event.target.value;
      const newValue = props?.allowOnlyNumbers
        ? inputValue.replace(/[^-?\d*\\.?\d+$]/g, "")
        : inputValue;
      setSelectedItem(selectedField);
      let workCapacity = fields?.workCapacity;
      if (selectedField === "capacity") {
        workCapacity = parseInt(newValue * 0.95);
      }
      setFields((prev) => {
        return {
          ...prev,
          [selectedField]: newValue,
          workCapacity: workCapacity,
        };
      });
    };
    const sameItem = selectedItem === selectedField;
    const errorClassName =
      showError && !(selectedValue?.length > 0) ? "errorClassName" : "";
    return (
      <div className="deliveryRow">
        <span className="tank-label">
          {props?.label}
          {mandatory && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className={`${errorClassName} tank-text-input`}>
          <Input
            ref={sameItem ? inputRef : null}
            value={selectedValue}
            onChange={onChange}
            maxLength={props?.maxLength}
          />
        </span>
      </div>
    );
  };

  const showHeader = () => {
    const title = props?.isAddTank
      ? "Add a New Tank Details"
      : "Edit Tank Details";
    return (
      <div className="deliveryHeader">
        <div className="title">{title}</div>
      </div>
    );
  };

  const showContent = () => {
    return (
      <div className="deliveryContent">
        <div className="leftContainer">
          {props?.isAddTank ? (
            <TextInputField
              label="Material"
              selectedField="materialNo"
              mandatory
              allowOnlyNumbers
              maxLength={10}
            />
          ) : (
            <LabelField label="Material" value={fields?.materialNo} />
          )}

          {props?.isAddTank ? (
            <TextInputField
              label="Product Code"
              selectedField="productCode"
              mandatory
              allowOnlyNumbers
              maxLength={10}
            />
          ) : (
            <LabelField label="Product Code" value={fields?.productCode} />
          )}

          {props?.isAddTank ? (
            <TextInputField
              label="Product"
              selectedField="productName"
              mandatory
            />
          ) : (
            <LabelField label="Product" value={fields?.productName} />
          )}
          {props?.isAddTank ? (
            <TextInputField
              label="Tank Group"
              selectedField="tankGroup"
              mandatory
              maxLength={10}
            />
          ) : (
            <LabelField label="Tank Group" value={fields?.tankGroup} />
          )}
        </div>
        <div className="rightContainer">
          {props?.isAddTank ? (
            <TextInputField
              label="Tank No"
              selectedField="tankName"
              mandatory
              maxLength={10}
            />
          ) : (
            <LabelField label="Tank No" value={fields?.tankName} />
          )}
          <LabelField label="Operational Capacity" value={fields?.workCapacity} />
          <TextInputField
            label="Capacity"
            selectedField="capacity"
            mandatory
            allowOnlyNumbers
            maxLength={7}
          />
        </div>
      </div>
    );
  };

  const isValid = () => {
    const allValid =
      fields?.materialNo?.length > 0 &&
      fields?.productCode?.length > 0 &&
      fields?.productName?.length > 0 &&
      fields?.tankGroup?.length > 0 &&
      fields?.tankName?.length > 0 &&
      fields?.capacity?.length > 0 &&
      (fields?.workCapacity?.length > 0 || fields?.workCapacity);

    setShowError(!allValid);
    return allValid;
  };

  const showSubmitButton = () => {
    return (
      <div className="tank-footer-container">
        <div className="info-label">
          {`NOTE: Operational Capacity is 95% of Capacity (${fields.capacity} * 0.95 = ${fields.workCapacity})`}
        </div>
        <div className="submit-btn-container">
          <Button
            className="submit-btn"
            onClick={(event) => {
              if (isValid()) {
                const x = event.clientX;
                const y = event.clientY;
                const offset = {
                  left: x,
                  top: y,
                };
                setCurrentOffSet(offset);
                setShowConfirmationPopup(true);
              }
            }}
          >
            {submitting ? "Submitting..." : "Save"}
          </Button>
          <Button
            className="close-btn"
            onClick={() => {
              props?.cancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const createPayload = () => {
    let tankInfo = [];
    if (props?.isAddTank) {
      const newTank = {
        additionalProductInfo: fields?.productName,
        capacity: fields?.capacity,
        materialNo: fields?.materialNo,
        productCode: fields?.productCode,
        tankGroup: fields?.tankGroup,
        tankName: fields?.tankName,
        workCapacity: fields?.workCapacity,
      };
      tankInfo = [...siteItem?.TankInfo, newTank];
    } else {
      tankInfo = siteItem?.TankInfo?.map((tank) => {
        let tankDetails = tank;
        if (tank?.deviceID === siteDetailsItem?.deviceID) {
          tankDetails = {
            ...tank,
            additionalProductInfo: fields?.productName,
            capacity: fields?.capacity,
            materialNo: fields?.materialNo,
            productCode: fields?.productCode,
            tankGroup: fields?.tankGroup,
            tankName: fields?.tankName,
            workCapacity: fields?.workCapacity,
          };
        }
        return tankDetails;
      });
    }

    const payload = {
      ...siteItem,
      TankInfo: tankInfo,
    };

    return payload;
  };

  const saveChanges = async () => {
    setShowConfirmationPopup(false);
    setSubmitting(true);
    const payload = createPayload();
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      payload
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
      props?.save(payload);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Failed to save site information!",
        })
      );
    }
    setSubmitting(false);
  };

  return (
    <div className="popupContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent()}
        {showSubmitButton()}
        {showConfirmationPopup && (
          <ConfirmPopup
            offset={currentOffSet}
            position="top"
            isOpen={showConfirmationPopup}
            title={"Are you sure you want to update the tank information?"}
            message={
              "This change would impact the existing report data and the report changes can’t be reverted."
            }
            saveAction={() => {
              saveChanges();
            }}
            cancelAction={() => {
              setShowConfirmationPopup(false);
            }}
          />
        )}

        {submitting && (
          <div className="loaderContainer">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { SiteTankDetails };
