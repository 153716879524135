import React, { useState, useEffect, useMemo } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { ReportMenu } from "./components/report-menu";
import { UserManagementContainer } from "./components/user-management-container";
import { IssueLogScreen } from "./components/issue-log-screen/main/main";
import { CountryConfigScreenContainer } from "./components/country-config-screen-container";
import { store } from "../../../state-management/store";
import { reportsService } from "../../../services/reports";
import "./reports.scss";
import { WetstockDataUpload } from "./components/wetstock-data-upload-screen/wetstock-data-upload-screen";
import { CommentableReportsContainer } from "./components/commentable-reports-container/commentable-reports-container";
import { PrePostingContainer } from "./components/pre-posting-container/pre-posting-container";
import { ReportRequestScreenContainer } from "./components/report-request-screen-container";
import { TankThresholdContainer } from "./components/tank-threshold-screen-container";
import { TankLeaksContainer } from "./components/tank-leaks-screen-container";
import { EODSummaryContainer } from "./components/eod-summary-container";
import { Button } from "@progress/kendo-react-all";
import { setSelectedReport } from "../../../state-management/actions";
import { PumpIndexContainer } from "./components/pump-index-container";
import { PumpIndexMonthlyExceptionContainer } from "./components/pump-index-monthly-exception-container";
import { OOTSummaryContainer } from "./components/oot-summary-container";
import { SiteInformationScreenContainerV2 } from "./components/site-information-screen-containerV2";
// import { DataCompletenessContainer } from "./components/data-completeness-container";
import { getAppVersion, SCREEN_IDS } from "./reportUtils";
import { GainLossOverviewContainer } from "./components/gain-loss-overview-screen-container";
import { MessageTrackerDeliveryContainer } from "./components/message-tracker-delivery-container";

const ReportsPage = () => {
  const [reportId, setReportId] = useState("");
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [pageName, setPageName] = useState("");
  const [title, setTitle] = useState("");
  const [screenId, setScreenId] = useState();
  const [, setEmbeddedReport] = useState();
  const [tableName, setTableName] = useState("");
  const [embedUrl, setEmbedUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [currentReport, setCurrentReport] = useState();
  const [userPreferenceData, setUserPreferenceData] = useState({
    DefaultCountry: "",
    Region: "",
  });
  const report = store.getState()?.selectedReport;

  const getUserDetailsFromStore = () => {
    const loggedInUser = store.getState()?.user;
    setUserPreferenceData({
      DefaultCountry: loggedInUser.UserPreference?.DefaultCountry || "",
      Region: loggedInUser.UserPreference?.Region || "",
    });
  };

  useEffect(() => {
    getUserDetailsFromStore();
  }, []);
  const filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: tableName,
      column: "Region",
    },
    operator: "eq",
    values: [userPreferenceData.Region],
  };
  const [settings] = useState({
    navContentPaneEnabled: false,
    filterPaneEnabled: false,
    background: models.BackgroundType.Transparent,
    layoutType: models.LayoutType.Widescreen,
    customLayout: {
      displayOption: models.DisplayOption.FitToWidth,
      pageSize: {
        type: models.PageSizeType.Custom,
      },
    },
  });

  store.subscribe(() => {
    const { selectedReport } = store.getState();
    setTableName(selectedReport?.tableName);
    setPageName(selectedReport?.pageName);
    setReportId(selectedReport?.reportId);
    setTitle(selectedReport?.title);
    setScreenId(selectedReport?.pageId || selectedReport?.moduleId);
    setCurrentReport(selectedReport);
  });

  useEffect(() => {
    report?.reportId &&
      reportsService.fetchEmbeddedToken(report?.reportId).then((res) => {
        setAccessToken(res.AccessToken);
        setEmbedUrl(res.ReportURL);
      });
  }, [report?.reportId]);

  const leakReportFilters = useMemo(() => {
    let filters = [];
    if (currentReport?.filters) {
      Object.keys(currentReport?.filters)?.forEach((key) => {
        if (key === "RequestDate") {
          const startDate = currentReport?.filters?.[key]?.startDate;
          const endDate = currentReport?.filters?.[key]?.endDate;
          const filter = {
            $schema: "https://powerbi.com/product/schema#advanced",
            target: {
              table: tableName,
              column: key,
            },
            logicalOperator: "And",
            conditions: [
              {
                operator: "GreaterThanOrEqual",
                value: startDate?.toISOString(),
              },
              {
                operator: "LessThanOrEqual",
                value: endDate?.toISOString(),
              },
            ],
            filterType: models.FilterType.Advanced,
          };
          filters = [...filters, filter];
        } else {
          if (currentReport?.filters?.[key]?.[0] !== "All") {
            let columnName = key;
            if (columnName === "Product") {
              columnName = "Material";
            }
            const filter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: tableName,
                column: columnName,
              },
              operator: "eq",
              values: currentReport?.filters?.[key],
              filterType: models.FilterType.Basic,
            };
            filters = [...filters, filter];
          }
        }
      });
    }
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport]);

  const handleThresholdLink = (title) => {
    let selectedReport = {
      moduleId: SCREEN_IDS.COUNTRY_CONFIGURATION,
      pageId: SCREEN_IDS.TANK_THRESHOLD_RESULTS,
      title: "TANK THRESHOLD RESULTS",
      tab: title,
      filters: currentReport?.filters,
    };
    localStorage.setItem("reportsData", JSON.stringify(selectedReport));
    store.dispatch(setSelectedReport(selectedReport));
  };

  const renderSubPage = () => {
    const { selectedReport } = store.getState();
    switch (screenId) {
      case SCREEN_IDS.USER_MANAGEMENT:
      case SCREEN_IDS.USER_AUDIT_LIST:
      case SCREEN_IDS.USER_LOGIN_HISTORY:
      case SCREEN_IDS.USER_ONBOARDING:
      case SCREEN_IDS.EDIT_USERS:
      case SCREEN_IDS.REVIEW_GSAP_DUPLICATES:
        return <UserManagementContainer selectedReport={selectedReport} />;

      case SCREEN_IDS.PRE_POSTING_ACTIVITIES:
        return <PrePostingContainer />;

      case SCREEN_IDS.ISSUE_LOG_REPORT:
        return <IssueLogScreen />;

      case SCREEN_IDS.SITE_INFORMATION:
        return <SiteInformationScreenContainerV2 />;

      case SCREEN_IDS.COUNTRY_CONFIGURATION:
      case SCREEN_IDS.VARIANCE_CONFIGURATION:
        return <CountryConfigScreenContainer />;

      case SCREEN_IDS.WETSTOCK_DATA_UPLOAD:
        return <WetstockDataUpload />;

      case SCREEN_IDS.HISTORY_REQUEST_PAGE:
        return <ReportRequestScreenContainer />;

      case SCREEN_IDS.TANK_THRESHOLD_CONFIGURATION:
        return <TankThresholdContainer />;

      case SCREEN_IDS.TANK_THRESHOLD_RESULTS:
        return <TankLeaksContainer />;

      case SCREEN_IDS.EOD_SUMMARY_BENEFRUX:
      case SCREEN_IDS.EOD_SUMMARY_DACH:
        return <EODSummaryContainer />;

      case SCREEN_IDS.OUT_OF_TOLERANCE_SUMMARY:
        return <OOTSummaryContainer />;

      case SCREEN_IDS.MESSAGE_TRACKER_DELIVERIES:
        return <MessageTrackerDeliveryContainer />;

      // case "OUT OF TOLERANCE COMMENTS":
      //   return <DataCompletenessContainer />;

      case SCREEN_IDS.PUMP_REGISTER_REPORT:
      case SCREEN_IDS.PUMP_REGISTER:
        return <PumpIndexContainer />;

      case SCREEN_IDS.MONTHLY_EXCEPTION:
        return <PumpIndexMonthlyExceptionContainer />;

      case SCREEN_IDS.GAIN_LOSS_OVERVIEW:
        return <GainLossOverviewContainer />;

      case SCREEN_IDS.OUT_OF_TOLERANCE_TREND_ANALYSIS:
        return userPreferenceData.Region === "ALL" ? (
          <CommentableReportsContainer
            report={title}
            screenId={screenId}
            reactPage={true}
            powerBi={
              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: reportId,
                  embedUrl,
                  accessToken,
                  tokenType: models.TokenType.Embed,
                  pageName,
                  settings,
                }}
                cssClassName={"iframe-container"}
                getEmbeddedComponent={(embeddedReport) =>
                  setEmbeddedReport(embeddedReport)
                }
              />
            }
          />
        ) : (
          <CommentableReportsContainer
            report={title}
            screenId={screenId}
            reactPage={true}
            powerBi={
              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: reportId,
                  embedUrl,
                  accessToken,
                  tokenType: models.TokenType.Embed,
                  pageName,
                  settings,

                  filters: [filter],
                }}
                cssClassName={"iframe-container"}
                getEmbeddedComponent={(embeddedReport) =>
                  setEmbeddedReport(embeddedReport)
                }
              />
            }
          />
        );

      case SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES:
      case SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES_NEW:
        return userPreferenceData.Region === "ALL" ? (
          <CommentableReportsContainer
            report={title}
            screenId={screenId}
            powerBi={
              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: reportId,
                  embedUrl,
                  accessToken,
                  tokenType: models.TokenType.Embed,
                  pageName,
                  settings,
                }}
                cssClassName={"iframe-container"}
                getEmbeddedComponent={(embeddedReport) =>
                  setEmbeddedReport(embeddedReport)
                }
              />
            }
          />
        ) : (
          <CommentableReportsContainer
            report={title}
            screenId={screenId}
            powerBi={
              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: reportId,
                  embedUrl,
                  accessToken,
                  tokenType: models.TokenType.Embed,
                  pageName,
                  settings,

                  filters: [filter],
                }}
                cssClassName={"iframe-container"}
                getEmbeddedComponent={(embeddedReport) =>
                  setEmbeddedReport(embeddedReport)
                }
              />
            }
          />
        );
      case SCREEN_IDS.FIFTEEN_MINS_RECONCILIATION_REPORT:
      case SCREEN_IDS.DAILY_RECONCILIATION_REPORT: {
        return (
          <div className="reconciliationContainer">
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id: reportId,
                embedUrl,
                accessToken,
                tokenType: models.TokenType.Embed,
                pageName,
                settings,
                filters: leakReportFilters,
              }}
              cssClassName={"iframe-container"}
              getEmbeddedComponent={(embeddedReport) =>
                setEmbeddedReport(embeddedReport)
              }
            />
          </div>
        );
      }
      default:
        return userPreferenceData.Region === "ALL" ? (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: reportId,
              embedUrl,
              accessToken,
              tokenType: models.TokenType.Embed,
              pageName,
              settings,
            }}
            cssClassName={"iframe-container"}
            getEmbeddedComponent={(embeddedReport) =>
              setEmbeddedReport(embeddedReport)
            }
          />
        ) : (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: reportId,
              embedUrl,
              accessToken,
              tokenType: models.TokenType.Embed,
              pageName,
              settings,

              filters: tableName ? [filter] : [],
            }}
            cssClassName={"iframe-container"}
            getEmbeddedComponent={(embeddedReport) =>
              setEmbeddedReport(embeddedReport)
            }
          />
        );
    }
  };

  const navigateToHistoryReport = (moduleId, subModuleId, reportId) => {
    const { userScreenList } = store.getState();

    const module = userScreenList?.find((item) => item.id === moduleId);
    const subModule = module?.children?.find((item) => item.id === subModuleId);
    let report = subModule?.children?.find((item) => item.id === reportId);
    if (!report) {
      report = subModule;
    }
    const selectedReport = {
      moduleId: module?.id,
      submoduleId: subModule?.id,
      pageId: report?.id,
      pageName: report?.pageName,
      reportId: report?.reportId,
      title: report?.title,
      tableName: report?.tableName,
    };
    localStorage.setItem("reportsData", JSON.stringify(selectedReport));
    store.dispatch(setSelectedReport(selectedReport));
  };

  const getPageLink = () => {
    switch (screenId) {
      case SCREEN_IDS.SITE_SUMMARY:
        return (
          <span className="header-link-container">
            <Button
              className="backButton"
              themeColor={"info"}
              fillMode="link"
              onClick={() => {
                navigateToHistoryReport(
                  SCREEN_IDS.HISTORY,
                  SCREEN_IDS.HISTORY_GAIN_LOSS
                );
              }}
            >
              Historical Gain & Loss
            </Button>
          </span>
        );
      case SCREEN_IDS.HISTORY_GAIN_LOSS:
        return (
          <span className="header-link-container">
            <Button
              className="backButton"
              themeColor={"info"}
              fillMode="link"
              onClick={() => {
                navigateToHistoryReport(
                  SCREEN_IDS.SITE_INFORMATION_MODULE,
                  SCREEN_IDS.SITE_SUMMARY
                );
              }}
            >
              Site summary
            </Button>
          </span>
        );
      case SCREEN_IDS.OUT_OF_TOLERANCE_COMMENTS:
        return (
          <span className="header-link-container">
            <Button
              className="backButton"
              themeColor={"info"}
              fillMode="link"
              onClick={() => {
                navigateToHistoryReport(
                  SCREEN_IDS.HISTORY,
                  SCREEN_IDS.HISTORY_OUT_OF_TOLERANCE_COMMENTS
                );
              }}
            >
              Historical Out of tolerance comments
            </Button>
          </span>
        );
      case SCREEN_IDS.HISTORY_OUT_OF_TOLERANCE_COMMENTS:
        return (
          <span className="header-link-container">
            <Button
              className="backButton"
              themeColor={"info"}
              fillMode="link"
              onClick={() => {
                navigateToHistoryReport(
                  SCREEN_IDS.CWR,
                  SCREEN_IDS.CWR_DAILY,
                  SCREEN_IDS.OUT_OF_TOLERANCE_COMMENTS
                );
              }}
            >
              Out of tolerance comments
            </Button>
          </span>
        );

      case SCREEN_IDS.FIFTEEN_MINS_RECONCILIATION_REPORT:
      case SCREEN_IDS.DAILY_RECONCILIATION_REPORT:
        if (report?.filters) {
          return (
            <span className="header-link-container">
              <Button
                className="backButton"
                themeColor={"info"}
                fillMode="link"
                onClick={() => {
                  handleThresholdLink(title);
                }}
              >
                Back To Threshold Page
              </Button>
            </span>
          );
        }
        return <></>;
      default:
        return <></>;
    }
  };

  const renderHeader = () => {
    return (
      <div className="header-container">
        <div className="header">
          <h4 className="title">{title?.toLowerCase()}</h4>
          {getPageLink()}
        </div>
        <div className="separator"></div>
      </div>
    );
  };

  const renderFooter = () => {
    const footerText = `Shell PLC. All rights reserved.`;
    return (
      <div className="footer-container">
        <div className="separator"></div>
        <h4 className="footer-title">
          {`[${getAppVersion()}]`} &#9400; {footerText}
        </h4>
      </div>
    );
  };

  const reportClass = menuCollapse
    ? `accordian-container reportCollapseContainer backButtonContainer`
    : `accordian-container reportMenuContainer backButtonContainer`;

  return (
    <div className="container-fluid">
      <div className="row reports-page">
        <div className={reportClass}>
          <ReportMenu menuCollapse={menuCollapse} />
          <Button
            className="backButton"
            themeColor={"info"}
            fillMode="link"
            onClick={() => {
              setMenuCollapse((prev) => !prev);
            }}
          >
            {menuCollapse ? "<=>" : ">=<"}
          </Button>
        </div>
        <div className="reports-container">
          <div className="container user-management-screen-container">
            <div className="row screen-container">
              {renderHeader()}
              {renderSubPage()}
              {renderFooter()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ReportsPage };
