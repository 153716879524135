import { createStore } from "redux";
import { primaryReducer } from "./reducer";
import { appConfig } from "../configs/app-config";
import { loggerService } from "../services/logger";

loggerService.dev("Creating The Global Store");

/**
 * This is the global store that the entire application will refer to for state management.
 * The store will expose redux dev tools but only if the application runs in development environment.
 */
const store = appConfig.isDev
  ? createStore(
      primaryReducer,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : createStore(primaryReducer);

loggerService.dev("Exporting The Global Store");

export { store };
