import React from "react";
import { EODSummaryScreen } from "./eod-summary-screen/eod-summary-screen";

const EODSummaryContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <EODSummaryScreen />
        </div>
      </div>
    </>
  );
};

export { EODSummaryContainer };
