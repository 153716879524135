import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { ShipToLinkCell } from "../common-utils";

export const getCountries = () => {
  return [
    {
      id: "de",
      text: "DE",
    },
    {
      id: "ch",
      text: "CH",
    },
    {
      id: "at",
      text: "AT",
    },
    {
      id: "be",
      text: "BE",
    },
    {
      id: "fr",
      text: "FR",
    },
    {
      id: "lu",
      text: "LU",
    },
    {
      id: "nl",
      text: "NL",
    },
  ];
};

export const colsToShow = (handleShipToLinkAction) => {
  let columns = [
    {
      title: "Ship To",
      field: "siteID",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      filterable: true,
    },
    {
      title: "Outbound Delivery No",
      field: "shipmentID",
      filterable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: "Correlation Id",
      field: "correlationId",
      filterable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: "Received On",
      field: "timeGenerated",
      filterable: true,
      headerCell: CustomHeaderCell,
    },
  ];
  return columns;
};
