import "./tank-threshold.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { Loader } from "@progress/kendo-react-indicators";
import { reportsService } from "../../../../../services/reports";
import { Button, Input } from "@progress/kendo-react-all";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { store } from "../../../../../state-management/store";
import {
  getMonthIndex,
  getTankGroups,
  thresholdDaySort,
  thresholdMonthSort,
  thresholdYearSort,
} from "./tank-threshold-utils";
import { readOnlyUser } from "../../../../../services/utils";
import {
  getAllSelectedOptions,
  getMonths,
  getRegions,
  getShipTos,
  getSiteNames,
  getThresholdYears,
} from "../../reportUtils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { isBFX } from "../common-utils";

const TankThresholdScreen = () => {
  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);
  const allOption = {
    id: "All",
    text: "All",
    group: true,
    items: [],
  };

  const FIRST_COLUMN_HEADER = "1";
  const SECOND_COLUMN_HEADER = "11";
  const THIRD_COLUMN_HEADER = "21";
  const defaultRegion = useSelector((state) => state?.selectedRegion);
  const [currentRegion, setCurrentRegion] = useState(defaultRegion);

  const defaultRegionSelection = {
    id: isBFX(currentRegion) ? "BE" : "all",
    text: isBFX(currentRegion) ? "BE" : "All",
  };

  const [filters, setFilters] = useState({
    selectedSiteId: null,
    selectedSiteName: null,
    selectedProducts: [],
    products: [],
    selectedTanks: [],
    tankGroup: [],
    selectedYears: getThresholdYears(1),
    years: getThresholdYears(2),
    selectedMonths: [
      {
        id: new Date().getMonth() + 1,
        text: new Date().toLocaleString("en-US", { month: "short" }),
      },
    ],
    months: getMonths(),
    regions: [],
    selectedRegion: [defaultRegionSelection],
    allSiteIds: [],
    allSiteNames: [],
  });

  const [masterData, setMasterData] = useState([]);
  const [thresholdData, setThresholdData] = useState([]);
  const [currentThreshold, setCurrentThreshold] = useState();
  const [showError, setShowError] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setCurrentRegion(selectedRegion);
  });

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );
  const [yearSelectedValues, setYearSelectedValues] = useState(
    filters?.selectedYears
  );
  const [monthSelectedValues, setMonthSelectedValues] = useState(
    filters?.selectedMonths
  );
  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters?.selectedRegion
  );

  useEffect(() => {
    setThresholdData([]);
    fetchMasterData();
    setFilters({
      ...filters,
      selectedRegion: [defaultRegionSelection],
    });
    setRegionSelectedValues([defaultRegionSelection]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  useEffect(() => {
    const regions = getRegions(masterData);
    const siteIds =
      filters.selectedRegion?.length > 0
        ? getShipTos(masterData, filters.selectedRegion, false)
        : [];

    const siteNames =
      filters.selectedRegion?.length > 0
        ? getSiteNames(masterData, filters.selectedRegion, false)
        : [];

    setFilters((prev) => {
      return {
        ...prev,
        regions: regions,
        selectedSiteId: siteIds?.[0]?.globalSiteId,
        selectedSiteName: siteNames?.[0]?.siteName,
        allSiteIds: siteIds,
        allSiteNames: siteNames,
      };
    });
  }, [masterData, filters.selectedRegion]);

  useEffect(() => {
    const siteData = filters?.allSiteIds?.find(
      (site) => site?.globalSiteId === filters?.selectedSiteId
    );
    const productsData = siteData?.materials?.map((product) => ({
      id: product?.materialNumber,
      text: product?.productName,
      ...product,
    }));
    setFilters((prev) => {
      return { ...prev, products: productsData };
    });
    setProductSelectedValues([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteId]);

  useEffect(() => {
    setFilters({
      ...filters,
      selectedRegion: regionSelectedValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setFilters({
      ...filters,
      selectedMonths: monthSelectedValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthSelectedValues]);

  useEffect(() => {
    setFilters({
      ...filters,
      selectedYears: yearSelectedValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearSelectedValues]);

  useEffect(() => {
    let tankGroups = getTankGroups(masterData, filters, productSelectedValues);
    if (productSelectedValues?.length > 0 && tankGroups?.length === 0) {
      tankGroups = [allOption];
    }
    setFilters({
      ...filters,
      selectedProducts: productSelectedValues,
      tankGroup: tankGroups,
    });
    if (tankGroups?.length > 0) {
      const tankGroup = tankGroups[0];
      setTankSelectedValues([tankGroup, ...tankGroup?.items]);
    } else {
      setTankSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  useEffect(() => {
    setFilters({
      ...filters,
      selectedTanks: tankSelectedValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  const onSiteIdSelection = (e) => {
    const siteId = e?.value?.text;

    const siteData = filters?.allSiteIds?.find((site) => site?.id === siteId);

    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteId: siteId,
        selectedSiteName: siteData?.siteName,
      };
    });
  };

  const onSiteNameSelection = (e) => {
    const siteName = e?.value?.text;
    const siteData = filters?.allSiteIds?.find(
      (site) => site?.siteName === siteName
    );
    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteId: siteData?.globalSiteId,
        selectedSiteName: siteName,
      };
    });
  };

  const onProductSelection = (event) => {
    setProductSelectedValues(
      getMultiSelectTreeValue(filters?.products, {
        ...multiSelectionDropDownFields,
        ...event,
        value: productSelectedValues,
      })
    );
  };

  const onTankSelection = (event) => {
    setTankSelectedValues(
      getMultiSelectTreeValue(filters?.tankGroup, {
        ...multiSelectionDropDownFields,
        ...event,
        value: tankSelectedValues,
      })
    );
  };

  const onYearSelection = (event) => {
    const selectedValues = getAllSelectedOptions(event, yearSelectedValues);
    setYearSelectedValues(
      getMultiSelectTreeValue(filters?.years, {
        ...multiSelectionDropDownFields,
        ...event,
        value: selectedValues,
      })
    );
  };

  const onMonthSelection = (event) => {
    const selectedValues = getAllSelectedOptions(event, monthSelectedValues);
    setMonthSelectedValues(
      getMultiSelectTreeValue(filters?.months, {
        ...multiSelectionDropDownFields,
        ...event,
        value: selectedValues,
      })
    );
  };

  const onRegionSelection = (event) => {
    const selectedValues = getAllSelectedOptions(event, regionSelectedValues);
    setRegionSelectedValues(
      getMultiSelectTreeValue(filters?.regions, {
        ...multiSelectionDropDownFields,
        ...event,
        value: selectedValues,
      })
    );
  };

  const editThresholdAction = () => {
    setEditEnabled(true);
  };
  const saveThresholdAction = () => {
    setEditEnabled(false);
    const selectedTankIds = filters?.selectedTanks
      ?.filter((tank) => !tank?.group)
      ?.map((item) => item?.text);
    updateThresholdRequest({
      ...thresholdData?.[thresholdData?.length - 1],
      threshold: currentThreshold?.threshold,
      region: currentRegion,
      tankIds: selectedTankIds,
    });
  };

  const cancelThresholdAction = () => {
    setEditEnabled(false);
    setCurrentThreshold(thresholdData?.[thresholdData?.length - 1]);
  };

  const fetchMasterData = async () => {
    try {
      setLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentRegion,
      });
      setLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the data.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
      setError("Server error. Unable to fetch data.");
      setLoading(false);
      setError("");
    }
  };

  const fetchThresholdData = async () => {
    const siteId = filters?.allSiteIds?.find(
      (site) => site?.globalSiteId === filters?.selectedSiteId
    )?.localSiteId;
    const selectedTankIds = filters?.selectedTanks
      ?.filter((tank) => tank?.group)
      ?.map((item) => item?.id);
    const selectedMonths = filters?.selectedMonths?.map((month) => month?.id);
    const selectedYears = filters?.selectedYears?.map((year) => year?.text);
    const selectedMaterials = filters?.selectedProducts?.map(
      (product) => product?.materialNumber
    );

    let valid =
      siteId &&
      selectedMonths?.length &&
      selectedYears?.length &&
      selectedMaterials?.length;

    if (valid && !isBFX(currentRegion)) {
      valid = valid && selectedTankIds?.length;
    }

    if (valid) {
      setShowError(false);
      setSubmitLoading(true);
      try {
        let payload = {
          region: currentRegion,
          globalSiteId: filters?.selectedSiteId,
          siteId: siteId,
          selectedMonths: selectedMonths,
          selectedYears: selectedYears,
          selectedMaterials: selectedMaterials,
        };
        if (!isBFX(currentRegion)) {
          payload = {
            ...payload,
            selectedTankIds: selectedTankIds,
          };
        }
        const response = await reportsService.fetchThresholdData(payload);
        setSubmitLoading(false);
        if (response) {
          const responseData = response?.map((record) => ({
            ...record,
            monthIndex: getMonthIndex(record?.month),
          }));
          responseData?.sort(thresholdDaySort);
          responseData?.sort(thresholdMonthSort);
          responseData?.sort(thresholdYearSort);
          if (responseData?.length > 0) {
            setCurrentThreshold(responseData?.[responseData?.length - 1]);
          }
          setThresholdData(responseData);
        } else {
          dispatch(
            triggerNotification({
              type: {
                style: "error",
                icon: true,
              },
              message: "Unable to fetch the data.",
            })
          );
        }
      } catch (error) {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Server error. Unable to fetch data.",
          })
        );
        setError("Server error. Unable to fetch data.");
        setSubmitLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };

  const updateThresholdRequest = async (thresholdData) => {
    setSubmitLoading(true);
    try {
      const response = await reportsService.updateThreshold(thresholdData);
      setSubmitLoading(false);
      if (response.status >= 200 && response.status < 300) {
        fetchThresholdData();
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: `${response?.data?.returnMessage || response?.data}`,
          })
        );
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message:
              response?.data?.returnMessage || "Unable to submit the request.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to submit the request",
        })
      );
      setSubmitLoading(false);
      setError("Server error. submit the request.");
      setError("");
    }
  };

  const showThresholdEntries = () => {
    const thresholdEntries = Object.groupBy(
      thresholdData,
      (item) => item?.month
    );
    const monthsKeys = Object.keys(thresholdEntries);
    if (thresholdData?.length > 0) {
      return (
        <div className="threshold-table-container">
          <span className="threshold-header-label">Threshold values</span>
          <table className="report-table">
            <thead className="table-header">
              <tr className="header-row">
                <td style={{ width: "8vw", fontSize: "1vw" }}>Period</td>
                <td style={{ width: "8vw", fontSize: "1vw" }}>1st</td>
                <td style={{ width: "8vw", fontSize: "1vw" }}>11th</td>
                <td style={{ width: "8vw", fontSize: "1vw" }}>21st</td>
              </tr>
            </thead>
            <tbody className="table-body">
              {monthsKeys.map((month, index) => {
                const monthData = thresholdEntries?.[month];
                const firstColumnData = monthData?.find(
                  (data) => data?.day === FIRST_COLUMN_HEADER
                );
                const secondColumnData = monthData?.find(
                  (data) => data?.day === SECOND_COLUMN_HEADER
                );
                const thirdColumnData = monthData?.find(
                  (data) => data?.day === THIRD_COLUMN_HEADER
                );
                const year =
                  firstColumnData?.year ||
                  secondColumnData?.year ||
                  thirdColumnData?.year;
                return (
                  <tr className="report-row" key={index}>
                    <td style={{ fontSize: "0.9vw", fontWeight: "600" }}>
                      <span>{`${month} ${year}`}</span>
                    </td>
                    <td style={{ fontSize: "0.9vw" }}>
                      {firstColumnData?.threshold
                        ? firstColumnData?.threshold
                        : ""}
                    </td>
                    <td style={{ fontSize: "0.9vw" }}>
                      {secondColumnData?.threshold
                        ? secondColumnData?.threshold
                        : ""}
                    </td>
                    <td style={{ fontSize: "0.9vw" }}>
                      {thirdColumnData?.threshold
                        ? thirdColumnData?.threshold
                        : ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div className="no-data-container">
        <h6 className="no-data-label">No Threshold Entries</h6>
      </div>
    );
  };

  const showCurrentThreshold = () => {
    return (
      <div className="edit-threshold-container">
        <span className="threshold-label">
          {editEnabled ? "Update Threshold" : "Current Threshold"}
        </span>
        <Input
          className="threshold-input"
          maxLength={8}
          disabled={!editEnabled}
          value={currentThreshold?.threshold}
          onChange={(e) => {
            setCurrentThreshold({
              ...currentThreshold,
              threshold: e?.target?.value,
            });
          }}
        />
        {!readOnly && (
          <Button
            className="edit-button"
            onClick={() => {
              editEnabled ? saveThresholdAction() : editThresholdAction();
            }}
          >
            {editEnabled ? "Save" : "Edit"}
          </Button>
        )}
        {editEnabled && (
          <Button className="cancel-button" onClick={cancelThresholdAction}>
            Cancel
          </Button>
        )}
      </div>
    );
  };

  const showHeaders = () => {
    const siteIds = filters.allSiteIds?.map((site) => site?.globalSiteId);
    const siteNames = filters.allSiteNames?.map((site) => site?.siteName);
    return (
      <div className="tank-threshold-container">
        <span className="header-container">
          <span className="filters-container">
            <span className="filter">
              <label htmlFor="year">Year</label>
              <MultiSelectionDropDown
                data={filters.years}
                values={yearSelectedValues}
                onChange={onYearSelection}
                filterable={false}
                showError={showError}
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter">
              <label htmlFor="month">Month</label>
              <MultiSelectionDropDown
                data={filters.months}
                values={monthSelectedValues}
                onChange={onMonthSelection}
                filterable={true}
                showError={showError}
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
            {isBFX(currentRegion) && (
              <span className="filter">
                <label htmlFor="region">Region</label>
                <MultiSelectionDropDown
                  data={filters.regions}
                  values={regionSelectedValues}
                  onChange={onRegionSelection}
                  filterable={false}
                  showError={showError}
                  customStyle={{ width: "6vw", minHeight: "3vh" }}
                />
              </span>
            )}
            <span className="filter-siteId">
              <label htmlFor="region">Site ID</label>
              <SearchableDropdown
                filterable
                style={{ minWidth: "5vw" }}
                onSelect={onSiteIdSelection}
                value={{ text: filters.selectedSiteId }}
                data={siteIds}
              />
            </span>
            <span className="filter-siteName">
              <label htmlFor="region">Site Name</label>
              <SearchableDropdown
                filterable
                style={{ minWidth: "8vw" }}
                onSelect={onSiteNameSelection}
                value={{ text: filters.selectedSiteName }}
                data={siteNames}
              />
            </span>
            <span className="filter">
              <label htmlFor="region">Product</label>
              <MultiSelectionDropDown
                data={filters.products}
                values={productSelectedValues}
                onChange={onProductSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ minWidth: "10vw" }}
              />
            </span>
            {!isBFX(currentRegion) && (
              <span className="filter">
                <label htmlFor="region">Tank Group</label>
                <MultiSelectionDropDown
                  data={filters.tankGroup}
                  values={getTankGroupValues(tankSelectedValues)}
                  onChange={onTankSelection}
                  filterable={false}
                  showError={showError}
                  maxSelection={1}
                  customStyle={{ minWidth: "7vw" }}
                />
              </span>
            )}
          </span>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={fetchThresholdData}
              disabled={loading}
            >
              {loading ? "Fetching..." : "Get data"}
            </Button>
          </span>
        </span>
      </div>
    );
  };

  const getTankGroupValues = (values) => {
    return values?.filter((tank) => tank?.group);
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {/* <h4 className="title">Tank Threshold Configuration</h4> */}
        {error && <span>{error}</span>}
        {loading ? (
          <>
            <div className="centralized-loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h5 className="loader-text">
                Fetching the deliveries please wait...
              </h5>
            </div>
          </>
        ) : (
          <div>
            <div>
              {showHeaders()}
              {submitLoading && (
                <Loader
                  style={{ position: "absolute", left: "50%", top: "30%" }}
                  size="large"
                  themeColor="warning"
                  type="converging-spinner"
                />
              )}
            </div>
            <div className="threshold-data-container">
              {showThresholdEntries()}
            </div>
            <div className="threshold-data-container">
              {thresholdData?.length > 0 && showCurrentThreshold()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { TankThresholdScreen };
