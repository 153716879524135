import { Loader } from "@progress/kendo-react-indicators";

const LoadingPanel = ({ message }) => {
  return (
    <div className="k-loading-mask">
      <div className="loading-text">{message}</div>
      <div className="loading-image">
        <Loader size="large" themeColor="warning" type="converging-spinner" />
      </div>

      <div className="k-loading-color"></div>
    </div>
  );
};

export { LoadingPanel };
