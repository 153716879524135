import React, { useEffect, useRef, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import "./account-for-deliveries.scss";
import { GridToolbar } from "@progress/kendo-react-grid";
// import { saveAs } from "@progress/kendo-file-saver";
// import { KendoOoxml } from "@progress/kendo-react-excel-export";
import {
  auditColsToShow,
  colsToExport,
  colsToShow,
  defaultOption,
  detailColsToShow,
  getPlantValues,
  getSlocValues,
} from "./account-for-deliveries-utils";
import {
  getAllSelectedOptions,
  getLastDates,
  getMaterials,
  getFilterRegions,
  getShipTos,
  getTanks,
  getYesterdayDate,
} from "../../reportUtils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import {
  DateInput,
  DateRangePicker,
  Input,
  RangeSlider,
} from "@progress/kendo-react-all";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { reportsService } from "../../../../../services/reports";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../../state-management/store";
import { triggerNotification } from "../../../../../state-management/actions";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../../../services/utils";
import { AddDelivery } from "./add-delivery-screen";
import { ButtonGroup } from "@progress/kendo-react-buttons";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { TextInlineEditCell } from "../../../../components/text-edit-cell/TextInlineEditCell";
import { DEFAULT_PAGE_SIZE } from "../common-utils";
import { InlineEditGrid } from "../../../../components/data-grid/inline-data-grid";

export const AFDTabOptions = {
  DELIVERY: 0,
  AUDIT: 1,
};

const AccountForDeliveries = () => {
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [accountForDeliveriesData, setAccountForDeliveriesData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [exportExcelData, setExportExcelData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [commentsOptions, setCommentsOptions] = useState([]);
  const [masterDataLoading, setMasterDataLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const exportGridRef = useRef(null);
  const exportAuditGridRef = useRef(null);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const defaultRegion = useSelector((state) => state?.selectedRegion);
  const [currentRegion, setCurrentRegion] = useState(defaultRegion);
  const [resetIndex, setResetIndex] = useState(false);
  const [showAddDelivery, setShowAddDelivery] = useState(false);
  const inputRef = useRef(null);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [selectedTab, setSelectedTab] = useState(AFDTabOptions.DELIVERY);
  const [sliderValue, setSliderValue] = useState({
    start: -1000,
    end: 1000,
  });
  // eslint-disable-next-line no-unused-vars
  const [differenceVal, _] = useState({
    min: -300000,
    max: 300000,
  });
  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setCurrentRegion(selectedRegion);
  });

  const [selectedItem, setSelectedItem] = useState();
  const [filters, setFilters] = useState({
    selectedDates: {
      start: getYesterdayDate(),
      end: new Date(),
    },
    dates: getLastDates(5, 1, currentRegion),
    selectedRegions: [],
    regions: getFilterRegions(filterData, false),
    selectedShipTo: [],
    shipTos: [],
    selectedMaterials: [],
    materials: getMaterials(filterData),
    selectedSlocs: [],
    slocs: getSlocValues(filterData),
    selectedPlants: [],
    plants: [],
  });

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [masterDataCount, setMasterDataCount] = useState(0);

  const [dateSelectedValues, setDateSelectedValues] = useState(
    filters.selectedDates
  );

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [materialSelectedValues, setMaterialSelectedValues] = useState(
    filters.selectedMaterials
  );

  const [slocSelectedValues, setSlocSelectedValues] = useState(
    filters.selectedSlocs
  );

  const [plantSelectedValues, setPlantSelectedValues] = useState(
    filters.selectedPlants
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const fetchAccountForDeliveriesReport = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    setChanged(false);
    setChangedItems([]);
    setFetchingData(true);
    const currentRegions = filter.selectedRegions?.map(
      (region) => region?.text
    );
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    const startDate = fromDate && getFormattedDate(fromDate);
    const endDate = toDate && getFormattedDate(toDate);

    const currentCountries = filter.selectedRegions?.map(
      (region) => region?.countryCode
    );

    const countryCode = currentCountries?.[0];
    let payload = {
      startDate: startDate,
      endDate: endDate,
      countryCode: countryCode,
      regions: currentRegions,
      shipTos: filter.selectedShipTo?.map((shipTo) => shipTo.text),
      materialNos: filter.selectedMaterials?.map((material) => material.text),
      slocs: filter.selectedSlocs?.map((sloc) => sloc.text),
      plants: filter.selectedPlants?.map((plant) => plant.text),
    };

    if (selectedTab === AFDTabOptions.DELIVERY) {
      payload = {
        ...payload,
        LossDifference: sliderValue.start,
        GainDifference: sliderValue.end,
      };
      let response = await reportsService.fetchAccountForDeliveriesData(
        payload
      );
      response?.sort((a, b) => a.materialNumber - b.materialNumber);
      response?.sort((a, b) => a.sloc - b.sloc);
      response?.sort((a, b) => a.shipTo - b.shipTo);

      const responseData = response?.map((item) => {
        const documentDate = item?.documentDate
          ? getFormattedDate(new Date(item?.documentDate))
          : "";
        const reportDate = item?.reportDate
          ? getFormattedDate(new Date(item?.reportDate))
          : "";
        const outBoundDeliveryId = item?.outBoundDeliveryId?.replace(",", "\n");
        return {
          ...item,
          documentDate: documentDate,
          reportDate: reportDate,
          outBoundDeliveryId: outBoundDeliveryId,
        };
      });

      setOriginalData(responseData ? responseData : []);
      setAccountForDeliveriesData(responseData ? responseData : []);
    } else {
      let response = await reportsService.fetchAFDAuditData(payload);

      const responseData = response?.map((item) => {
        const reportDate = item?.reportDate
          ? getFormattedDate(new Date(item?.reportDate))
          : "";
        const outBoundDeliveryId = item?.outBoundDeliveryId?.replace(",", "\n");
        const isComment = item?.entity?.toLowerCase() === "comment";
        return {
          ...item,
          documentDate: item?.partitionKey,
          confirmedDelivery: isComment ? "" : item?.confimedDelivery,
          tankId: isComment ? "" : item?.tankId,
          outBoundDeliveryId: isComment ? "" : outBoundDeliveryId,
          reportDate: reportDate,
          modifiedOn: item?.modifiedOn
            ? getFormattedDateAndTime(new Date(item?.modifiedOn))
            : "",
        };
      });
      setAuditData(responseData ? responseData : []);
    }
    if (!refresh) {
      setAppliedFilters(filter);
    }

    setFetchingData(false);
  };

  const hasEmptyValues = () => {
    let emptySelection =
      filters?.selectedDates?.length === 0 ||
      filters?.selectedRegions?.length === 0 ||
      filters?.selectedShipTo?.length === 0 ||
      filters?.selectedMaterials?.length === 0 ||
      filters?.selectedSlocs?.length === 0 ||
      filters?.selectedPlants?.length === 0;

    return emptySelection;
  };

  const loadData = () => {
    let emptySelection = hasEmptyValues();

    if (!emptySelection && firstLoad) {
      fetchAccountForDeliveriesReport();
      setFirstLoad(false);
    }
  };

  const fetchMasterData = async () => {
    setMasterDataLoading(true);
    const masterData = await reportsService.fetchAccountForDeliveriesMasterData(
      {
        country: currentRegion,
      }
    );
    setFilterData(masterData ? masterData : []);
    setMasterDataCount((prev) => prev + 1);
  };
  const fetchCommentsMasterData = async () => {
    setMasterDataLoading(true);
    const response = await reportsService.fetchCommentsMasterData({
      country: currentRegion,
    });
    const options = response?.map((option) => option?.categoryText);
    const optionsData = [defaultOption, ...options];
    setCommentsOptions(options ? optionsData : []);
    setMasterDataCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (masterDataCount === 2) {
      setMasterDataCount(0);
      setMasterDataLoading(false);
    }
  }, [masterDataCount]);

  const updateExportData = () => {
    let data = [];
    accountForDeliveriesData?.forEach((item) => {
      const tankData = {
        plant: item?.plant,
        documentDate: item?.documentDate,
        shipTo: item?.shipTo,
        sloc: item?.sloc,
        materialNumber: item?.materialNumber,
        outBoundDeliveryId: item?.outBoundDeliveryId?.replace("\n", ", "),
        type: "Material Level",
        calculatedDelivery: item?.calculatedDelivery,
        confirmedDelivery: item?.confirmedDelivery,
        difference: item?.difference,
        comment: item?.comment,
      };
      data = [...data, tankData];
      item?.deliveries?.forEach((deliveryItem) => {
        const deliveryData = {
          plant: item?.plant,
          documentDate: item?.documentDate,
          shipTo: item?.shipTo,
          sloc: item?.sloc,
          materialNumber: item?.materialNumber,
          outBoundDeliveryId: deliveryItem?.outBoundDeliveryId,
          type: "Tank Level",
          calculatedDelivery: deliveryItem?.calculatedDelivery,
          confirmedDelivery: deliveryItem?.confirmedDelivery,
          difference: item?.difference,
          comment: item?.comment,
          deliveryStartDate:
            deliveryItem?.deliveryStartDate?.length > 0
              ? deliveryItem?.deliveryStartDate
              : null,
          startTemperature: deliveryItem?.startTemperature
            ? `${deliveryItem?.startTemperature}°C`
            : "",
          deliveryEndDate:
            deliveryItem?.deliveryEndDate?.length > 0
              ? deliveryItem?.deliveryEndDate
              : null,
          endTemperature: deliveryItem?.endTemperature
            ? `${deliveryItem?.endTemperature}°C`
            : "",
          tank: deliveryItem?.tank,
        };
        data = [...data, deliveryData];
      });
    });

    setExportExcelData(data);
  };

  useEffect(() => {
    updateExportData();
    inputRef?.current?.focus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountForDeliveriesData]);

  useEffect(() => {
    setFirstLoad(true);
    fetchMasterData();
    fetchCommentsMasterData();
    setChanged(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  useEffect(() => {
    const regions = getFilterRegions(filterData, false);
    let selectedRegions = [];
    if (regions?.length > 0) {
      selectedRegions = [regions?.[0]];
    }
    if (regions?.length > 1) {
      selectedRegions = [regions?.[1]];
    }
    setFilters({
      ...filters,
      regions,
      selectedRegions,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    setDateSelectedValues(filters.selectedDates);
    setRegionSelectedValues(filters.selectedRegions);
    const shipTos =
      filters.selectedRegions?.length > 0
        ? getShipTos(filterData, filters.selectedRegions)
        : [];
    const selectedShipTo =
      filters.selectedRegions?.length > 0 && shipTos?.length > 0
        ? [shipTos?.[0]]
        : [];

    setFilters({
      ...filters,
      shipTos,
      selectedShipTo: selectedShipTo,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    setShipToSelectedValues(filters.selectedShipTo);
    const tanks =
      filters.selectedShipTo?.length > 0 ? getTanks(filterData, filters) : [];
    const selectedTanks = tanks?.length > 0 ? [tanks?.[0]] : [];
    const materials =
      filters.selectedShipTo?.length > 0
        ? getMaterials(filterData, filters)
        : [];
    const selectedMaterial = materials?.length > 0 ? [materials[0]] : [];
    const slocs = getSlocValues(filterData, filters);
    const selectedSlocs = slocs?.length > 0 ? [slocs[0]] : [];
    const plants = getPlantValues(filterData, filters);
    const selectedPlants = plants?.length > 0 ? [plants[0]] : [];

    setFilters({
      ...filters,
      materials,
      selectedMaterials: selectedMaterial,
      tanks,
      selectedTanks,
      slocs,
      selectedSlocs,
      plants,
      selectedPlants,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedShipTo, filters.shipTos]);

  useEffect(() => {
    setMaterialSelectedValues(filters.selectedMaterials);
  }, [filters.selectedMaterials]);

  useEffect(() => {
    setSlocSelectedValues(filters.selectedSlocs);
  }, [filters.selectedSlocs]);

  useEffect(() => {
    setPlantSelectedValues(filters.selectedPlants);
  }, [filters.selectedPlants]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: dateSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedRegions: regionSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedShipTo: shipToSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedMaterials: materialSelectedValues,
    }));
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedSlocs: slocSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slocSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedPlants: plantSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantSelectedValues]);

  const handleDataChange = (e) => {
    const { dataItem, field } = e;
    setSelectedItem({ ...dataItem, editField: field });
    const updatedItems = accountForDeliveriesData?.map((item, index) => {
      return index === dataItem.id ? e?.dataItem : item;
    });
    setAccountForDeliveriesData(updatedItems);

    const actualItem = originalData?.find(
      (item) => item.rowKey === dataItem.rowKey
    );

    const comment =
      actualItem.comment === defaultOption ? "" : actualItem.comment;
    const currentComment =
      dataItem.comment === defaultOption ? "" : dataItem.comment;

    const itemChanged =
      comment !== currentComment ||
      parseFloat(actualItem.confirmedDelivery) !==
        parseFloat(dataItem.confirmedDelivery) ||
      parseFloat(actualItem.outBoundDeliveryId) !==
        parseFloat(dataItem.outBoundDeliveryId);

    const onlyCommentUpdated =
      comment !== currentComment &&
      parseFloat(actualItem.confirmedDelivery) ===
        parseFloat(dataItem.confirmedDelivery);

    setChanged(itemChanged);
    if (itemChanged) {
      const sameItem = changedItems?.find(
        (item) => item.rowKey === dataItem.rowKey
      );

      if (sameItem) {
        const items = changedItems?.map((item) => {
          if (item.rowKey === dataItem.rowKey) {
            return dataItem;
          }
          return item;
        });
        setChangedItems(items);
      } else {
        setChangedItems([
          ...changedItems,
          { ...dataItem, onlyCommentUpdated: onlyCommentUpdated },
        ]);
      }
    } else {
      const items = changedItems?.filter(
        (item) => item.rowKey !== dataItem.rowKey
      );
      setChangedItems(items);
    }
  };

  const createPayload = () => {
    return changedItems?.map((item) => {
      const comment = item.comment === defaultOption ? "" : item.comment;
      return {
        onlyCommentUpdated: item?.onlyCommentUpdated ? true : false,
        countryCode: item?.countryCode,
        rowKey: item.rowKey,
        shipTo: item?.shipTo,
        materialNumber: item.materialNumber,
        tankId: item?.tankId,
        outBoundDeliveryId: item?.outBoundDeliveryId,
        documentDate: item?.documentDate,
        reportDate: getFormattedDate(new Date()),
        calculatedDelivery: item.calculatedDelivery,
        confirmedDelivery: item?.confirmedDelivery,
        difference: item?.difference,
        Comment: comment,
        userName: store.getState()?.user?.UserEmail,
      };
    });
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.updateAFDDelivery(payload);
    if (response?.status >= 200 && response?.status < 300) {
      setChanged(false);
      setChangedItems([]);
      fetchAccountForDeliveriesReport();
      setSubmitting(false);
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message:
            response?.data?.message || "Deliveries updated successfully.",
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to submit the comments.",
        })
      );
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setAccountForDeliveriesData(originalData);
    setChanged(false);
    setChangedItems([]);
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };

  // const detailExportDataInExcel = async (rowData) => {
  //   let workbook = await exportGridRef.current.getWorkBook();
  //   let rows = workbook.sheets[0].rows;
  //   let headerOptions = rows[0].cells[0];
  //   let currentRowIndex = null;

  //   //Add child rows
  //   rowData?.forEach((rowItem) => {
  //     //Find the row index
  //     rows.find((el, index) => {
  //       if (
  //         el.cells?.[0]?.value === rowItem.plant &&
  //         el.cells?.[1]?.value === rowItem.documentDate &&
  //         el.cells?.[2]?.value === rowItem.shipTo &&
  //         el.cells?.[3]?.value === rowItem.sloc &&
  //         el.cells?.[4]?.value === rowItem.materialNumber &&
  //         el.cells?.[5]?.value === rowItem.outBoundDeliveryId &&
  //         el.cells?.[6]?.value === rowItem.calculatedDelivery &&
  //         el.cells?.[7]?.value === rowItem.confirmedDelivery &&
  //         el.cells?.[8]?.value === rowItem.difference &&
  //         el.cells?.[9]?.value === rowItem.comment
  //       ) {
  //         currentRowIndex = index;
  //       }
  //     });

  //     //Add child rows

  //     const deliveries = rowItem?.deliveries;
  //     for (
  //       let deliveryIdx = deliveries.length - 1;
  //       deliveryIdx >= 0;
  //       deliveryIdx--
  //     ) {
  //       const delivery = deliveries[deliveryIdx];
  //       rows.splice(currentRowIndex + 1, 0, {
  //         cells: [
  //           {},
  //           { value: delivery?.deliveryStartDate },
  //           {
  //             value: delivery?.startTemperature
  //               ? `${delivery?.startTemperature}°C`
  //               : "",
  //           },
  //           { value: delivery?.deliveryEndDate },
  //           {
  //             value: delivery?.endTemperature
  //               ? `${delivery?.endTemperature}°C`
  //               : "",
  //           },
  //           { value: delivery?.outBoundDeliveryId },
  //           { value: delivery?.tank },
  //           { value: delivery?.calculatedDelivery },
  //           { value: delivery?.confirmedDelivery },
  //           { value: delivery?.difference },
  //         ],
  //       });
  //     }

  //     if (rowItem?.deliveries?.length > 0) {
  //       rows.splice(currentRowIndex + 1, 0, {
  //         cells: [
  //           {},
  //           Object.assign({}, headerOptions, {
  //             value: "Delivery Start Date",
  //           }),
  //           Object.assign({}, headerOptions, { value: "Start Temperature" }),
  //           Object.assign({}, headerOptions, { value: "Delivery End Date" }),
  //           Object.assign({}, headerOptions, { value: "End Temperature" }),
  //           Object.assign({}, headerOptions, {
  //             value: "OutBound Delivery Id",
  //           }),
  //           Object.assign({}, headerOptions, { value: "Tank" }),
  //           Object.assign({}, headerOptions, { value: "Detected Delivery" }),
  //           Object.assign({}, headerOptions, { value: "Confirmed Delivery" }),
  //           Object.assign({}, headerOptions, { value: "Difference" }),
  //         ],
  //       });
  //     }
  //   });

  //   new KendoOoxml.Workbook(workbook).toDataURL().then((dataUrl) => {
  //     saveAs(dataUrl, "AFD Data.xlsx");
  //   });
  // };

  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    // detailExportDataInExcel(
    //   accountForDeliveriesData
    // );

    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const exportAuditData = async () => {
    showHideLoaders(true);

    exportAuditGridRef?.current?.exportDataInExcel &&
      (await exportAuditGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const addDelivery = () => {
    setShowAddDelivery(true);
  };

  const cancelDelivery = () => {
    setShowAddDelivery(false);
  };

  const saveDelivery = () => {
    setShowAddDelivery(false);
    fetchData();
  };

  const showTabComponent = () => {
    return (
      <div className="modeContainer">
        <ButtonGroup>
          <Button
            togglable={true}
            selected={selectedTab === AFDTabOptions.DELIVERY}
            onClick={() => {
              setSelectedTab(AFDTabOptions.DELIVERY);
            }}
          >
            Delivery View
          </Button>
          <Button
            togglable={true}
            selected={selectedTab === AFDTabOptions.AUDIT}
            onClick={() => {
              setSelectedTab(AFDTabOptions.AUDIT);
            }}
          >
            Change History
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  const headerButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = changed && "#fbce07";
    const borderWidth = changed && "0.15vw";
    return (
      <div className="buttonsContainer">
        {selectedTab === AFDTabOptions.DELIVERY ? (
          <div className="buttonsLeftContainer">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={
                exporting || !accountForDeliveriesData?.length || changed
              }
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
            <Button className="exportButton" onClick={addDelivery}>
              Add New Delivery
            </Button>
          </div>
        ) : (
          <div className="buttonsLeftContainer">
            <Button
              className="exportButton"
              onClick={exportAuditData}
              disabled={!auditData?.length}
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
          </div>
        )}
        <div className="submitButtonContainer">
          {selectedTab === AFDTabOptions.DELIVERY && (
            <>
              <button
                title="Save"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
                onClick={saveChanges}
                style={{ borderColor: saveColor, borderWidth: borderWidth }}
                disabled={!changed}
              >
                Save
              </button>
              <button
                title="Cancel"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
                onClick={cancelChanges}
                style={{ borderColor: cancelColor, borderWidth: borderWidth }}
                disabled={!changed}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, regionSelectedValues);
      setRegionSelectedValues(
        getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onShipToSelection = (event) => {
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, shipToSelectedValues);
      setShipToSelectedValues(
        getMultiSelectTreeValue(filters?.shipTos, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onMaterialSelection = (event) => {
    if (event.operation === "delete") {
      setMaterialSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(
        event,
        materialSelectedValues
      );
      setMaterialSelectedValues(
        getMultiSelectTreeValue(filters?.materials, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onSlocSelection = (event) => {
    if (event.operation === "delete") {
      setSlocSelectedValues([]);
    } else {
      setSlocSelectedValues(
        getMultiSelectTreeValue(filters?.slocs, {
          ...multiSelectionDropDownFields,
          ...event,
          value: slocSelectedValues,
        })
      );
    }
  };

  const onPlantSelection = (event) => {
    if (event.operation === "delete") {
      setPlantSelectedValues([]);
    } else {
      setPlantSelectedValues(
        getMultiSelectTreeValue(filters?.plants, {
          ...multiSelectionDropDownFields,
          ...event,
          value: plantSelectedValues,
        })
      );
    }
  };

  const fetchData = () => {
    let canShowError = hasEmptyValues();

    setShowError(canShowError);
    if (!canShowError) {
      fetchAccountForDeliveriesReport();
      setResetIndex(true);
    }
  };

  const StartDateInput = (props) => {
    const dateClassName =
      props.value !== null || !showError ? "date" : "empty-date";
    return (
      <div className="dateContainer">
        <label htmlFor="startDate">Start Date</label>
        <DateInput
          {...props}
          label={undefined}
          className={`${dateClassName}`}
          placeholder="MM/DD/YY"
        />
      </div>
    );
  };

  const EndDateInput = (props) => {
    const dateClassName =
      props.value !== null || !showError ? "date" : "empty-date";
    return (
      <div className="dateContainer">
        <label htmlFor="endDate">End Date</label>
        <DateInput
          {...props}
          label={undefined}
          className={`${dateClassName}`}
          placeholder="MM/DD/YY"
        />
      </div>
    );
  };

  const handleDatePickerClose = () => {
    // setDatePickerClosed(true);
  };

  const onSliderChange = (event, type) => {
    const inputValue = event.target.value;
    const regex = /^(-?\d+|\d+)$/;
    const valid = regex.test(inputValue);
    if (
      (valid &&
        inputValue >= differenceVal.min &&
        inputValue <= differenceVal.max) ||
      inputValue === "-" ||
      inputValue === ""
    ) {
      setSliderValue((prev) => ({
        ...prev,
        [type]: inputValue,
      }));
    }
  };

  const CustomDetailsContainer = (props) => {
    const dataItem = props?.dataItem;
    const [tankDetails, setTankDetails] = useState([]);
    const [deliveries, setDeliveries] = useState([]);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
      const deliveries = dataItem?.deliveries?.map((item) => {
        const startTemperature =
          item?.startTemperature === 0 || item?.startTemperature
            ? `${item?.startTemperature}°C`
            : "";
        const endTemperature =
          item?.endTemperature === 0 || item?.endTemperature
            ? `${item?.endTemperature}°C`
            : "";
        return {
          ...item,
          startTemperature: startTemperature,
          endTemperature: endTemperature,
          inEdit: false,
          title: "Edit",
          subTitle: " ",
          saveEnabled:
            dataItem?.outBoundDeliveryId?.toString()?.length > 0 &&
            dataItem?.confirmedDelivery?.toString()?.length > 0,
        };
      });

      setDeliveries(deliveries);
      setTankDetails(deliveries);
    }, [dataItem]);

    const handleDeliveryEdit = (rowItem, childItem) => {
      const pumpList = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          return {
            ...delivery,
            inEdit: true,
            title: "Save",
            subTitle: "Cancel",
          };
        } else {
          return {
            ...delivery,
            inEdit: false,
            title: "Edit",
            subTitle: "",
          };
        }
      });
      setTankDetails(pumpList);
    };

    const handleDeliveryCancel = (rowItem, childItem) => {
      const tankDeliveries = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          const actualDelivery = deliveries?.find(
            (item) => item.rowKey === delivery.rowKey
          );
          return actualDelivery;
        }
        return delivery;
      });
      setTankDetails(tankDeliveries);
    };

    const getDateTime = (documentDate, dateVal) => {
      const date = new Date(dateVal);
      const defaultValue = new Date();
      defaultValue.setHours(0, 0, 0, 0);
      const deliveryStartDate = documentDate
        ? new Date(documentDate)
        : defaultValue;
      deliveryStartDate.setHours(date ? date?.getHours() : 0);
      deliveryStartDate.setMinutes(date ? date?.getMinutes() : 0);
      const dateTime = getFormattedDateAndTime(deliveryStartDate);
      return dateTime;
    };

    const handleDeliverySave = async (rowItem, childItem) => {
      //Start Time
      const startDate = childItem?.deliveryStartDate;
      const deliveryStartTime =
        startDate instanceof Date || startDate?.length > 0
          ? getDateTime(rowItem?.documentDate, childItem?.deliveryStartDate)
          : null;

      //End Time..
      const endDate = childItem?.deliveryStartDate;
      const deliveryEndTime =
        endDate instanceof Date || endDate?.length > 0
          ? getDateTime(rowItem?.documentDate, childItem?.deliveryEndDate)
          : null;

      const payload = {
        countryCode: rowItem?.countryCode,
        rowKey: childItem.rowKey,
        shipTo: rowItem?.shipTo,
        materialNumber: rowItem.materialNumber,
        tankId: childItem?.tank,
        outBoundDeliveryId: childItem?.outBoundDeliveryId,
        documentDate: rowItem?.documentDate,
        reportDate: getFormattedDate(new Date()),
        calculatedDelivery: childItem?.calculatedDelivery,
        confirmedDelivery: childItem?.confirmedDelivery,
        difference: childItem?.difference,
        deliveryStartTime: deliveryStartTime,
        deliveryEndTime: deliveryEndTime,
        startTemperature:
          childItem?.startTemperature?.length > 0
            ? parseFloat(childItem?.startTemperature)
            : null,
        endTemperature:
          childItem?.endTemperature?.length > 0
            ? parseFloat(childItem?.endTemperature)
            : null,
        userName: store.getState()?.user?.UserEmail,
      };
      setUpdating(true);
      const response = await reportsService.addAFDDelivery(payload);
      if (response?.data?.isSuccess) {
        // const tankDeliveries = tankDetails?.map((delivery) => {
        //   if (delivery.rowKey === childItem.rowKey) {
        //     let startDate = childItem?.deliveryStartDate;
        //     let endDate = childItem?.deliveryEndDate;
        //     startDate =
        //       startDate instanceof Date
        //         ? getFormattedDateAndTime(startDate)
        //         : startDate;

        //     endDate =
        //       endDate instanceof Date
        //         ? getFormattedDateAndTime(endDate)
        //         : endDate;
        //     return {
        //       ...childItem,
        //       deliveryStartDate: startDate,
        //       deliveryEndDate: endDate,
        //       inEdit: false,
        //       title: "Edit",
        //       subTitle: "",
        //     };
        //   }
        //   return delivery;
        // });
        // setTankDetails(tankDeliveries);
        fetchAccountForDeliveriesReport();
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message:
              response?.message ||
              response?.data?.message ||
              "Delivery updated successfully.",
          })
        );
      } else {
        const tankDeliveries = tankDetails?.map((delivery) => {
          if (delivery.rowKey === childItem.rowKey) {
            const actualDelivery = deliveries?.find(
              (item) => item.rowKey === delivery.rowKey
            );
            return actualDelivery;
          }
          return delivery;
        });
        setTankDetails(tankDeliveries);
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message:
              response?.message ||
              response?.data?.message ||
              "Delivery update failed",
          })
        );
      }
      setUpdating(false);
    };

    const handleDataUpdate = (childItem, field, value) => {
      const tankDeliveries = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          return {
            ...childItem,
            [field]: value,
          };
        }
        return delivery;
      });
      setTankDetails(tankDeliveries);
    };
    const textValidator = (value, message) => {
      if (value === null) {
        return message;
      } else if (value?.length === 0) {
        return message;
      }
      return "";
    };
    const handleItemChange = (e) => {
      const childItem = e.dataItem;
      const pumpList = tankDetails?.map((delivery) => {
        if (delivery.rowKey === childItem.rowKey) {
          const confirmedDeliveryValid = textValidator(
            e.field === "confirmedDelivery"
              ? e.value
              : delivery?.confirmedDelivery,
            "Confirmed Delivery is required"
          );
          delivery["confirmedDeliveryValid"] = confirmedDeliveryValid;

          const outBoundDeliveryIdValid = textValidator(
            e.field === "outBoundDeliveryId"
              ? e.value
              : delivery?.outBoundDeliveryId,
            "Outbound Delivery is required"
          );

          delivery["outBoundDeliveryIdValid"] = outBoundDeliveryIdValid;

          const saveEnabled = !(
            delivery?.confirmedDeliveryValid?.length > 0 ||
            delivery?.outBoundDeliveryIdValid?.length > 0
          );

          return {
            ...delivery,
            saveEnabled: saveEnabled,
            [e.field]: e.value,
          };
        }
        return delivery;
      });
      setTankDetails(pumpList);
    };

    return (
      <>
        <div className="container-fluid">
          <div className="openingHoursContainer">
            <InlineEditGrid
              style={{ maxHeight: "50vh", borderColor: "#b8e2ea" }}
              take={10000000}
              pageable={false}
              filterable={true}
              expandable={false}
              sortable={true}
              data={tankDetails}
              columnsToShow={detailColsToShow({
                rowItem: dataItem,
                onEdit: handleDeliveryEdit,
                onSave: handleDeliverySave,
                onCancel: handleDeliveryCancel,
                onUpdate: handleDataUpdate,
                currentRegion: currentRegion,
              })}
              onItemChange={handleItemChange}
              cells={{
                edit: {
                  text: TextInlineEditCell,
                },
              }}
            />
          </div>
          {updating && (
            <LoadingPanel message="Updating Deliveries.. please wait.." />
          )}
        </div>
      </>
    );
  };
  const headerFilters = () => {
    return (
      <div className="filters-container">
        <div className="filter-date">
          <DateRangePicker
            placeholder="Select date range..."
            defaultValue={filters.selectedDates}
            value={filters.selectedDates}
            startDateInput={StartDateInput}
            endDateInput={EndDateInput}
            onChange={onDateSelection}
            onClose={handleDatePickerClose}
            onOpen={() => {
              // setDatePickerClosed(false);
            }}
            max={new Date()}
          />
        </div>
        <span className="filter">
          <label htmlFor="region">
            {currentRegion === "DE" ? "Region" : "Country"}
          </label>
          <MultiSelectionDropDown
            data={filters.regions}
            values={regionSelectedValues}
            onChange={onRegionSelection}
            filterable={false}
            showError={showError && !masterDataLoading}
            customStyle={{
              minWidth: "10vw",
              minHeight: "3vh",
            }}
            maxSelection={1}
          />
        </span>
        <span className="filter">
          <label htmlFor="shipTo">Ship To</label>
          <MultiSelectionDropDown
            data={filters.shipTos}
            values={shipToSelectedValues}
            onChange={onShipToSelection}
            filterable={true}
            showError={showError && !masterDataLoading}
            customStyle={{ minWidth: "7vw", minHeight: "3vh" }}
          />
        </span>
        <span className="filter">
          <label htmlFor="material">Material No</label>
          <MultiSelectionDropDown
            data={filters.materials}
            values={materialSelectedValues}
            onChange={onMaterialSelection}
            filterable={true}
            showError={showError && !masterDataLoading}
            customStyle={{ minWidth: "7.0vw", minHeight: "3vh" }}
          />
        </span>

        {selectedTab === AFDTabOptions.DELIVERY && (
          <>
            <span className="filter">
              <label htmlFor="SLOC">SLOC</label>
              <MultiSelectionDropDown
                data={filters.slocs}
                values={slocSelectedValues}
                onChange={onSlocSelection}
                filterable={false}
                showError={showError && !masterDataLoading}
                customStyle={{
                  minWidth: "7vw",
                  minHeight: "3vh",
                }}
              />
            </span>
            <span className="filter">
              <label htmlFor="Plant">Plant</label>
              <MultiSelectionDropDown
                data={filters.plants}
                values={plantSelectedValues}
                onChange={onPlantSelection}
                filterable={false}
                showError={showError && !masterDataLoading}
                customStyle={{
                  minWidth: "7vw",
                  minHeight: "3vh",
                }}
              />
            </span>
            <span className="filter">
              <label htmlFor="Threshold">Threshold</label>
              <span className="filter-row">
                <span>
                  <Input
                    value={sliderValue.start}
                    onChange={(e) => {
                      onSliderChange(e, "start");
                    }}
                  />
                </span>
                <span>
                  <Input
                    value={sliderValue.end}
                    onChange={(e) => {
                      onSliderChange(e, "end");
                    }}
                  />
                </span>
              </span>
              <span>
                <RangeSlider
                  defaultValue={sliderValue}
                  value={sliderValue}
                  step={1}
                  min={differenceVal.min}
                  max={differenceVal.max}
                  onChange={(event) => {
                    const value = {
                      start: Math.round(event?.value?.start),
                      end: Math.round(event?.value?.end),
                    };
                    setSliderValue(value);
                  }}
                ></RangeSlider>
              </span>
            </span>
          </>
        )}

        <Button
          className={
            selectedTab === AFDTabOptions.DELIVERY
              ? "get-data-btn-delivery"
              : "get-data-btn-change"
          }
          onClick={fetchData}
          disabled={fetchingData}
        >
          {fetchingData ? "Fetching..." : "Get data"}
        </Button>
        <Button
          className={
            selectedTab === AFDTabOptions.DELIVERY
              ? "refresh-btn-delivery"
              : "refresh-btn-change"
          }
          onClick={() => {
            fetchAccountForDeliveriesReport(true);
          }}
          disabled={!appliedFilters}
        >
          Refresh
        </Button>
      </div>
    );
  };

  const showGridToolBar = () => {
    return (
      <GridToolbar>
        <div className="gridToolbar">
          {headerFilters()}
          <div className="rowContainer">{headerButtons()}</div>
        </div>
      </GridToolbar>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };
  return (
    <>
      <div className="account-for-deliveries-screen-container data-grid">
        <div className="row ">
          <div className="data-grid-container">
            {showTabComponent()}
            {showGridToolBar()}
            {showSiteInfoModal && (
              <SiteInfoScreen
                globalSiteId={selectedSite?.siteId}
                countryCode={selectedSite?.country || currentRegion}
                close={closeModal}
              />
            )}
            {selectedTab === AFDTabOptions.DELIVERY ? (
              <span className="grid-container">
                <AppDataGrid
                  resetIndex={resetIndex}
                  setResetIndex={setResetIndex}
                  take={DEFAULT_PAGE_SIZE}
                  style={{
                    height: "57.5vh",
                  }}
                  pageable={true}
                  filterable={true}
                  data={accountForDeliveriesData}
                  expandable={true}
                  detailsContainer={CustomDetailsContainer}
                  columnsToShow={colsToShow(
                    accountForDeliveriesData,
                    commentsOptions,
                    inputRef,
                    selectedItem,
                    handleDataChange,
                    currentRegion,
                    handleShipToLinkAction
                  )}
                  onItemChange={handleDataChange}
                  sortable={true}
                  ref={exportGridRef}
                  exportFileName="AFD Report"
                  exportData={exportExcelData}
                  columnsToExport={colsToExport(currentRegion)}
                  // rowHeight={40}
                  // total={accountForDeliveriesData?.total}
                  // scrollable={"virtual"}
                  // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
                />
              </span>
            ) : (
              <span className="grid-container">
                <AppDataGrid
                  resetIndex={resetIndex}
                  setResetIndex={setResetIndex}
                  take={DEFAULT_PAGE_SIZE}
                  style={{
                    height: "61vh",
                  }}
                  pageable={true}
                  filterable={true}
                  data={auditData}
                  expandable={false}
                  columnsToShow={auditColsToShow(
                    currentRegion,
                    handleShipToLinkAction
                  )}
                  sortable={true}
                  ref={exportAuditGridRef}
                  exportFileName="AFD Audit Report"
                />
              </span>
            )}
            {masterDataLoading && (
              <LoadingPanel message="Fetching the config data.. please wait.." />
            )}
            {exporting && (
              <LoadingPanel message="Exporting the data.. please wait.." />
            )}
            {fetchingData && (
              <LoadingPanel message="Fetching the data.. please wait.." />
            )}
            {submitting && (
              <LoadingPanel message="Updating Deliveries.. please wait.." />
            )}
          </div>
        </div>
        {showAddDelivery && (
          <AddDelivery
            filters={filters}
            filterData={filterData}
            commentOptions={commentsOptions}
            cancelDelivery={cancelDelivery}
            saveDelivery={saveDelivery}
            currentRegion={currentRegion}
          />
        )}
      </div>
    </>
  );
};
export { AccountForDeliveries };
