import { loggerService } from "../services/logger";

loggerService.dev("Cerating Actions");

/**
 * An action that saves the user into the global store and updates the global state.
 * @param {object} user - The user object containing user details.
 */
const saveLoggedInUser = (user) => {
  return {
    type: "SAVE_LOGGED_IN_USER",
    payload: user,
  };
};

/**
 * An action that updates the current language in the global state.
 * @param {object} newLanguage - The new language object containing the newly selected language.
 */
const changeLanguage = (newLanguage) => {
  return {
    type: "CHANGE_LANGUAGE",
    payload: {
      newLanguage,
    },
  };
};

/**
 * An action that saves the user screens retrived from the remote server.
 * @param {object} userScreenList - The user screen list object containing screens that user have access to.
 */
const setUserScreenList = (screenList) => ({
  type: "SET_USER_SCREEN_LIST",
  payload: screenList,
});

/**
 * An action that saves the currently selected report into the global state.
 * @param {object} selectedReport - The selected report object containing report details.
 */
const setSelectedReport = (report) => ({
  type: "SET_SELECTED_REPORT",
  payload: report,
});

/**
 * An action that saves the site data to the global state.
 * @param {object} siteData - The siteData object containing site details.
 */
const persistSiteData = (siteData) => {
  return {
    type: "PERSIST_SITE_DATA",
    payload: siteData,
  };
};

const setRefreshToken = (refresh_token) => {
  return {
    type: "SET_REFRESH_TOKEN",
    payload: refresh_token,
  };
};

const setAccessToken = (access_token) => {
  return {
    type: "SET_ACCESS_TOKEN",
    payload: access_token,
  };
};

const setIdToken = (id_token) => {
  return {
    type: "SET_ID_TOKEN",
    payload: id_token,
  };
};

const triggerNotification = (notification) => {
  return {
    type: "TRIGGER_NOTIFICATION",
    payload: notification,
  };
};

const closeNotification = () => {
  return {
    type: "CLOSE_NOTIFICATION",
  };
};

const saveFormData = (formData) => {
  return {
    type: "SAVE_FORM_DATA",
    payload: formData,
  };
};

const setCurrentRegion = (region) => {
  return {
    type: "SET_SELECTED_REGION",
    payload: region,
  };
};
const clearSiteData = () => {
  return {
    type: "CLEAR_SITE_DETAILS",
  };
};

const updateDetails = (details) => {
  return {
    type: "UPDATE_SITE_DETAILS",
    payload: details,
  };
};

loggerService.dev("Exporting Actions");
export {
  saveLoggedInUser,
  changeLanguage,
  setUserScreenList,
  setSelectedReport,
  persistSiteData,
  setRefreshToken,
  setAccessToken,
  setIdToken,
  triggerNotification,
  closeNotification,
  saveFormData,
  setCurrentRegion,
  updateDetails,
  clearSiteData,
};
