import React from "react";

import "./account-for-deliveries.scss";
import { AccountForDeliveries } from "./account-for-deliveries-screen.jsx";

const AccountForDeliveriesContainer = () => {
  return (
    <>
      <div className="out-of-trend-analysis-screen-container">
        <div className="row content-container">
          <AccountForDeliveries />
        </div>
      </div>
    </>
  );
};

export { AccountForDeliveriesContainer };
