import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./out-of-tolerance-trend-analysis.scss";
import { reportsService } from "../../../../../services/reports";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { tankColsToShow } from "./out-of-tolerance-trend-analysis-utils";

const SiteInfoItems = {
  BASIC_INFO: "Basic Information",
  TANK_INFO: "Tank Details",
};

const SiteInfoConfig = [
  {
    id: SiteInfoItems.BASIC_INFO,
    category: SiteInfoItems.BASIC_INFO,
  },
  {
    id: SiteInfoItems.TANK_INFO,
    category: SiteInfoItems.TANK_INFO,
  },
];

const SiteInfoScreen = (props) => {
  const globalSiteId = props?.globalSiteId;
  const countryCode = props?.countryCode;
  const [expandedItems, setExpandedItems] = useState([SiteInfoConfig?.[0]]);
  const [loading, setLoading] = useState(false);
  const [siteInfo, setSiteInfo] = useState(false);

  const fetchSiteInfo = async () => {
    setLoading(true);
    let response = await reportsService.fetchSiteInfo({
      countryCode,
      globalSiteId,
    });

    // response = {
    //   ...response,
    //   TankInfo: [
    //     ...response.TankInfo,
    //     ...response.TankInfo,
    //     ...response.TankInfo,
    //   ],
    // };

    setSiteInfo(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBasicInfo = () => {
    return (
      <div className="contentContainer">
        <div className="leftContainer">
          <div className="row">
            <div className="labelContainer ">Site Name: </div>
            <div className="valueContainer">{siteInfo?.Name}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">Ship To: </div>
            <div className="valueContainer">{siteInfo?.GlobalSiteId}</div>
          </div>

          <div className="row">
            <div className="labelContainer ">Site Id: </div>
            <div className="valueContainer">{siteInfo?.SiteId}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">OBN: </div>
            <div className="valueContainer">{siteInfo?.OBN}</div>
          </div>
        </div>
        <div className="middleContainer">
          <div className="row">
            <div className="labelContainer ">Country: </div>
            <div className="valueContainer">{siteInfo?.CountryCode}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">Region: </div>
            <div className="valueContainer">{siteInfo?.Region}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">PLANT: </div>
            <div className="valueContainer">{siteInfo?.PLANT}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">SLOC: </div>
            <div className="valueContainer">{siteInfo?.SLOC}</div>
          </div>
        </div>
        <div className="rightContainer">
          <div className="row">
            <div className="labelContainer ">Source: </div>
            <div className="valueContainer">{siteInfo?.Source}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">Last Updated On: </div>
            <div className="valueContainer">{siteInfo?.Timestamp}</div>
          </div>
          <div className="row">
            <div className="labelContainer ">Status: </div>
            <div
              className="valueContainer"
              style={{
                color:
                  siteInfo?.SiteStatus?.toLowerCase() === "active"
                    ? "green"
                    : "red",
              }}
            >
              {siteInfo?.SiteStatus}
            </div>
          </div>
          <div className="row">
            <div className="labelContainer">Closed On: </div>
            <div className="valueContainer closed-status">
              {siteInfo?.SiteClosingDate}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getTankInfoDetails = () => {
    return (
      <div className="contentContainer">
        <div className="out-of-trend-analysis-site-info">
          <AppDataGrid
            style={{ maxHeight: "30vh" }}
            take={1000000000}
            total={siteInfo?.TankInfo?.length}
            rowHeight={30}
            pageable={false}
            filterable={true}
            data={siteInfo?.TankInfo}
            columnsToShow={tankColsToShow(siteInfo?.TankInfo)}
          />
        </div>
      </div>
    );
  };

  const getContent = (id) => {
    switch (id) {
      case SiteInfoItems.BASIC_INFO:
        return getBasicInfo();

      case SiteInfoItems.TANK_INFO:
        return getTankInfoDetails();

      default:
        break;
    }
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">{`Site Information: ${globalSiteId}`}</div>
        <div className="close-btn-container">
          <Button
            className="close-btn"
            onClick={() => {
              props?.close();
            }}
          >
            Close
          </Button>
        </div>
      </div>
    );
  };

  const showContent = () => {
    return (
      <div className="deliveryContent">
        <div className="contentWrapper">
          <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
            {SiteInfoConfig.map((item) => {
              const expanded = expandedItems?.find(
                (expandedItem) => expandedItem.id === item.id
              );
              const isExpanded = expanded ? true : false;
              return (
                <ExpansionPanel
                  className={isExpanded ? "expanded" : ""}
                  title={item.category}
                  expanded={isExpanded}
                  key={item.id}
                  onAction={(event) => {
                    if (event.syntheticEvent.type === "click") {
                      const expanded = expandedItems?.find(
                        (expandedItem) => expandedItem.id === item.id
                      );
                      if (!expanded) {
                        setExpandedItems((prev) => {
                          return [...prev, item];
                        });
                      } else {
                        const items = expandedItems?.filter(
                          (expandItem) => expandItem.id !== item.id
                        );
                        setExpandedItems(items);
                      }
                    }
                  }}
                >
                  <Reveal>
                    {isExpanded && (
                      <ExpansionPanelContent>
                        <div className="content">
                          <div onKeyDown={(e) => e.stopPropagation()}>
                            {getContent(item.id)}
                          </div>
                        </div>
                      </ExpansionPanelContent>
                    )}
                  </Reveal>
                </ExpansionPanel>
              );
            })}
            <div className="emptyItem"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="deliveryContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent()}
        {loading && (
          <span className="loaderContainer">
            <LoadingPanel message="Loading the form please wait..." />
          </span>
        )}
      </div>
    </div>
  );
};

export { SiteInfoScreen };
