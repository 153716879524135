import { getMonths } from "../../reportUtils";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import {
  MissingIconCell,
  MissingMandatoryCell,
  MultilineTextCell,
} from "../common-utils";
import { GainLossTabOptions } from "./gain-loss-overview";

export const getMonthIndex = (name) => {
  const monthsData = getMonths();
  const month = monthsData?.find((month) => month.text === name);
  return month ? month.id : 0;
};

export const getTankGroups = (masterData, filters, productSelectedValues) => {
  const siteData = masterData?.find(
    (site) => site?.globalSiteId === filters?.selectedSiteId
  );
  let productTanks = [];
  productSelectedValues?.forEach((product) => {
    const tanks = siteData?.siteTanks?.filter(
      (tank) => tank?.materialNumber === product?.id || product?.id === "All"
    );
    if (tanks) {
      productTanks?.push(...tanks);
    }
  });

  let tankGroupData = [];

  if (productTanks?.length > 0) {
    const tankGroup = Object.groupBy(productTanks, (item) => item?.tankGroupId);

    tankGroupData = Object.keys(tankGroup)?.map((key) => {
      const groupItem = {
        text: key,
        id: key,
        group: true,
      };
      return groupItem;
    });
  }

  return tankGroupData;
};

export const addAllOptions = (items) => {
  return [
    {
      id: "All",
      text: "All",
    },
    ...items,
  ];
};

export const colsToShow = (gainLossData, selectedTab, currentRegion) => {
  let columns = [
    {
      title: "Date",
      field: "date",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.06,
      filterable: true,
    },
    {
      title: "Material No",
      field: "material",
      headerCell: CustomHeaderCell,
      width:
        selectedTab === GainLossTabOptions.MATERIAL
          ? window.innerWidth * 0.08
          : window.innerWidth * 0.06,
      filterable: true,
    },
  ];
  if (selectedTab === GainLossTabOptions.TANK_GROUP) {
    columns = [
      ...columns,
      {
        title: "Tank Group",
        field: "tankGroupName",
        headerCell: CustomHeaderCell,
        width: window.innerWidth * 0.05,
        filterable: true,
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: "Outbound Delivery No",
      field: "outBound",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Outbound Delivery ID's." />;
      },
      cell: (props) => {
        return <MultilineTextCell {...props} />;
      },
      filterable: true,
    },
    {
      title: "Stock",
      field: "stock",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Stock in liters." />;
      },
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
      width: window.innerWidth * 0.06,
      filterable: true,
    },
    {
      title: "Sales",
      field: "sales",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Sales in liters." />;
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * 0.06,
      filterable: true,
    },
    {
      title: "Deliveries",
      field: "deliveries",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Deliveries in liters." />;
      },
      width: window.innerWidth * 0.07,
      filterable: true,
    },
    {
      title: "Variance",
      field: "differences",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Variance in liters." />;
      },
      cell: (props) => {
        return (
          <MissingIconCell
            {...props}
            dependent={{
              field: "stock",
              message:
                "Missing Stock, variance is calculated using current book stock.",
            }}
          />
        );
      },
      width: window.innerWidth * 0.07,
      filterable: true,
    },
    {
      title: "Cum. Variance",
      field: "cumDiff",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label="Cumulative variance in liters." />
        );
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * 0.07,
      filterable: true,
    },
    {
      title: "Cum. Sales",
      field: "cumSales",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Cumulative sales." />;
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * 0.07,
      filterable: true,
    },
    {
      title: "Variance%",
      field: "diffPercent",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Variance in %." />;
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * 0.07,
      filterable: true,
    },
    {
      title: "Cum. Variance%",
      field: "cumDiffPercent",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label="Cumulative variance in %." />
        );
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * 0.07,
      filterable: true,
    },
  ];

  return columns;
};

export const colsToExport = (selectedTab, currentRegion) => {
  let columns = [
    {
      title: "Date",
      field: "date",
    },
    {
      title: "Ship To",
      field: "globalSiteId",
    },
    {
      title: "OBN",
      field: "obn",
    },
    {
      title: "SLOC",
      field: "sloc",
    },
    {
      title: "Plant",
      field: "plant",
    },
    {
      title: "Material No",
      field: "material",
    },
  ];
  if (selectedTab === GainLossTabOptions.TANK_GROUP) {
    columns = [
      ...columns,
      {
        title: "Tank Group",
        field: "tankGroupName",
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: "Stock",
      field: "stock",
    },
    {
      title: "Sales",
      field: "sales",
    },
    {
      title: "Deliveries",
      field: "deliveries",
    },
    {
      title: "Delivery ID's",
      field: "outBound",
    },
    {
      title: "Variance",
      field: "differences",
    },
    {
      title: "Variance%",
      field: "diffPercent",
    },
    {
      title: "Cum. Variance",
      field: "cumDiff",
    },
    {
      title: "Cum. Variance%",
      field: "cumDiffPercent",
    },
    {
      title: "Cum. Sales",
      field: "cumSales",
    },
  ];

  return columns;
};
