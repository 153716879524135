import { Input } from "@progress/kendo-react-all";
import { getFormattedDate } from "../../../../../services/utils";
import { MissingIconCell } from "../common-utils";

export const PumpIndexViewOptions = {
  MATERIAL: 0,
  TANK: 1,
};

const CustomHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const classNames =
    props?.field === "dateOfPumpIndex"
      ? "k-customHeader k-customHeaderLine"
      : "k-customHeader";
  return (
    <th {...props}>
      <div className={classNames} title={props?.label}>
        {columnTitle}
        {props.children}
      </div>
    </th>
  );
};

const valueExist = (dataItem) => {
  const hasValue =
    dataItem?.pumpReadings.length ||
    dataItem?.deliveries.length ||
    dataItem?.overallPumpSales ||
    dataItem?.posSalesQuantity ||
    dataItem?.posSalesVariancePercentage ||
    dataItem?.posSalesVarianceQuantity;
  return !(hasValue === null || hasValue === undefined);
};

const TextInputCell = (props) => {
  const { dataItem, field, selectedItem, onChange, inputRef, isMaterial } =
    props;
  const sameItem = selectedItem ? isSame(dataItem, selectedItem, field) : false;

  if (props.dataItem?.locked) {
    let styles = {};
    let classNames = props.className;
    styles.bottom = 0;
    classNames += " k-grid-row-sticky";
    return (
      <td
        style={{
          textAlign: "center",
          ...styles,
        }}
        className={classNames}
        onClick={() => props.onClick(props.dataItem)}
      ></td>
    );
  }
  if (valueExist(props.dataItem)) {
    if (!isMaterial) {
      return <td>{dataItem?.[field]}</td>;
    }
    return (
      <td className="editableCell">
        <Input
          ref={sameItem ? inputRef : null}
          value={dataItem?.[field]}
          onChange={(event) => {
            const position = event.target.element?.selectionStart;
            const inputValue = event.target.value;
            const newDataItem = { ...dataItem, [field]: inputValue };
            onChange({
              dataItem: newDataItem,
              field: field,
              syntheticEvent: event,
              position: position,
            });
          }}
        />
      </td>
    );
  }
  return <td className="noDataField">No data received!</td>;
};

export const isSame = (dataItem, selectedItem, field) => {
  return (
    dataItem.dateOfPumpIndex === selectedItem.dateOfPumpIndex &&
    dataItem.outBound === selectedItem.outBound &&
    field === selectedItem?.editField
  );
};

const getStaticPumpColumns = () => {
  const staticPumps = [];
  for (let index = 0; index < 2; index++) {
    const name = index === 0 ? `Pump x` : `Pump y`;
    const element = {
      title: name,
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={name} />;
      },
      columns: [
        {
          title: "Meter Reading",
          field: "pump1MeterReading",
          headerCell: (props) => {
            return <CustomHeaderCell {...props} label="Meter Reading" />;
          },
          width: 80,
        },
        {
          title: "Quantity",
          field: "pump1Qty",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label="Quantity in litres at 15 °C"
              />
            );
          },
          label: "testing",
          width: 80,
        },
      ],
    };
    staticPumps.push(element);
  }
  return staticPumps;
};

export const getFooterItem = (response) => {
  let footerItem = {
    dateOfPumpIndex: "TOTAL",
    dateOfFuel: "",
    outBound: "",
    comments: "",
    locked: true,
    deliveryQty: "",
    existingStock: "",
    overallSales: "",
    salesQtyPos: "",
    varianceQty: "",
    variancePercent: "",
    footerItem: true,
  };
  let pumpData = {};

  let openingStock = null;

  response?.forEach((item) => {
    item?.pumpReadings?.forEach((pump) => {
      const key = `pump${pump.pumpNumber}qty`;
      const qty = pump?.salesQuantity;
      const current = parseInt(pumpData[key]);
      pumpData[key] = current ? current + qty : qty;
    });
    const deliveryQty = parseFloat(item?.deliveryQty);
    if (deliveryQty) {
      footerItem.deliveryQty =
        Math.round(footerItem?.deliveryQty) + deliveryQty;
    }
    const existingStock = parseFloat(item?.existingStock);
    if (existingStock) {
      footerItem.existingStock = existingStock;
    }

    const overallSales = parseFloat(item?.overallSales);
    if (overallSales) {
      footerItem.overallSales =
        Math.round(footerItem?.overallSales) + overallSales;
    }

    const salesQtyPos = parseFloat(item?.salesQtyPos);
    if (salesQtyPos) {
      footerItem.salesQtyPos =
        Math.round(footerItem?.salesQtyPos) + salesQtyPos;
    }

    if (!openingStock && footerItem.existingStock) {
      openingStock = footerItem.existingStock;
    }
  });

  let varianceQty =
    openingStock -
    footerItem.existingStock +
    footerItem.overallSales -
    footerItem.deliveryQty;

  varianceQty =
    openingStock && footerItem.existingStock !== openingStock
      ? Math.round(varianceQty)
      : openingStock
      ? 0
      : "";

  const percentage = Math.round(varianceQty / footerItem.overallSales);
  const variancePercent = isNaN(percentage) ? "" : percentage;

  let deliveryQty = Math.round(parseFloat(footerItem.deliveryQty));
  deliveryQty = isNaN(deliveryQty) ? "" : deliveryQty;

  let existingStock = Math.round(parseFloat(footerItem.existingStock));
  existingStock = isNaN(existingStock) ? "" : existingStock;

  let overallSales = Math.round(parseFloat(footerItem.overallSales));
  overallSales = isNaN(overallSales) ? "" : overallSales;

  let salesQtyPos = Math.round(parseFloat(footerItem.salesQtyPos));
  salesQtyPos = isNaN(salesQtyPos) ? "" : salesQtyPos;

  return {
    ...footerItem,
    deliveryQty: deliveryQty,
    existingStock: existingStock,
    overallSales: overallSales,
    salesQtyPos: salesQtyPos,
    varianceQty: varianceQty,
    variancePercent: (variancePercent !== Infinity && variancePercent !== -Infinity) ? variancePercent : '',
    ...pumpData,
  };
};

export const getDeliveryDate = (deliveries) => {
  const dates = deliveries?.map((delivery) =>
    getFormattedDate(new Date(delivery?.deliveryDateTime))
  );
  const deliveryDate = [...new Set(dates)]?.join("\n ");
  return deliveryDate;
};

export const getDeliveryIds = (deliveries) => {
  const deliveryIds = deliveries?.map((delivery) => delivery?.deliveryId);
  const deliveryId = [...new Set(deliveryIds)]?.join("\n ");
  return deliveryId;
};

export const getDeliveryQty = (deliveries) => {
  const quantityValues = deliveries?.map((delivery) => delivery?.quantity);

  if (quantityValues?.length > 0) {
    const totalQty = quantityValues?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    return totalQty.toFixed(0);
  }
  return "";
};

const TestSalesCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {value?.toLowerCase() === "yes" ? (
        <span className="HasTestSalesValue k-grid-row-sticky">{value}</span>
      ) : (
        value
      )}
    </td>
  );
};

const HighlightedCell = (props) => {
  const { dataItem, field } = props;
  let value = dataItem?.[field];
  let difference = 0;
  if (
    field === "overallSales" ||
    field === "salesQtyPos" ||
    field === "salesDifference"
  ) {
    difference = isNaN(dataItem?.salesDifference)
      ? 0
      : dataItem?.salesDifference;
  }
  if (field === "salesDifference") {
    value = isNaN(value) ? "" : value;
  }
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {difference !== 0 && !dataItem?.footerItem ? (
        <span className="highlightedField">{value}</span>
      ) : (
        value
      )}
    </td>
  );
};

export const colsToShow = (
  trendAnalysisData,
  selectedMode,
  ref,
  onChange,
  selectedItem,
  screenWidth
) => {
  const firstRecord = trendAnalysisData?.[0];
  const haveRecords =
    firstRecord?.overallSales?.toString()?.length > 0 ||
    firstRecord?.salesQtyPos?.toString()?.length > 0 ||
    firstRecord?.overallPosTestSalesQuantity?.toString()?.length > 0 ||
    firstRecord?.existingStock?.toString()?.length > 0 ||
    firstRecord?.varianceQty?.toString()?.length > 0 ||
    firstRecord?.variancePercent?.toString()?.length > 0;

  const validRow = trendAnalysisData?.find(
    (item) => item?.pumpReadings?.length > 0
  );
  const totalPumps = validRow?.pumpReadings?.length;
  const totalColumns =
    selectedMode === PumpIndexViewOptions.TANK
      ? 6 + totalPumps * 2
      : 10 + totalPumps * 2;
  const ratio = 1.12 / totalColumns;
  let totalWidth = totalColumns * (screenWidth * ratio) - screenWidth * 0.2;
  const minResizableWidth = 80;
  let itemWidth = parseInt(totalWidth / totalColumns);
  itemWidth = itemWidth < minResizableWidth ? minResizableWidth : itemWidth;
  let columns = [
    {
      title: "Date",
      field: "dateOfPumpIndex",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label="Date of Pump Index Reading" />
        );
      },
      width: window.innerWidth * 0.06,
      locked: true,
      minResizableWidth:
        itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
    },
    {
      title: "Deliveries",
      locked: true,
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Deliveries" />;
      },
      columns: [
        {
          title: "Dates",
          field: "dateOfFuel",
          headerCell: (props) => {
            return (
              <CustomHeaderCell {...props} label="Date Time of Fuel Delivery" />
            );
          },
          locked: true,
          width: itemWidth * 1.2,
          minResizableWidth:
            itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
        },
        {
          title: "Delivery Ids",
          field: "outBound",
          headerCell: (props) => {
            return (
              <CustomHeaderCell {...props} label="OutBound Delivery ID's" />
            );
          },
          locked: true,
          width: itemWidth * 1.2,
          minResizableWidth:
            itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
        },
        {
          title: "Quantity",
          field: "deliveryQty",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label="Fuel Quantity Delivered in litres at 15 °C"
              />
            );
          },
          locked: true,
          width: itemWidth,
          minResizableWidth:
            itemWidth > minResizableWidth ? itemWidth : minResizableWidth,
        },
      ],
    },
  ];

  const pumpColumns = haveRecords
    ? validRow?.pumpReadings?.map((pump) => {
        return {
          title: `Pump ${pump?.pumpNumber}`,
          headerCell: (props) => {
            return (
              <CustomHeaderCell {...props} label={`Pump ${pump?.pumpNumber}`} />
            );
          },
          columns: [
            {
              title: "Reading",
              field: `pump${pump.pumpNumber}meterReading`,
              headerCell: (props) => {
                return <CustomHeaderCell {...props} label="Meter Reading" />;
              },
              cell: (props) => {
                return <MissingIconCell {...props} />;
              },
              width: itemWidth,
            },
            {
              title: "Quantity",
              field: `pump${pump.pumpNumber}qty`,
              cell: (props) => {
                return <MissingIconCell {...props} />;
              },
              headerCell: (props) => {
                return (
                  <CustomHeaderCell
                    {...props}
                    label="Quantity in litres at 15 °C"
                  />
                );
              },
              width: itemWidth,
            },
          ],
        };
      })
    : getStaticPumpColumns();

  if (Array.isArray(pumpColumns)) {
    columns = [...columns, ...pumpColumns];
  }

  if (selectedMode === PumpIndexViewOptions.MATERIAL) {
    columns = [
      ...columns,
      {
        title: "Pump Sales",
        field: "overallSales",
        cell: HighlightedCell,
        headerCell: (props) => {
          return <CustomHeaderCell {...props} label="Overall Pump Sales" />;
        },
        width: itemWidth,
      },
      {
        title: "POS Sales",
        field: "salesQtyPos",
        cell: HighlightedCell,
        headerCell: (props) => {
          return (
            <CustomHeaderCell {...props} label="Sales Quantity from POS" />
          );
        },
        width: itemWidth,
      },
      {
        title: "Sales Difference",
        field: "salesDifference",
        cell: HighlightedCell,
        headerCell: (props) => {
          return <CustomHeaderCell {...props} label="Sales Difference" />;
        },
        width: itemWidth,
      },
      {
        title: "Has Test Sales",
        field: "hasTestSales",
        cell: TestSalesCell,
        headerCell: (props) => {
          return <CustomHeaderCell {...props} label="Has Test Sales" />;
        },
        width: itemWidth,
      },
      {
        title: "Test Sales",
        field: "overallPosTestSalesQuantity",
        headerCell: (props) => {
          return <CustomHeaderCell {...props} label="Test Sales" />;
        },
        width: itemWidth,
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: "Stock",
      field: "existingStock",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Existing Stock" />;
      },
      width: itemWidth,
    },
  ];

  if (selectedMode === PumpIndexViewOptions.MATERIAL) {
    columns = [
      ...columns,
      {
        title: "Variance [Pump Sales]",
        headerCell: (props) => {
          return (
            <CustomHeaderCell
              {...props}
              label="Current Date Stock- Prev Date Stock+ Overall Pump Sales-Deliveries"
            />
          );
        },
        columns: [
          {
            title: "Quantity",
            field: "varianceQty",
            headerCell: (props) => {
              return (
                <CustomHeaderCell
                  {...props}
                  label="Quantity in litres at 15 °C"
                />
              );
            },
            width: itemWidth,
          },
          {
            title: "Variance %",
            field: "variancePercent",
            headerCell: (props) => {
              return <CustomHeaderCell {...props} label="Variance %" />;
            },
            width: itemWidth,
          },
        ],
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: "Comments",
      field: "comments",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Comments" />;
      },
      width: screenWidth * 0.2,
      cell: (props) => {
        return (
          <TextInputCell
            {...props}
            inputRef={ref}
            onChange={onChange}
            selectedItem={selectedItem}
            isMaterial={selectedMode === PumpIndexViewOptions.MATERIAL}
          />
        );
      },
    },
  ];

  return columns;
};

export const exceptionColsToShow = (trendAnalysisData) => {
  let columns = [
    {
      title: "Ship To",
      field: "globalSiteId",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Ship To" />;
      },
    },
    {
      title: "Material Name",
      field: "materialName",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label="Material Name" />;
      },
    },
    {
      title: "Delivered Quantity",
      field: "totalDeliveries",
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label="Delivered - Quantity in litres at 15 °C"
          />
        );
      },
    },
    {
      title: "Overall Pump Sales Quantity",
      field: "totalOverallPumpSales",
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label="Overall Pump Sales - Quantity in litres at 15 °C"
          />
        );
      },
    },
    {
      title: "Existing Stock Quantity",
      field: "endOfMonthStock",
      headerCell: (props) => {
        return (
          <CustomHeaderCell
            {...props}
            label="Existing Stock - Quantity in litres at 15 °C"
          />
        );
      },
    },
    {
      title: "Monthly Variance (Gain/Loss)",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label="Monthly Variance (Gain/Loss)" />
        );
      },
      columns: [
        {
          title: "Quantity",
          field: "varianceQuantity",
          headerCell: (props) => {
            return (
              <CustomHeaderCell
                {...props}
                label="Quantity in litres at 15 °C"
              />
            );
          },
        },
        {
          title: "Variance in %",
          field: "variancePercentage",
          headerCell: (props) => {
            return <CustomHeaderCell {...props} label="Variance in %" />;
          },
        },
      ],
    },
  ];

  return columns;
};
