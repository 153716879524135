import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";

const BarChart = (props) => {
  const SharedTooltip = (props) => {
    const { category, points } = props;
    return (
      <div>
        <div>{category}</div>
        {points.map((point, i) => (
          <div key={i}>
            {point.category} : {point.dataItem}
          </div>
        ))}
      </div>
    );
  };

  const roundToNextHundred = (num) => Math.ceil(num / 100) * 100;
  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;
  const maxValue = Math.max(...props.yAxisData)
  const yMax = maxValue > 100 ? maxValue : 100
  return (
    <Chart style={chartStyle}>
      <ChartTooltip shared={true} render={sharedTooltipRender} />
      <ChartTitle text={props?.title} />
      <ChartLegend position="top" orientation="horizontal" />
      <ChartValueAxis>
        <ChartValueAxisItem
          title={{
            text: "Count",
          }}
          min={0}
          max={roundToNextHundred(yMax)}
        />
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={props.xAxisData}
          startAngle={45}
          labels={{
            rotation: "auto",
          }}
        />
      </ChartCategoryAxis>
      <ChartSeries>
        <ChartSeriesItem
          tooltip={{
            visible: true,
          }}
          color="#66c5d2"
          type="column"
          data={props.yAxisData}
        />
      </ChartSeries>
    </Chart>
  );
};

export { BarChart };

const chartStyle = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "100%",
  padding: "2%",
};
