import * as React from "react";
import {
  MultiSelectTree,
  getMultiSelectTreeValue,
} from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState } from "./data-operations";
import { useEffect } from "react";
const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "text";
const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

export const MultiSelectTreeDropdown = (props) => {
  const [expanded, setExpanded] = React.useState([]);
  const [filter, setFilter] = React.useState(null);
  const [data] = React.useState(props.data || []);
  const [value, setValue] = React.useState([]);

  useEffect(() => {
    if (!props.preSelectionEnabled)
      setExpanded(props.data.length > 0 ? [props.data[0][dataItemKey]] : []);
    else {
      const sites = props.dataItem ? (props.dataItem.AccessControl) : [];
      if (sites && sites.includes('DE')) {
        const preSelectedValues = ([...data[0].items]);
        setValue(preSelectedValues);
        return;
      }
      const sitesInitiallySelected = [];
      for (let i = 0; i < data[0].items.length; i++) {
        const site = data[0].items[i];
        for (let j = 0; j < sites.length; j++) {
          const assignedSite = sites[j];
          if (assignedSite === site.text) {
            sitesInitiallySelected.push(site);
          }
        }
      }
      setValue(sitesInitiallySelected);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onChange = (event) => {
    if (props.preSelectionEnabled) {
      const updatedValue = getMultiSelectTreeValue(data, {
        ...fields,
        ...event,
        value
      });
      setValue(updatedValue);
      props.onChange(updatedValue);
    } else {
      props.onChange(
        getMultiSelectTreeValue(props.data, {
          ...fields,
          ...event,
          value: props.value,
        })
      );
    }
  };

  const onExpandChange = React.useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const treeData = React.useMemo(
    () =>
      props.data.length > 0
        ? processMultiSelectTreeData(props.data, {
          expanded,
          value: props.preSelectionEnabled ? value : props.value,
          filter,
          ...fields,
        })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expanded, props.preSelectionEnabled ? value : props.value, filter]
  );

  const onFilterChange = (event) => setFilter(event.filter);

  return (
    <MultiSelectTree
      style={props.style}
      name={props.name}
      label={props.label}
      data={treeData}
      value={props.preSelectionEnabled ? value : props.value}
      required={true}
      onChange={onChange}
      textField={textField}
      dataItemKey={dataItemKey}
      checkField={checkField}
      checkIndeterminateField={checkIndeterminateField}
      expandField={expandField}
      subItemsField={subItemsField}
      onExpandChange={onExpandChange}
      filterable={props.data.length > 0}
      onFilterChange={onFilterChange}
      validityStyles={props.validityStyles}
      validationMessage=""
      listNoData={props.noData}
      disabled={props.disabled}
      tags={props.preSelectionEnabled && [{ text: `${value.length} items selected` }]}
    />
  );
};
