import { loggerService } from "../services/logger";

loggerService.dev("Creating Iniaital Application State");

/**
 * This is the initial state of this application, which will be updated based on user's actions.
 */
const initialState = {
  user: null,
  language: "english",
  selectedReport: null,
  siteData: null,
  userScreenList: [],
  refresh_token: null,
  access_token: null,
  id_token: null,
  notification: [],
  selectedRegion: null,
};

loggerService.dev("Creating The Primary Reducer");

/**
 * This is the primary reducer function that is responsible of updating the global state based on user's action.
 * @param {object} state - The state of the application, defaults to initial state.
 * @param {object} action - The action being dispatched that in turn will update the global state.
 */
const primaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_LOGGED_IN_USER":
      if (!action.payload) return state;
      state.user = action.payload;
      return state;

    case "CHANGE_LANGUAGE":
      const newLanguage =
        action.payload &&
        (action.payload["newLanguage"]
          ? action.payload["newLanguage"]
          : "english");
      state.language = newLanguage;
      return state;

    case "SET_SELECTED_REPORT":
      state.selectedReport = action.payload;
      return state;

    case "SET_USER_SCREEN_LIST":
      state.userScreenList = action.payload;
      return state;

    case "PERSIST_SITE_DATA":
      if (!action.payload) return state;
      state.siteData = action.payload;
      return state;

    case "SET_REFRESH_TOKEN":
      if (!action.payload) return state;
      state.refresh_token = action.payload;
      return state;

    case "SET_ACCESS_TOKEN":
      if (!action.payload) return state;
      state.access_token = action.payload;
      return state;

    case "SET_ID_TOKEN":
      if (!action.payload) return state;
      state.id_token = action.payload;
      return state;

    case "TRIGGER_NOTIFICATION":
      if (!action.payload) return state;
      state.notification.push(action.payload);
      return state;

    case "CLOSE_NOTIFICATION":
      state.notification = [];
      return state;

    case "SAVE_FORM_DATA":
      state.formData = action.payload;
      return state;

    case "SET_SELECTED_REGION":
      state.selectedRegion = action.payload;
      state.siteData = [];
      return state;

    case "CLEAR_SITE_DETAILS":
      state.siteData = [];
      return state;

    case "UPDATE_SITE_DETAILS": {
      if (!action.payload) return state;
      const siteData = state.siteData?.map((site) => {
        return site?.GlobalSiteId === action.payload?.GlobalSiteId
          ? action.payload
          : site;
      });
      state.siteData = siteData;
      return state;
    }

    default:
      return state;
  }
};

loggerService.dev("Exporting The Primary Reducer");
export { primaryReducer };
