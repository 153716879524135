import { React, useEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import { triggerNotification } from "../../../state-management/actions";
import { useNavigate } from "react-router-dom";
import DropDown from "../../components/dropdown/dropdown";
import "./UserPreference.scss";
import { userService } from "../../../services/user";
import { saveLoggedInUser } from "../../../state-management/actions";

const UserPreferencePage = () => {
  const [userPreferenceData, setUserPreferenceData] = useState({
    DefaultCountry: "",
    Region: "",
  });

  const regionDropDownRef = useRef(null);
  const navigate = useNavigate();
  const store = useStore();

  const getUserDetailsFromStore = () => {
    const loggedInUser = store.getState()?.user;

    if (!loggedInUser) {
      navigate("/login");
      return;
    }
    setUserPreferenceData({
      DefaultCountry: loggedInUser.UserPreference?.DefaultCountry || "",
      Region: loggedInUser.UserPreference?.Region || "",
    });
  };
  const onClickSavePreference = async () => {
    const data = await userService.UpdateProfileService(userPreferenceData);
    if (data.data.Status === "Success") {
      const loggedInUser = store.getState()?.user;
      if (!loggedInUser) {
        navigate("/login");
        return;
      }
      const user = await userService.fetchUser(loggedInUser.UniqueUserId);
      store.dispatch(saveLoggedInUser(user));

      setUserPreferenceData({
        DefaultCountry: user.UserPreference?.DefaultCountry || "",
        Region: user.UserPreference?.Region || "",
      });
      store.dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: false,
          },
          message: `Preferences saved successfully.`,
        })
      );
    } else {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: false,
          },
          message: `Error occured while saving the preference.`,
        })
      );
    }
  };
  const handelReportChange = (option) => {
    const selected = option.selection;
    setUserPreferenceData({ ...userPreferenceData, Region: selected });
  };
  const handelCountryChange = (option) => {
    const selected = option.selection;
    if (userPreferenceData?.DefaultCountry !== selected) {
      const region = selected === "DE" ? "ALL" : "ALL";
      regionDropDownRef?.current?.resetSelection &&
        regionDropDownRef.current.resetSelection();
      setUserPreferenceData({
        ...userPreferenceData,
        DefaultCountry: selected,
        Region: region,
      });
    }
  };
  useEffect(() => {
    getUserDetailsFromStore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRegionOptions = () => {
    if (userPreferenceData?.DefaultCountry === "DE") {
      return [
        { title: "ALL", value: "ALL" },
        { title: "NORD", value: "NORD" },
        { title: "SUED", value: "SUED" },
        { title: "MP Others", value: "MP Others" },
        { title: "MP Pfennings", value: "MP Pfennings" },
      ];
    } else {
      return [
        { title: "ALL", value: "ALL" },
        { title: "BE", value: "BE" },
        { title: "NL-RBA", value: "NL-RBA" },
        { title: "NL-SE", value: "NL-SE" },
        { title: "NL-STD", value: "NL-STD" },
        { title: "FR", value: "FR" },
        { title: "LU", value: "LU" },
      ];
    }
  };

  return (
    <div className="container-fluid">
      <div className="row profile-page">
        <div className="container user-details-container">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 user-details-table-container">
              <h2 className="user-details-heading">User Preferences</h2>
              <table>
                <tbody>
                  <tr style={{ display: "flex" }}>
                    <td style={{ display: "flex", flex: 0.2 }}>
                      <b>Country</b>
                    </td>
                    <td>
                      <DropDown
                        defaultText={userPreferenceData.DefaultCountry}
                        style={{
                          button: {
                            color: "#595959",
                            width: "150%",
                            fontSize: "0.8vw",
                          },
                        }}
                        onDropdownSelection={handelCountryChange}
                        options={[
                          { title: "DE", value: "DE" },
                          { title: "BeneFrux", value: "BeneFrux" },
                        ]}
                        name="country"
                      />
                    </td>
                  </tr>
                  {/* <tr>
                      <td>
                        <b>Site</b>
                      </td>
                      <td>
                        <MultiSelect className="date-picker" />
                      </td>
                    </tr> */}
                  {/* <tr>
                      <td>
                        <b>Date</b>
                      </td>
                      <td>
                        <DatePicker
                          className="date-picker"
                          name="start"
                          onChange={handleDateChange}
                          format="dd-MMMM-yyyy"
                        />
                      </td>
                    </tr> */}
                  <tr style={{ display: "flex" }}>
                    <td style={{ display: "flex", flex: 0.2 }}>
                      <b>Region</b>
                    </td>
                    <td>
                      <DropDown
                        ref={regionDropDownRef}
                        defaultText={userPreferenceData?.Region}
                        style={{
                          button: {
                            color: "#595959",
                            width: "150%",
                            fontSize: "0.8vw",
                          },
                        }}
                        onDropdownSelection={handelReportChange}
                        options={getRegionOptions()}
                        name="reports"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button className="home-btn" onClick={onClickSavePreference}>
                Save
              </button>
              <button className="logout-btn" onClick={() => navigate("/home")}>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserPreferencePage };
