import React from "react";
import { PumpIndexMonthlyExceptionScreen } from "./pump-index-report-screen/pump-index-monthly-exception-screen";

const PumpIndexMonthlyExceptionContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <PumpIndexMonthlyExceptionScreen />
        </div>
      </div>
    </>
  );
};

export { PumpIndexMonthlyExceptionContainer };
