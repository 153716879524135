import "./about.scss";

const AboutPage = () => {
  return (
    <div className="about-container">
      <div className="content">
        <img src={require("../../../assets/logo.png")} alt="shell" />
        <h1>About us</h1>
        <p>
          WSM Analytics is a Digital Wetstock Data platform created for Business
          stakeholders to take better decisions with access to Wetstock Data.
          With advanced Reporting capabilities, Business can perceive trends of
          Sales & Stocks of Tanks at different time period across the Market.
        </p>
      </div>
    </div>
  );
};
export { AboutPage };
