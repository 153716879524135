import "./dataGrid.scss";
import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

const InlineEditGrid = (props) => {
  return (
    <Grid
      style={props?.style}
      data={props?.data}
      pageable={props?.pageable}
      filterable={props?.filterable}
      expandable={props?.expandable}
      take={props?.take}
      onItemChange={props?.onItemChange}
      editField={"inEdit"}
      cells={props?.cells}
    >
      {props?.columnsToShow?.map((col, index) => (
        <GridColumn
          field={col.field}
          title={col.title}
          filterable={col.filterable || false}
          filterCell={col.filterCell}
          filter={col.filter || "text"}
          key={index}
          editable={col.editable}
          editor={col.editor}
          cell={col?.cell}
          width={col?.width}
          headerCell={col?.headerCell}
          className={col?.className}
          minResizableWidth={col?.minResizableWidth}
          locked={col?.locked}
          sortable={col?.sortable}
        />
      ))}
    </Grid>
  );
};

export { InlineEditGrid };
