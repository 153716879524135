import { httpService } from "./http";
import { API } from "./utils";

class PrePostingService {
  async getPrepostingData(country, month, year) {
    const URL = API.prePosting + `?countryCode=${country}&month=${month}&year=${year}`;

    try {
      const response = await httpService.get(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async savePrePostDate(content) {
    let URL = `${API.prePosting}`;

    try {
      const response = await httpService.post(URL, content);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async sendEmail() {
    let URL = `${API.email}`;

    try {
      const response = await httpService.post(URL);
      if (response && response.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

const prePostingService = new PrePostingService();

export { prePostingService };
