import { Button } from "@progress/kendo-react-all";
import { calculateWidth } from "../../eod-summary-screen/eod-summary-utils";
import { CustomHeaderCell } from "../../account-for-deliveries/account-for-deliveries-utils";
import { ShipToLinkCell } from "../../common-utils";

export const getActionParties = () => {
  return [
    {
      id: "All",
      text: "All",
    },
    {
      id: "RSAA/WSFP",
      text: "RSAA/WSFP",
    },
    {
      id: "CWR",
      text: "CWR",
    },
    {
      id: "OTD",
      text: "OTD",
    },
    {
      id: "BTC",
      text: "BTC",
    },
    {
      id: "WS",
      text: "WS",
    },
  ];
};

export const getStatusList = () => {
  return [
    {
      id: "All",
      text: "All",
    },
    {
      id: "Open",
      text: "Open",
    },
    {
      id: "Closed",
      text: "Closed",
    },
  ];
};

const ButtonCell = (props) => {
  const { dataItem, handleIssueUpdate } = props;
  return (
    <td className="editableCell">
      <span className="filter-button">
        <Button
          className="get-data-btn"
          onClick={() => {
            handleIssueUpdate(dataItem);
          }}
        >
          Update
        </Button>
      </span>
    </td>
  );
};

export const colsToShow = (
  issues,
  handleIssueUpdate,
  handleShipToLinkAction
) => {
  let columns = [
    {
      title: "Incident Id",
      field: "incidentId",
      filterable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("incidentId", issues) * 0.9,
    },
    {
      title: "Ship To",
      field: "globalSiteId",
      filterable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: calculateWidth("globalSiteId", issues) * 1.2,
    },
    {
      title: "Transaction Date",
      field: "transactionDate",
      filterable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("transactionDate", issues) * 1.2,
    },
    {
      title: "Raised Date",
      field: "raisedDate",
      filterable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("raisedDate", issues) * 1.2,
    },
    {
      title: "Nature of issue",
      field: "natureOfOpenIssue",
      filterable: true,
      headerCell: CustomHeaderCell,
      // width: calculateWidth("natureOfOpenIssue", issues)  * 0.9,
    },
    {
      title: "Status",
      field: "status",
      filterable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("status", issues),
    },
    {
      title: "Description",
      field: "issueDescription",
      filterable: true,
      headerCell: CustomHeaderCell,
      // width: calculateWidth("issueDescription", issues) * 0.6,
    },
    {
      title: "Action Party",
      field: "actionParty",
      filterable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("actionParty", issues) * 1.2,
    },
    {
      title: "Actions",
      field: "",
      filterable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <ButtonCell {...props} handleIssueUpdate={handleIssueUpdate} />;
      },
      width: window.innerWidth * 0.08,
    },
  ];

  return columns;
};
