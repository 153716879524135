import {
  DropDownList,
  DatePicker,
  Label,
  Button,
} from "@progress/kendo-react-all";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { siteService } from '../../../../../services/sites';
import { manualDataService } from '../../../../../services/manual-data';
import { triggerNotification } from "../../../../../state-management/actions";
import { store } from "../../../../../state-management/store";


export const WetstockDataUpload = () => {

  const dispatch = useDispatch();

  const [selection, setSelection] = useState({
    siteId: null,
    date: null,
    formattedDate: null
  });
  const defaultRegion = useSelector((state) => state?.selectedRegion);
  const [currentRegion, setCurrentRegion] = useState(defaultRegion);

  const [gridData, setGridData] = useState([]);
  const [originalGridData, setOriginalGridData] = useState([]);

  const user = useSelector(state => state?.user);

  const [allSiteIds, setAllSiteIds] = useState([]);
  const [allSites, setAllSites] = useState([]);

  const [loading, setLoading] = useState(false);

  const colsToShow = [
    {
      title: "Tank No",
      field: "TankNo",
      editable: false,
    },
    {
      title: "Fuel Grade",
      field: "FuelGrade",
      editable: false,
    },
    {
      title: "Opening Stock",
      field: "OpeningStock",
      editable: false,
    },
    {
      title: "Deliveries",
      field: "Deliveries",
      editable: true,
      editor: 'numeric'
    },
    {
      title: "Others",
      field: "Others",
      editable: true,
      editor: 'numeric'
    },
    {
      title: "Sales",
      field: "Sales",
      editable: true,
      editor: 'numeric'
    },
    {
      title: "Closing Stock",
      field: "ClosingStock",
      editable: true,
      editor: 'numeric'
    },
    {
      title: "Variance",
      field: "Variance",
      editable: false,
    },
  ]

  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setCurrentRegion(selectedRegion);
  });

  const getAllSiteIds = async () => {
    const allSites = await siteService.fetchSiteIdListWithName(currentRegion);
    const temp = [...allSites];
    setAllSiteIds(temp.map(site => site.Id));
    setAllSites(allSites);
  }

  useEffect(() => {
    getAllSiteIds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectionOk = (selection.formattedDate && selection.siteId);

  const DataGridContent = () => {

    const handleDataChange = (e) => {
      const newData = gridData.map((item) => {
        const temp = { ...item }
        if (temp.TankNo === e.dataItem.TankNo) {
          temp[e.field || ""] = e.value;
          temp.Variance =
            temp.ClosingStock - temp.OpeningStock - temp.Deliveries + temp.Sales;
        }
        return temp;
      })
      setGridData(newData);
    }

    const saveChanges = async () => {
      const gridDataForPayload = gridData.map(item => {
        delete item.inEdit;
        return item;
      });
      const payload = {
        "CountryCode": user.Country,
        "SiteId": selection.siteId,
        "Date": selection.date,
        "UserEmail": user.UserEmail,
        "Tanks": gridDataForPayload
      };
      const response = await manualDataService.saveManualDataUploadChanges(payload);
      if (response?.['Status'] === 'Success') {
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: false,
            },
            message: `Changes Saved Successfully!`,
          })
        );
        setGridData(originalGridData)
        return;
      }
    }

    return <>
      <h5 className="manual-data-upload-table-header">
        {
          selectionOk ?
            `Enter Wet Stock Data for Site ID: ${selection.siteId} and Date: ${selection.formattedDate}`
            : 'Please select a Site ID and Date, for which you need to add data manually.'
        }
      </h5>
      {
        loading && <h1>Loading please wait...</h1>
      }
      {
        (selectionOk && !loading && gridData)
        &&
        <AppDataGrid
          onItemChange={handleDataChange}
          data={[...gridData]}
          columnsToShow={colsToShow}
        />
      }
      <div className="manual-data-upload-table-buttons">
        <Button
          disabled={JSON.stringify(gridData) === JSON.stringify(originalGridData)}
          onClick={() => setGridData(originalGridData)}
          className="button manual-data-upload-table-buttons-reset"
        >
          Reset
        </Button>
        <Button
          disabled={JSON.stringify(gridData) === JSON.stringify(originalGridData)}
          onClick={saveChanges}
          className="button manual-data-upload-table-buttons-submit"
        >
          Submit and Exit
        </Button>
      </div>
    </>
  }

  const handleSiteIdSelection = (e) => {
    setSelection(prevSelection => {
      return {
        ...prevSelection,
        siteId: e.value
      }
    })
  }

  const handleDateSelection = (e) => {
    const dateInstance = new Date(e.value);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const month = (dateInstance.getMonth() + 1).toString().padStart(2, "0");
    const date = dateInstance.getDate().toString().padStart(2, "0");
    const year = dateInstance.getFullYear();

    const dateForPayload = `${year}-${month}-${date}`;
    setSelection(prevSelection => {
      return {
        ...prevSelection,
        date: dateForPayload,
        formattedDate: `${date}-${months[dateInstance.getMonth()]}-${year}`
      }
    })
  }

  const getSiteData = async () => {
    setLoading(true);
    const { siteId, date } = selection;
    const payload = { "CountryCode": user.Country, "SiteId": siteId, "Date": date };
    const response = await manualDataService.fetchStockData(payload);
    if (response?.['Error']) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: false,
          },
          message: `${response?.['Error']}`,
        })
      );
      setLoading(false);
      return;
    }
    if (response?.['API Response']) {
      dispatch(
        triggerNotification({
          type: {
            style: "info",
            icon: false,
          },
          message: `${response?.['API Response']}`,
        })
      );
      setLoading(false);
      return;
    }
    const formattedData = response?.TankList?.map((item, index) => {
      item['Deliveries'] = 0;
      item['Others'] = 0;
      item['Sales'] = 0;
      item['ClosingStock'] = 0;
      item['Variance'] = 0;
      item['inEdit'] = true;
      return item;
    });
    setGridData(formattedData?.slice());
    setOriginalGridData(formattedData?.slice());
    setLoading(false);
  }

  return (
    <div className="container user-management-screen-container">
      <div className="row content-container">
        <div className="container data-grid">
          <div className="row ">
            <h4 className="title">Manual Data Upload</h4>
            <div className="data-grid-container manual-data-upload">
              <div className="col-xs-12 manual-data-upload-filters">
                <div className="col-xs-3 filter">
                  <Label>Site ID (Mandatory)</Label>
                  <DropDownList
                    data={allSiteIds}
                    onChange={handleSiteIdSelection}
                  />
                </div>
                <div className="col-xs-3 filter">
                  <Label>Date (Mandatory)</Label>
                  <DatePicker
                    onChange={handleDateSelection}
                  />
                </div>
                <div className="col-xs-3 filter">
                  <Label>Site Name</Label>
                  <p className="site-name">
                    {
                      allSites.filter(site => site.Id === selection.siteId)[0]?.['Name'] || 'Please select a site id'
                    }
                  </p>
                </div>
                <div className="col-xs-3 filter">
                  <Button disabled={!selectionOk || loading}
                    onClick={getSiteData}
                    className="button manual-data-upload-table-buttons-fetch-site-data">
                    {loading ? 'Loading...' : `Fetch site data`}
                  </Button>
                </div>
              </div>
              <div className="col-xs-12 manual-data-upload-table">
                <DataGridContent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
