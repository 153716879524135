/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, DatePicker, Loader } from "@progress/kendo-react-all";
import { TextArea } from "@progress/kendo-react-inputs";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { triggerNotification } from "../../../../../state-management/actions";
import { store } from "../../../../../state-management/store";
import { BarChart } from "../../../../components/bar-chart/bar-chart";
import { ContentWindow } from "../../../../components/content-window/content-window";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { getDaysBeforeDate, readOnlyUser } from "../../../../../services/utils";
import { AccountForDeliveriesContainer } from "../account-for-deliveries/account-for-deliveries-container";
import { OutOfToleranceTrendAnalysisContainerV2 } from "../out-of-tolerance-trend-analysis/out-of-tolerance-trend-analysis-container-v2";
import { SCREEN_IDS } from '../../reportUtils';

const getFormattedDate = (d) => {
  const date = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();
  return `${year}-${month}-${date}`;
};

const CommentViewContainer = (props) => {
  const [oldComments, setOldComments] = useState([]);
  const [isOutOfTolerance, setIsOutOfTolerance] = useState(false);
  const [windowVisibility, setWindowVisibility] = useState(false);
  const [commentsHistory, setCommentsHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const region = useSelector((state) => state?.selectedRegion);

  useEffect(() => {
    setIsOutOfTolerance(
      props?.report?.toUpperCase() === "OUT OF TOLERANCE TREND ANALYSIS"
    );
    setOldComments([]);
    fetchOldComments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.report]);

  async function fetchOldComments() {
    setLoading(true);
    const response = await reportsService.fetchPreviousComments(
      region,
      props?.report,
      startDate,
      endDate
    );
    const sanitized =
      response &&
      response.map((item, index) => {
        return { ...item, id: index };
      });
    setOldComments(sanitized);
    setLoading(false);
    return () => setOldComments([]);
  }

  const prepareAndOpenContentWindow = (recordId) => {
    const specificCommenthistory = oldComments.filter(
      (item) => item?.id === recordId
    );
    const commentsHistoryArr = [];
    specificCommenthistory?.forEach((comment) => {
      commentsHistoryArr.push({
        Comment: comment?.Comment,
        UserEmail: comment?.CommentBy,
        CommentDate: comment?.CommentedOn,
      });
      comment?.CommentHistoryList?.forEach((history) => {
        commentsHistoryArr.push({
          Comment: history?.Comment,
          UserEmail: history?.CommentBy,
          CommentDate: history?.CommentedOn,
        });
      });
    });

    if (specificCommenthistory && commentsHistoryArr.length > 0) {
      setCommentsHistory(commentsHistoryArr);
      setWindowVisibility(true);
    } else {
      setCommentsHistory([]);
      setWindowVisibility(false);
    }
  };

  const handleDateChange = (e) => {
    const name = e?.target?.name;
    const value = getFormattedDate(new Date(e?.target?.value));
    if (name === "start") {
      setStartDate(value);
    } else if (name === "end") {
      setEndDate(value);
    }
  };

  const inlineStyles =
    "col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 comments-form-container";
  const overlayStyles = isOutOfTolerance
    ? "overlay-comments-form-container"
    : "";
  const combinedClassName = `${inlineStyles} ${overlayStyles}`;

  return (
    <div className={combinedClassName}>
      <div className="row">
        <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 title">
          <b>Showing previously added comments</b>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 close-btn-container">
          <Button className="close-btn" onClick={props.onViewClose}>
            Close
          </Button>
        </div>
      </div>
      <p>
        Please select a range to view comments within a specific date range.
      </p>
      <p>
        The dates represent the delivery date( for accounting for deliveries )
        or record date ( for out of tolerance trend report)
      </p>
      <DatePicker
        className="date-picker"
        name="start"
        onChange={handleDateChange}
        format="dd-MMMM-yyyy"
      />
      <DatePicker
        className="date-picker"
        name="end"
        onChange={handleDateChange}
        format="dd-MMMM-yyyy"
      />
      <Button onClick={fetchOldComments}>Get Comments</Button>
      {loading ? (
        <div className="loading-container">
          <Loader size="medium" />
          <h6>Loading please wait...</h6>
        </div>
      ) : oldComments && oldComments?.length === 0 ? (
        <div className="no-comments">
          <h6>No comments to display</h6>
          <p>Please select a date range</p>
        </div>
      ) : (
        <table className="previous-comments-table">
          <thead>
            <tr className="table-row">
              <td>
                <b>ShipTo</b>
              </td>
              <td>
                <b>
                  {props?.report === "ACCOUNTING FOR DELIVERIES"
                    ? "Calculated Delivery"
                    : "OBN"}
                </b>
              </td>
              <td>
                <b>Tank</b>
              </td>
              <td>
                <b>
                  {props?.report === "ACCOUNTING FOR DELIVERIES"
                    ? "Difference"
                    : "Material No."}
                </b>
              </td>
              <td>
                <b>Comment Date</b>
              </td>
              <td>
                <b>
                  {props?.report === "ACCOUNTING FOR DELIVERIES"
                    ? "Confirmed Delivery"
                    : "Variance"}
                </b>
              </td>
              <td>
                <b>Comment</b>
              </td>
              <td>
                <b>History</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {oldComments &&
              oldComments.length > 0 &&
              oldComments.map((comment, index) => {
                return (
                  <tr className="table-row" key={index}>
                    <td>{comment["ShipToId"]}</td>
                    <td>
                      {props?.report === "ACCOUNTING FOR DELIVERIES"
                        ? comment["CalculatedDelivery"] || 0
                        : comment["OBN"]}
                    </td>
                    <td>{comment["Tank"]}</td>
                    <td>
                      {props?.report === "ACCOUNTING FOR DELIVERIES"
                        ? comment["ConfirmedDelivery"] -
                          comment["CalculatedDelivery"]
                        : comment["MaterialNo"]}
                    </td>
                    <td>{comment["CommentedOn"]}</td>
                    <td>
                      {props?.report === "ACCOUNTING FOR DELIVERIES"
                        ? comment["ConfirmedDelivery"] || 0
                        : comment["Variance"]}
                    </td>
                    <td>
                      {comment["Comment"].split("$")[
                        comment["Comment"].split("$").length - 1
                      ] || ""}
                    </td>
                    <td>
                      {
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            prepareAndOpenContentWindow(comment?.id)
                          }
                        >
                          View
                        </a>
                      }
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      <ContentWindow
        title="Comments History"
        visible={windowVisibility}
        onClose={() => setWindowVisibility(false)}
      >
        <AppDataGrid
          take={4}
          pageable={true}
          filterable={false}
          expandable={false}
          sortable={false}
          data={commentsHistory}
          columnsToShow={[
            {
              title: "Comments",
              field: "Comment",
              filterable: false,
            },
            {
              title: "User Email",
              field: "UserEmail",
              filterable: false,
            },
            {
              title: "Comment Date",
              field: "CommentDate",
              filterable: true,
            },
          ]}
        />
      </ContentWindow>
    </div>
  );
};

const AddCommentsForm = (props) => {
  const user = useSelector((state) => state?.user);
  const region = useSelector((state) => state?.selectedRegion);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disability, setDisability] = useState({
    TankId: false,
    MaterialNumer: false,
  });

  const [isOutOfTolerance, setIsOutOfTolerance] = useState(false);
  const reportDate = getFormattedDate(props?.reportDate);
  const [payload, setPayload] = useState({
    CountryCode: region,
    UserEmail: user?.UserEmail,
    Comment: "",
    SiteId: "",
    RequestDate: isOutOfTolerance ? reportDate : "",
    TankNo: "",
    MaterialNumer: "",
    ShipmentId: "",
  });
  const [showOtherCommentTextbox, setShowOtherCommentTextbox] = useState(false);

  useEffect(() => {
    setIsOutOfTolerance(
      props?.report?.toUpperCase() === "OUT OF TOLERANCE TREND ANALYSIS"
    );
    setFormData([]);
    async function fetchFormContent() {
      let form = await reportsService.fetchCommentsForm(region, props?.report);
      setFormData(form);
      return () => setFormData([]);
    }
    fetchFormContent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.report]);

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        RequestDate: isOutOfTolerance ? reportDate : "",
      };
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutOfTolerance]);

  const renderForm = (field) => {
    const fieldName = Object.keys(field)[0];

    const type = Object.values(field[fieldName][0])[0];
    const displayName = Object.values(field[fieldName][1])[0];
    const source = Object.values(field[fieldName][2])[0];

    const handleDropdownChange = (e) => {
      const value = e.value?.text || "";
      const name = e.target.name;
      switch (name) {
        case "ShipToId":
          setPayload((prev) => {
            return { ...prev, SiteId: value };
          });
          return;

        case "TankId":
          setPayload((prev) => {
            return { ...prev, TankNo: value };
          });
          setDisability((prev) => {
            return { ...prev, MaterialNumer: true, TankId: false };
          });
          return;

        case "MaterialNumer":
          setPayload((prev) => {
            return { ...prev, MaterialNumer: value };
          });
          setDisability((prev) => {
            return { ...prev, TankId: true, MaterialNumer: false };
          });
          return;
        case "ShipmentId":
          setPayload((prev) => {
            return { ...prev, ShipmentId: value };
          });
          return;

        case "Comments":
          if (value === "Others") {
            setShowOtherCommentTextbox(true);
            setPayload((prev) => {
              return { ...prev, Comment: "" };
            });
          } else {
            setShowOtherCommentTextbox(false);
            setPayload((prev) => {
              return { ...prev, Comment: value };
            });
          }
          return;

        default:
          break;
      }
    };

    const handleDateChange = (e) => {
      const dateInstance = new Date(e.value);
      const date = dateInstance.getDate().toString().padStart(2, "0");
      const month = (dateInstance.getMonth() + 1).toString().padStart(2, "0");
      const year = dateInstance.getFullYear();
      const sanitizedDate = `${year}-${month}-${date}`;
      setPayload((prev) => {
        return { ...prev, RequestDate: sanitizedDate };
      });
    };

    const checkValue = (fieldname) => {
      if (fieldname === "TankId" && payload["TankNo"])
        return { text: payload["TankNo"] };
      if (fieldname === "MaterialNumer" && payload["MaterialNumer"])
        return { text: payload["MaterialNumer"] };
      if (fieldname === "ShipToId" && payload["SiteId"])
        return { text: payload["SiteId"] };
      if (fieldname === "ShipmentId" && payload["ShipmentId"])
        return { text: payload["ShipmentId"] };
      if (
        fieldname === "Comments" &&
        payload["Comment"] &&
        !showOtherCommentTextbox
      )
        return { text: payload["Comment"] };
      return { text: "" };
    };

    switch (type) {
      case "DROP_DOWN":
        return (
          <div
            className="field"
            style={{ color: disability[fieldName] && "grey" }}
          >
            <p htmlFor="Drowdown">{displayName?.toUpperCase()}</p>
            <SearchableDropdown
              onSelect={handleDropdownChange}
              name={fieldName}
              disabled={disability[fieldName]}
              data={source}
              filterable
              value={checkValue(fieldName)}
            />
            {fieldName === "TankId" && disability["TankId"] && (
              <p>
                All Tanks for this Ship To number and this Material will be
                updated with your comment by the system. Click Reset if you want
                to select Tank instead of Material
              </p>
            )}
            {fieldName === "MaterialNumer" && disability["MaterialNumer"] && (
              <p>
                Material number will be automatically updated by the system
                based on Ship To and Tank. Click Reset if you want to select
                Material instead of Tank
              </p>
            )}
          </div>
        );

      case "DATE":
        return (
          !isOutOfTolerance && (
            <div className="field">
              <p
                htmlFor="Date"
                title="This date should match with the date in the record on the left hand side for which you are entering the comment."
              >
                DELIVERY DATE<span class="k-icon k-i-info"></span>
              </p>
              <DatePicker
                format={"dd-MMM-yyyy"}
                onChange={handleDateChange}
                placeholder={"Please select a date"}
              />
            </div>
          )
        );

      default:
        break;
    }
    return null;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await reportsService.saveReportComment(
      payload,
      props?.report
    );
    if (response["Error"]) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: false,
          },
          message: response["Error"],
        })
      );
    }
    if (response?.Status === "Success") {
      store.dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: false,
          },
          message: "Record Updated Successfully",
        })
      );
    }
    setLoading(false);
  };

  const handleOtherComment = (e) => {
    const value = e.value;
    setPayload((prev) => {
      return {
        ...prev,
        Comment: `$Others$${value}`,
      };
    });
  };

  const handleReset = () => {
    setLoading(false);
    setDisability((prev) => {
      return { ...prev, MaterialNumer: false, TankId: false };
    });
    setPayload((prev) => {
      return {
        ...prev,
        Comment: "",
        SiteId: "",
        RequestDate: isOutOfTolerance ? payload?.RequestDate : "",
        TankNo: "",
        MaterialNumer: "",
        ShipmentId: "",
      };
    });
    setShowOtherCommentTextbox(false);
  };

  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 comments-form-container">
      <div className="row">
        <div className="col-md-9 title">
          <b>Add new comment</b>
          <p>Please use the form below to add a new comment.</p>
        </div>
        <div className="col-md-3 close-btn-container">
          <Button className="close-btn" onClick={props.onViewClose}>
            Close
          </Button>
        </div>
      </div>
      <hr />
      {formData && formData.length > 0 ? (
        formData[0]?.Fields?.map((field) => renderForm(field))
      ) : (
        <p>Loading the form please wait...</p>
      )}
      {formData && showOtherCommentTextbox && (
        <>
          <p>Other Comment</p>
          <TextArea maxLength={500} onChange={handleOtherComment} />
        </>
      )}
      <div className="submit-btn-container">
        {!loading ? (
          <Button className="submit-btn" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <p>Updating please wait...</p>
        )}
        &nbsp;
        <Button className="submit-btn" onClick={handleReset}>
          Reset
        </Button>
      </div>
    </div>
  );
};

const ChartsContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [chart, setChart] = useState({ xAxis: [], yAxis: [] });
  const country = useSelector((state) => state?.user?.Country);
  const [chartPayload, setChartPayload] = useState({
    CountryCode: country,
    FromDate: "",
    ToDate: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const dateInstance = new Date(value);
    const date = dateInstance.getDate().toString().padStart(2, "0");
    const month = (dateInstance.getMonth() + 1).toString().padStart(2, "0");
    const year = dateInstance.getFullYear();
    const sanitizedDate = `${year}-${month}-${date}`;

    setChartPayload((prev) => {
      return { ...prev, [name]: sanitizedDate };
    });
  };

  const handleGenerateChart = async () => {
    setLoading(true);

    let response = null;
    if (props?.report === "ACCOUNTING FOR DELIVERIES") {
      response = await reportsService.getChartData(chartPayload, props?.report);
    } else {
      response = await reportsService.getOOTAChartData(
        chartPayload?.CountryCode,
        chartPayload?.FromDate,
        chartPayload?.ToDate
      );
    }

    if (response) {
      const xAxisData = Object.keys(response);
      const yAxisData = Object.values(response);
      setChart((prev) => {
        return { ...prev, xAxis: xAxisData, yAxis: yAxisData };
      });
    }

    setLoading(false);
  };

  const [isOutOfTolerance, setIsOutOfTolerance] = useState(false);
  useEffect(() => {
    setIsOutOfTolerance(
      props?.report?.toUpperCase() === "OUT OF TOLERANCE TREND ANALYSIS"
    );
  }, [props?.report]);

  const title = isOutOfTolerance
    ? "Out of tolerance trend analysis"
    : "Accounting for Deliveries";

  return (
    <div className="charts-parent-container">
      <div className="row">
        <div className="col-md-9 charts-container">
          <BarChart
            className="chart"
            xAxisData={chart.xAxis}
            yAxisData={chart.yAxis}
            title={title}
          />
        </div>
        <div className="col-md-3 filter-container">
          <b>Please select a date range for which you wish to see the graph.</b>
          <p>Start Date</p>
          <DatePicker onChange={handleChange} name="FromDate" />
          <p>End Date</p>
          <DatePicker onChange={handleChange} name="ToDate" />
          <hr />
          <div className="footer-btn">
            {!loading ? (
              <Button
                onClick={handleGenerateChart}
                className="generate-chart-btn"
              >
                Generate Chart
              </Button>
            ) : (
              <p>Generating chart, please wait...</p>
            )}
            <Button onClick={props.onClose} className="close-btn">
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommentableReportsContainer = ({ powerBi, report, screenId }) => {
  const hiddenFileInput = useRef();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [showCommentsForm, setShowCommentsForm] = useState(false);
  const [showPrevComments, setShowPrevComments] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [historicalViewEnabled, setHistoricalViewEnabled] = useState(false);
  const isOutOfTolerance = screenId === SCREEN_IDS.OUT_OF_TOLERANCE_TREND_ANALYSIS;
  const isOutOfToleranceV2 = screenId === SCREEN_IDS.OUT_OF_TOLERANCE_TREND_ANALYSIS;

  const isNewAFD = screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES_NEW;

  const region = useSelector((state) => state?.selectedRegion);

  const [currentRegion, setCurrentRegion] = useState(region);

  const reportMaxDate =
    region === "DE" ? getDaysBeforeDate(2) : getDaysBeforeDate(1);
  const [reportDate, setReportDate] = useState(reportMaxDate);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);

  useEffect(() => {
    setReportDate(reportMaxDate);
    setShowCommentsForm(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setCurrentRegion(selectedRegion);
  });

  const historyViewAction = (enabled) => {
    console.log("enabled", enabled);
    setHistoricalViewEnabled(enabled);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadFile = async (data) => {
    setUploading(true);
    try {
      const date = getFormattedDate(reportDate);
      const headers = {
        RequestDate: date,
        Market: currentRegion,
      };
      const response = await reportsService.uploadFile(data, headers);
      setUploading(false);
      if (response.status >= 200 && response.status < 300) {
        const message = response.hasOwnProperty("data")
          ? response.data
          : "File uploaded successfully";
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: message,
          })
        );
      } else {
        const message = response.response.hasOwnProperty("data")
          ? response?.response?.data?.errors?.["Data Error"]?.[0]
          : "Unable to upload the file";
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: message,
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to upload the file",
        })
      );
      setUploading(false);
    }
  };

  const renderCommentsFormContainer = (reportDate) => {
    if (showPrevComments)
      return isOutOfTolerance ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <CommentViewContainer
            report={report}
            onViewClose={() => setShowPrevComments(false)}
          />
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: "10%",
            left: "42%",
            width: "80%",
            height: "85%",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommentViewContainer
            report={report}
            onViewClose={() => setShowPrevComments(false)}
          />
        </div>
      );

    if (showCommentsForm)
      return (
        <AddCommentsForm
          report={report}
          onViewClose={() => setShowCommentsForm(false)}
          reportDate={reportDate}
        />
      );
  };

  const handleReportDateChange = (e) => {
    setReportDate(new Date(e?.target?.value));
  };

  const headerSection = () => {
    return readOnly ? (
      <></>
    ) : (
      <div className="actions-header">
        {/* <h4 className="title">{report}</h4> */}
        <div className="header-button-container">
          {!(isOutOfTolerance || isNewAFD) && (
            <Button
              onClick={() => {
                setShowCommentsForm(true);
                setShowPrevComments(false);
              }}
            >
              <p>{"Add Comment"}</p>
            </Button>
          )}

          {!(isNewAFD || historicalViewEnabled) && (
            <>
              <Button
                onClick={() => {
                  setShowCommentsForm(false);
                  setShowPrevComments(true);
                }}
              >
                <p>{"Show previous comments"}</p>
              </Button>
              <Button
                onClick={() => {
                  setShowCommentsForm(false);
                  setShowPrevComments(false);
                  setShowChart(true);
                }}
              >
                <p>View Chart</p>
              </Button>
            </>
          )}
          {isOutOfTolerance && !historicalViewEnabled && (
            <span className="toggle-container">
              <span className="export-container">
                <DatePicker
                  format={"dd-MMM-yyyy"}
                  value={reportDate}
                  onChange={handleReportDateChange}
                  max={reportMaxDate}
                  min={getDaysBeforeDate(16)}
                />
                <Button className="button-upload" onClick={handleClick}>
                  {uploading ? "Uploading..." : "Upload a file"}
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    disabled={uploading}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e?.currentTarget?.files?.[0];
                      const formData = new FormData();
                      formData.append("Report Data", file);
                      uploadFile(formData);
                      hiddenFileInput.current.value = null;
                    }}
                  />
                </Button>
              </span>
            </span>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="commentable-report-container">
      {!showChart && headerSection()}
      {showChart ? (
        <ChartsContainer report={report} onClose={() => setShowChart(false)} />
      ) : (
        <div className="power-bi-report">
          {isOutOfTolerance ? (
            <OutOfToleranceTrendAnalysisContainerV2
              historyViewAction={historyViewAction}
            />
          ) : isOutOfToleranceV2 ? (
            <OutOfToleranceTrendAnalysisContainerV2
              historyViewAction={historyViewAction}
            />
          ) : isNewAFD ? (
            <AccountForDeliveriesContainer />
          ) : (
            <div
              className={
                showCommentsForm || showPrevComments
                  ? "col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 report-container"
                  : "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 report-container"
              }
            >
              {powerBi}
            </div>
          )}

          {renderCommentsFormContainer(reportDate)}
        </div>
      )}
    </div>
  );
};

export { CommentableReportsContainer };
