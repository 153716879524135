import React from "react";
import { MessageTrackerDeliveryScreen } from "./message-tracker-delivery/message-tracker-delivery-screen";

const MessageTrackerDeliveryContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <MessageTrackerDeliveryScreen />
        </div>
      </div>
    </>
  );
};

export { MessageTrackerDeliveryContainer};
