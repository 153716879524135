import React from "react";
import { TankLeaksScreen } from "./tank-leaks-screen/tank-leaks-screen";

const TankLeaksContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container">
        <div className="row content-container">
          <TankLeaksScreen />
        </div>
      </div>
    </>
  );
};

export { TankLeaksContainer };
