import "./pump-index-report.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { store } from "../../../../../state-management/store";
import {
  PumpIndexViewOptions,
  colsToShow,
  getDeliveryDate,
  getDeliveryIds,
  getDeliveryQty,
  getFooterItem,
} from "./pump-index-utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Button, DropDownList, ButtonGroup } from "@progress/kendo-react-all";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  getMonths,
  getProducts,
  getShipTos,
  getSiteNames,
  getTankGroupList,
  getThresholdYears,
} from "../../reportUtils";

import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import AlertPopup from "../../../../components/alert-popup/alert-popup";
import { getFormattedDate } from "../../../../../services/utils";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { useNavigate } from "react-router-dom";
import { Switch } from "@progress/kendo-react-inputs";

const PumpIndexScreen = () => {
  const dispatch = useDispatch();
  const [pumpIndexReportData, setPumpIndexReportData] = useState([]);
  const defaultRegion = useSelector((state) => state?.selectedRegion);
  const [currentRegion, setCurrentRegion] = useState(defaultRegion);
  const [exporting, setExporting] = useState(false);
  const pdfExportComponent = useRef(null);
  const [translatedContent, setTranslatedContent] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [selectedMode, setSelectedMode] = useState(
    PumpIndexViewOptions.MATERIAL
  );
  const [cursorPosition, setCursorPosition] = useState(null);
  const exportGridRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resetIndex, setResetIndex] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [changed, setChanged] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [masterData, setMasterData] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const divRef = useRef(null);
  const gridRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [salesDifferenceToggleOn, setSalesDifferenceToggleOn] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState({
    selectedSiteId: null,
    selectedRegions: [
      {
        id: "BE",
        text: "BE",
      },
    ],
    selectedYear: getThresholdYears(1)?.[0],
    years: getThresholdYears(10),
    selectedMonth: {
      id: new Date().getMonth() + 1,
      text: new Date().toLocaleString("en-US", { month: "short" }),
    },
    months: getMonths(),
    selectedSiteName: null,
    selectedProduct: null,
    products: [],
    selectedTankGroup: [],
    tankGroup: [],
    allSiteIds: [],
    allSiteNames: [],
  });
  const inputRef = useRef(null);
  const languageOptions = [
    { id: "dutch", text: "Dutch" },
    { id: "french", text: "French" },
  ];

  const [pdfLanguage, setPdfLanguage] = useState(languageOptions?.[0]);
  const navigate = useNavigate();

  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    if (selectedRegion !== "BENEFRUX") {
      navigate("/home");
    }
    setCurrentRegion(selectedRegion);
  });

  useEffect(() => {
    // when the component gets mounted
    if (divRef.current.offsetWidth) {
      setWidth(divRef.current.offsetWidth);
    }

    // to handle page resize
    const getwidth = () => {
      setWidth(divRef.current.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getwidth);
  }, [divRef]);

  useEffect(() => {
    setFirstLoad(true);
    fetchMasterData();
    setChanged(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  useEffect(() => {
    setChanged(false);
    setChangedItems([]);
    const updatedItems = originalData?.filter((item) => {
      if (salesDifferenceToggleOn) {
        return item?.salesDifference !== 0;
      }
      return true;
    });
    setPumpIndexReportData(updatedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDifferenceToggleOn]);

  useEffect(() => {
    cancelChanges();
    fetchPumpIndexData();
    setChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMode]);

  useEffect(() => {
    const content = require("./translation.json");
    setTranslatedContent(content?.[pdfLanguage.id]);
  }, [pdfLanguage]);

  const getTranslatedContent = (keyItem) => {
    const key = keyItem.trim();
    return translatedContent?.[key] ? translatedContent?.[key] : key;
  };

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pumpIndexReportData]);

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };

  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const exportPDFData = () => {
    setExporting(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    // let element = pdfExportComponent.current;
    // if (element !== null) {
    //   savePDF(element, {
    //     repeatHeaders: true,
    //     paperSize: "A3",
    //     margin: "2cm",
    //   });
    // }
    setExporting(false);
  };

  const createPayload = () => {
    const Comments = changedItems?.map((item) => ({
      MaterialRowKey: item.materialRowKey,
      Text: item.comments,
    }));
    const payload = {
      Year: filters.selectedYear?.text,
      Month: filters.selectedMonth?.id,
      GlobalSiteId: filters?.selectedSiteId?.text,
      MaterialCode: filters?.selectedProduct?.id,
      Comments,
    };

    return payload;
  };

  const onYearSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedYear: event,
      };
    });
  };

  const onMonthSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedMonth: event,
      };
    });
  };

  const onShipToSelection = (value) => {
    const siteId = value?.split(" | ")?.[0];
    if (siteId?.length) {
      const selectedSiteId = filters?.allSiteIds?.find(
        (item) => item.text === siteId
      );
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: selectedSiteId,
        };
      });
    }
  };

  const onSiteNameSelection = (value) => {
    const selectedSiteName = filters?.allSiteNames?.find(
      (item) => item.text === value
    );
    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteName: selectedSiteName,
      };
    });
  };

  const onProductSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedProduct: event,
      };
    });
  };
  const onTankSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTankGroup: event,
      };
    });
  };

  useEffect(() => {
    const siteIds =
      filters.selectedRegions?.length > 0
        ? getShipTos(masterData, filters.selectedRegions, false)
        : [];

    const siteNames =
      filters.selectedRegions?.length > 0
        ? getSiteNames(masterData, filters.selectedRegions, false)
        : [];

    setFilters((prev) => {
      return {
        ...prev,
        selectedSiteId: siteIds?.[0],
        selectedSiteName: siteNames?.[0],
        allSiteIds: siteIds,
        allSiteNames: siteNames,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  const getMonthsData = (selectedYear) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const months = filters?.months?.map((month) => {
      return selectedYear === currentYear
        ? {
            ...month,
            disabled: month.id > currentMonth + 1,
          }
        : {
            ...month,
            disabled: false,
          };
    });

    return months;
  };
  useEffect(() => {
    const year = parseInt(filters?.selectedYear?.text);
    const months = getMonthsData(year);
    const currentYear = new Date().getFullYear();

    let selectedMonth = filters.selectedMonth;
    const currentMonth = new Date().getMonth();
    if (year === currentYear && selectedMonth.id > currentMonth + 1) {
      selectedMonth = {
        id: new Date().getMonth() + 1,
        text: new Date().toLocaleString("en-US", { month: "short" }),
      };
    }

    setFilters((prev) => {
      return {
        ...prev,
        months: months,
        selectedMonth: selectedMonth,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedYear]);

  useEffect(() => {
    let selectedSiteName = null;
    if (filters?.selectedSiteId) {
      const site = filters?.allSiteNames?.find(
        (site) => site?.globalSiteId === filters?.selectedSiteId?.id
      );
      if (site) {
        selectedSiteName = site;
      }
    }

    const productsData = getProducts(masterData, filters, false);
    const selectedProduct = productsData?.[0];

    setFilters((prev) => {
      return {
        ...prev,
        products: productsData,
        selectedProduct: selectedProduct,
        selectedSiteName: selectedSiteName,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteId]);

  useEffect(() => {
    let selectedSiteId = null;
    if (filters?.selectedSiteName) {
      const site = filters?.allSiteIds?.find(
        (site) => site?.globalSiteId === filters?.selectedSiteName?.globalSiteId
      );
      if (site) {
        selectedSiteId = site;
      }
    }

    const productsData = getProducts(masterData, filters, false);
    const selectedProduct = productsData?.[0];

    setFilters((prev) => {
      return {
        ...prev,
        products: productsData,
        selectedProduct: selectedProduct,
        selectedSiteId: selectedSiteId,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteName]);

  useEffect(() => {
    const tankGroupsData = getTankGroupList(masterData, filters, false);
    const selectedTanks = tankGroupsData?.[0];
    setFilters((prev) => {
      return {
        ...prev,
        tankGroup: tankGroupsData,
        selectedTankGroup: selectedTanks,
      };
    });

    if (filters.selectedProduct && firstLoad) {
      fetchPumpIndexData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedProduct]);

  const fetchMasterData = async () => {
    try {
      setPumpIndexReportData([]);
      setFilterDataLoading(true);

      const payload = {
        country: currentRegion,
      };
      const response = await reportsService.fetchRegionMasterData(payload);
      setFilterDataLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the data.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
      setError("Server error. Unable to fetch data.");
      setFilterDataLoading(false);
      setError("");
    }
  };

  const handleDataChange = (props) => {
    const { dataItem, field, position } = props;
    setSelectedItem({ ...dataItem, editField: field });

    let changeItem = props?.dataItem;
    const updatedItems = pumpIndexReportData?.map((item) => {
      if (
        item.dateOfPumpIndex === dataItem.dateOfPumpIndex &&
        item.dateOfFuel === dataItem.dateOfFuel &&
        item.outBound === dataItem.outBound
      ) {
        return dataItem;
      }
      return item;
    });
    setPumpIndexReportData(updatedItems);

    const actualItem = originalData?.find(
      (item) =>
        item.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
        item.dateOfFuel === changeItem.dateOfFuel &&
        item.outBound === changeItem.outBound
    );
    const isChanged = actualItem.comments !== changeItem.comments;

    setChanged(isChanged);
    if (isChanged) {
      const sameItem = changedItems?.find(
        (item) =>
          item.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
          item.dateOfFuel === changeItem.dateOfFuel &&
          item.outBound === changeItem.outBound
      );
      if (sameItem) {
        const items = changedItems?.map((item) => {
          if (
            item.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
            item.dateOfFuel === changeItem.dateOfFuel &&
            item.outBound === changeItem.outBound
          ) {
            return changeItem;
          }
          return item;
        });
        setChangedItems(items);
      } else {
        setChangedItems([...changedItems, changeItem]);
      }
    } else {
      setChangedItems(
        changedItems?.filter(
          !(
            actualItem.dateOfPumpIndex === changeItem.dateOfPumpIndex &&
            actualItem.dateOfFuel === changeItem.dateOfFuel &&
            actualItem.outBound === changeItem.outBound
          )
        )
      );
    }
    setCursorPosition(position);
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.savePumpIndexValues(payload);
    if (response?.status >= 200 && response?.status < 300) {
      setChanged(false);
      setChangedItems([]);
      fetchPumpIndexData();
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to submit the comments.",
        })
      );
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setPumpIndexReportData(originalData);
    setChanged(false);
    setChangedItems([]);
  };

  const showHeaders = () => {
    const siteIds = filters.allSiteIds?.map(
      (site) => `${site?.globalSiteId} | ${site?.localSiteId}`
    );
    const siteNames = filters.allSiteNames?.map((site) => site?.siteName);
    return (
      <div className="header-container">
        <div className="filters-container">
          <span className="filter">
            <label htmlFor="year">Year</label>
            <DropDownList
              data={filters.years}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                onYearSelection(event.target.value);
              }}
              value={filters.selectedYear}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="month">Month</label>
            <DropDownList
              data={filters.months}
              textField="text"
              filterable={true}
              dataItemKey="id"
              onChange={(event) => {
                onMonthSelection(event.target.value);
              }}
              value={filters.selectedMonth}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">Site ID</label>
            <SearchableDropdown
              data={siteIds}
              filterable={true}
              textField="text"
              dataItemKey="id"
              onSelect={(event) => {
                onShipToSelection(event.value.text);
              }}
              value={{
                text: filters?.selectedSiteId
                  ? `${filters?.selectedSiteId?.text} | ${filters?.selectedSiteId?.localSiteId}`
                  : "",
              }}
              style={{
                minWidth: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">Site Name</label>
            <SearchableDropdown
              data={siteNames}
              textField="text"
              dataItemKey="id"
              filterable={true}
              onSelect={(event) => {
                onSiteNameSelection(event.value.text);
              }}
              value={{ text: filters?.selectedSiteName?.text }}
              style={{
                minWidth: "8vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="region">Material</label>
            <DropDownList
              data={filters.products}
              textField="idText"
              dataItemKey="id"
              onChange={(event) => {
                onProductSelection(event.target.value);
              }}
              value={filters.selectedProduct}
              style={{
                minWidth: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          {selectedMode === PumpIndexViewOptions.TANK && (
            <span className="filter">
              <label htmlFor="region">Tank Group</label>
              <DropDownList
                data={filters.tankGroup}
                textField="text"
                dataItemKey="id"
                onChange={(event) => {
                  onTankSelection(event.target.value);
                }}
                value={filters.selectedTankGroup}
                style={{
                  minWidth: "7vw",
                  marginRight: "1vw",
                  fontSize: "10px",
                }}
              />
            </span>
          )}
        </div>
        <span className="filter-button">
          <Button
            className="get-data-btn"
            onClick={() => {
              setSalesDifferenceToggleOn(false);
              fetchPumpIndexData();
              setResetIndex(!resetIndex);
            }}
            disabled={filterDataLoading}
          >
            {filterDataLoading ? "Fetching..." : "Get data"}
          </Button>
        </span>
        <span className="filter-button">
          <Button
            className="refresh-btn"
            onClick={() => {
              fetchPumpIndexData(true);
            }}
            disabled={!appliedFilters}
          >
            Refresh
          </Button>
        </span>
      </div>
    );
  };

  const headerButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = changed && "#fbce07";
    const borderWidth = changed && "0.15vw";
    return (
      <div className="buttonsContainer">
        {selectedMode === PumpIndexViewOptions.TANK && (
          <div className="languageContainer">
            <DropDownList
              data={languageOptions}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                setPdfLanguage(event.target.value);
              }}
              value={pdfLanguage}
              style={{
                width: "7vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
              disabled={exporting || pumpIndexReportData?.length === 0}
            />
            <Button
              className="exportButton"
              onClick={exportPDFData}
              disabled={exporting || pumpIndexReportData?.length === 0}
            >
              {exporting ? "Exporting..." : "Export to PDF"}
            </Button>
          </div>
        )}
        {selectedMode === PumpIndexViewOptions.MATERIAL && (
          <div className="submitButtonContainer">
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={exporting || !pumpIndexReportData?.length}
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
          </div>
        )}
        {selectedMode === PumpIndexViewOptions.MATERIAL && (
          <div className="submitButtonContainer">
            <div className="switchContainer">
              <span className="switch-label">Show only sales differences</span>
              <Switch
                onLabel={""}
                offLabel={""}
                size="small"
                checked={salesDifferenceToggleOn}
                onChange={() => {
                  setSalesDifferenceToggleOn(!salesDifferenceToggleOn);
                }}
              />
            </div>
            <button
              title="Save"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={saveChanges}
              style={{ borderColor: saveColor, borderWidth: borderWidth }}
              disabled={!changed}
            >
              Save
            </button>
            <button
              title="Cancel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={cancelChanges}
              style={{ borderColor: cancelColor, borderWidth: borderWidth }}
              disabled={!changed}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  };
  const HeaderTemplate = (props) => {
    return (
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    );
  };

  const renderPDFContent = () => {
    const colsToExport = colsToShow(pumpIndexReportData, selectedMode);
    return (
      <div className="pdfContainer">
        <PDFExport
          ref={pdfExportComponent}
          pageTemplate={HeaderTemplate}
          scale={0.6}
          paperSize="A2"
          margin={40}
          repeatHeaders={true}
          fileName={`Sample PDF Report`}
        >
          <div>
            <div className="pdfHeader">
              <div style={{ textAlign: "center" }}>
                <div>{getTranslatedContent("Site company")}</div>
                <div>{getTranslatedContent("WSMA -Pump index Register")}</div>
                <div>
                  {getTranslatedContent(
                    "Material id, Material name name in french and Dutch"
                  )}
                </div>
                <div>{getTranslatedContent("Tank group number")}</div>
              </div>
            </div>
            <Grid data={pumpIndexReportData}>
              {colsToExport.map((col, index) => (
                <GridColumn
                  field={col.field}
                  title={getTranslatedContent(col.title)}
                >
                  {col?.columns?.map((col, index) => (
                    <GridColumn
                      field={col.field}
                      title={getTranslatedContent(col.title)}
                    />
                  ))}
                </GridColumn>
              ))}
            </Grid>
          </div>
        </PDFExport>
      </div>
    );
  };

  const tabComponent = () => {
    return (
      <div className="modeContainer">
        <ButtonGroup>
          <Button
            togglable={true}
            selected={selectedMode === PumpIndexViewOptions.MATERIAL}
            onClick={() => {
              if (changed) {
                setIsPopupOpen(true);
              } else {
                setSelectedMode(PumpIndexViewOptions.MATERIAL);
              }
            }}
          >
            Material View
          </Button>
          <Button
            togglable={true}
            selected={selectedMode === PumpIndexViewOptions.TANK}
            onClick={() => {
              if (changed) {
                setIsPopupOpen(true);
              } else {
                setSelectedMode(PumpIndexViewOptions.TANK);
              }
            }}
          >
            Tank Group View
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  const getValue = (value) => {
    const decimalValue = parseFloat(value);
    const intValue = isNaN(decimalValue) ? "" : Math.round(decimalValue);
    return intValue;
  };

  const fetchPumpIndexData = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const month = filter.selectedMonth;
    const year = filter.selectedYear;

    let valuesSelected =
      month &&
      year &&
      currentRegion &&
      filter?.selectedSiteId &&
      filter?.selectedProduct;

    if (selectedMode === PumpIndexViewOptions?.TANK) {
      valuesSelected = valuesSelected && filter?.selectedTankGroup;
    }

    if (valuesSelected) {
      let payload = {
        Month: month?.id,
        Year: year?.text,
        GlobalSiteId: filter?.selectedSiteId?.text,
        MaterialCode: filter?.selectedProduct?.id,
      };
      if (selectedMode === PumpIndexViewOptions?.TANK) {
        payload = {
          ...payload,
          TankGroup: filter?.selectedTankGroup?.id,
        };
      }
      setSubmitLoading(true);
      setFirstLoad(false);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        setChangedItems([]);
        setChanged(false);
        let response = null;
        if (selectedMode === PumpIndexViewOptions?.TANK) {
          response = await reportsService.fetchPumpIndexTankData(payload);
        } else {
          response = await reportsService.fetchPumpIndexData(payload);
        }
        response.sort(
          (a, b) =>
            new Date(a.pumpReadingDateTime) - new Date(b.pumpReadingDateTime)
        );
        response = response?.map((item) => {
          const dateOfPumpIndex = getFormattedDate(
            new Date(item.pumpReadingDate)
          );
          const dateOfFuel = getDeliveryDate(item?.deliveries);
          const outBound = getDeliveryIds(item?.deliveries);
          const deliveryQty = getDeliveryQty(item?.deliveries);
          const salesQtyPos = getValue(item?.overallPosSalesQuantity);
          const existingStock = getValue(item?.overallExistingStock);
          const varianceQty = getValue(item?.pumpSalesVarianceQuantity);
          const variancePercent = getValue(item?.pumpSalesVariancePercentage);
          const overallSales = getValue(item?.overallPumpSalesQuantity);
          const salesDifference =
            parseFloat(overallSales) - parseFloat(salesQtyPos);

          let pumpData = {};
          const pumpReadings = item?.pumpReadings.sort(
            (a, b) => new Date(a.pumpNumber) - new Date(b.pumpNumber)
          );
          pumpReadings?.forEach((pump) => {
            pumpData[`pump${pump.pumpNumber}meterReading`] = getValue(
              pump?.endMeterReading
            );
            pumpData[`pump${pump.pumpNumber}qty`] = getValue(
              pump?.salesQuantity
            );
          });
          const hasTestSales =
            item?.hasTestSales === null
              ? ""
              : item?.hasTestSales === true
              ? "Yes"
              : "No";
          return {
            ...item,
            dateOfPumpIndex,
            dateOfFuel,
            outBound,
            deliveryQty,
            salesQtyPos,
            existingStock,
            varianceQty,
            variancePercent,
            overallSales,
            hasTestSales: hasTestSales,
            comments: item?.comments ? item?.comments : "",
            salesDifference,
            ...pumpData,
          };
        });
        if (response?.length > 0) {
          const lockedItem = getFooterItem(response);
          if (selectedMode === PumpIndexViewOptions.MATERIAL) {
            response = [...response, lockedItem];
          }
        }

        setPumpIndexReportData(response);
        setOriginalData(response);
        setSubmitLoading(false);
        if (gridRef.current) {
          gridRef.current.scrollIntoView({
            rowIndex: 0,
          });
        }
      } catch (error) {
        setPumpIndexReportData([]);
        setOriginalData([]);
        setSubmitLoading(false);
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Server error. Unable to fetch data.",
          })
        );
        setError("Server error. Unable to fetch data.");
        setSubmitLoading(false);
        setError("");
      }
    }
  };

  const isColumnLocked = (colsToExport, field) => {
    let columnData = colsToExport?.find((item) => item?.field === field);
    if (!columnData) {
      colsToExport?.forEach((item) => {
        const data = item?.columns?.find((item) => item?.field === field);
        if (data) {
          columnData = data;
        }
      });
    }
    return columnData?.locked;
  };

  const cellRender = (td, props, colsToExport) => {
    const isLocked = isColumnLocked(colsToExport, props?.field);
    let extraProps = {};
    const styles = isLocked ? props.style : {};
    if (props?.dataItem?.locked) {
      extraProps.style = {
        ...styles,
        bottom: 0,
      };
      extraProps.className = props.className + " k-grid-row-sticky";
    }
    return React.cloneElement(
      td,
      {
        ...td.props,
        ...extraProps,
      },
      td.props.children
    );
  };

  const columns = colsToShow(
    pumpIndexReportData,
    selectedMode,
    inputRef,
    handleDataChange,
    selectedItem,
    width
  );

  const gridClassName =
    selectedMode === PumpIndexViewOptions.MATERIAL
      ? "material-grid"
      : "tank-grid";
  return (
    <div className="pump-index-screen-container data-grid">
      <div className="row ">
        {/* <h4 className="title">Pump Index Report</h4> */}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {tabComponent()}
          {showHeaders()}
          {headerButtons()}
          {isPopupOpen && (
            <AlertPopup
              isOpen={isPopupOpen}
              setIsPopupOpen={setIsPopupOpen}
              title={"Information"}
              message={"You have unsaved comments.. please save (or) cancel."}
            />
          )}
          <div ref={divRef} className={gridClassName}>
            <AppDataGrid
              hasLockedRow={true}
              gridRef={gridRef}
              take={1000000000}
              style={{ height: "61vh" }}
              pageable={false}
              filterable={true}
              data={pumpIndexReportData}
              columnsToShow={columns}
              onItemChange={handleDataChange}
              sortable={true}
              cellRender={(td, props) => {
                return cellRender(td, props, columns);
              }}
              ref={exportGridRef}
              exportFileName="Pump Register Report"
            />
          </div>
          {renderPDFContent()}
          {submitLoading && (
            <LoadingPanel message="Fetching the leaks data please wait.." />
          )}
          {filterDataLoading && (
            <LoadingPanel message="Fetching the configurations please wait.." />
          )}
          {submitting && (
            <LoadingPanel message="Saving comments. Please wait.." />
          )}
        </div>
      </div>
    </div>
  );
};

export { PumpIndexScreen };
