import { Window } from "@progress/kendo-react-all";
import { useEffect, useState } from "react";
import "./content-window.scss";

const ContentWindow = (props) => {
    const [visible, setVisibility] = useState(props?.visible || false);

    useEffect(() => {
        setVisibility(props?.visible);
    }, [props]);

    if (visible) {
        return (
            <Window
                className="content-window"
                resizable={false}
                draggable={false}
                title={props?.title || "Window"}
                onClose={props?.onClose}
                initialHeight={400}
                initialWidth={800}
            >
                {props?.children || "Nothing to display"}
            </Window>
        );
    } else {
        return null;
    }
};

export { ContentWindow };
