import "./searchable-dropdown.scss";
import { useEffect, useRef, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

const standardWindow = {
  width: 1650,
  height: 880
}

const SearchableDropdown = (props) => {
  const dropDownRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData.slice());
  const [dynamicHeight, setDynamicHeight] = useState("30px");
  const [refresh, setRefresh] = useState(true)
  const [selectedVal, setSelectedVal] = useState();

  useEffect(() => {
    resizeDropDownHeight(props?.value?.text);
    const updateSize = () => {
      setRefresh(!refresh)
      resizeDropDownHeight(selectedVal);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sanitizedDropdownData = props?.data.map((item, index) => {
      return { id: index, text: item };
    });
    setAllData(sanitizedDropdownData);
    setData(props?.data ? sanitizedDropdownData.slice() : []);
  }, [props?.data]);

  const resizeDropDownHeight = (
    content = dropDownRef.current.element.textContent
  ) => {
    const element = dropDownRef.current?.element
    if (!element) {
      return;
    }
    const fontSize = window.getComputedStyle(element)?.fontSize;
    const lineHeight = parseInt(fontSize);
    const padding = 12;
    const factor = (10/standardWindow.height) * window.innerHeight;
    const clientWidth = dropDownRef.current.element.clientWidth;
    
    const textLength = content?.length;
    const lines = Math.ceil((textLength * factor) / clientWidth);
    const totalLine = lines > 1 ? lines : 1;
    const height = `${totalLine * lineHeight + padding + totalLine}px`;
    setDynamicHeight(height);
  };

  const filterData = (filter) => {
    const data = allData.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return (
    <DropDownList
      refresh={refresh}
      ref={dropDownRef}
      disabled={props.disabled}
      name={props?.name}
      value={props?.value}
      onChange={(event) => {
        props?.onSelect(event);
        resizeDropDownHeight(event.target.value.text);
        setSelectedVal(event.target.value.text)
      }}
      data={data}
      textField="text"
      filterable={props?.filterable || false}
      onFilterChange={filterChange}
      style={{ ...props?.style,minHeight: dynamicHeight}}
      className="searchable-dropdown"
    />
  );
};

export { SearchableDropdown };
