import React from "react";
const SelectionPanel = (props) => {
  return (
    <span className="login-option">
      <div className="row">
        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1">
          <input
            type="radio"
            onChange={() => {
              props.handelSelection(props.isShellUser);
            }}
          />
        </div>
        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-11">
          <h4 className="text">{props.text}</h4>
          <span>{props.eg}</span>
        </div>
      </div>
    </span>
  );
};

export { SelectionPanel };
