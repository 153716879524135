import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-all";
import "./common-utils.scss";

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_INITIAL_PAGE_SIZE = 50;

export const MissingIconCell = (props) => {
  const { dataItem, field } = props;
  const dependent = props?.dependent;
  const value = dataItem?.[field];
  if (value || value === 0 || dataItem?.footerItem) {
    if (dependent) {
      const stock = dataItem?.[dependent?.field];
      if (!(stock || stock === 0 || dataItem?.footerItem)) {
        return (
          <td>
            <div className="dependentContainer">
              {value}{" "}
              <Tooltip openDelay={100} position="left" anchorElement="target">
                <div
                  className="dependentIndicator"
                  title={dependent?.message}
                ></div>
              </Tooltip>
            </div>
          </td>
        );
      }
      return <td>{value}</td>;
    }
    return <td>{value}</td>;
  } else {
    const icon = require("../../../../assets/warning.png");
    const iconClass = "warningIcon";
    const title = "Data not received!";
    return (
      <td>
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img className={iconClass} title={title} src={icon} alt="Warning" />
        </Tooltip>
      </td>
    );
  }
};

export const MissingMandatoryCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  if (value || value === 0 || dataItem?.footerItem) {
    return <td>{value}</td>;
  } else {
    const icon = require("../../../../assets/error.png");
    const iconClass = "errorIcon";
    const title = "Data not received!";
    return (
      <td>
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img className={iconClass} title={title} src={icon} alt="Warning" />
        </Tooltip>
      </td>
    );
  }
};

export const MultilineTextCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  const arrayValues = value?.split("\n");
  const uniqueArray = arrayValues instanceof Array && [...new Set(arrayValues)];
  const lines = uniqueArray;
  return (
    <td>
      {lines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </td>
  );
};

export const ShipToLinkCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  const country =
    dataItem?.country ||
    dataItem?.countryCode ||
    dataItem?.Country ||
    dataItem?.region;
  return (
    <td>
      <Button
        fillMode="link"
        className="ship-to-link-btn"
        onClick={() => props?.linkAction(value, country)}
      >
        {value}
      </Button>
    </td>
  );
};

export const TotalHighlightedCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  if (props.dataItem?.locked) {
    let styles = {};
    let classNames = props.className;
    styles.bottom = 0;
    classNames += " k-grid-row-sticky";
    return (
      <td
        style={{
          textAlign: "center",
          ...styles,
        }}
        className={classNames}
      >
        {value}
      </td>
    );
  }
  return (
    <td {...props} className="totalCount">
      {value}
    </td>
  );
};

export const showTotalRecordsCount = (messages) => {
  return (
    <div className="total-count-container">
      {messages?.map((message) => {
        return <div className="message">{message}</div>;
      })}
    </div>
  );
};

export const BoxItem = ({ item }) => {
  return (
    <div className="boxContainer">
      <span className="highlighter"></span>
      <span className="box-title">{item?.title}</span>
      <span className="box-count">{item?.count}</span>
    </div>
  );
};

export const isBFX = (region) => region === "BENEFRUX";
export const isDE = (region) => region === "DE";
