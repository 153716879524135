// Native packaged imports
import React from "react";
import ReactDOM from "react-dom";
import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";

// Local imports
import { ProtectedRoutes } from "./ui/components/protected-routes/protectedRoutes";
import { HomePage } from "./ui/pages/home/home";
import { LoginPage } from "./ui/pages/login/login";
import { ReportsPage } from "./ui/pages/reports/reports";
import { ProfilePage } from "./ui/pages/profile/profile.jsx";
import { PageNotFound } from "./ui/pages/page-not-found/page-not-found";
import { loggerService } from "./services/logger";
import { CustomNotificationsContainer } from "./ui/components/custom-notifications-container/custom-notifications-container";

// Stylesheet imports
import "./ui/styles/shell.scss";
import "./ui/styles/global.scss";
import "react-notifications/lib/notifications.css";
import { store } from "./state-management/store";
import { AboutPage } from "./ui/pages/about/about";
import { TourPage } from "./ui/pages/tour/tour";
import { ContactPage } from "./ui/pages/contact/contact";
import { UserPreferencePage } from "./ui/pages/user-preference/UserPreference";
import ExporToExcel from "./ui/pages/export-to-excel/ExporToExcel";
import { Helmet } from "react-helmet";

const root = document.querySelector("#root");

const initialize = () => {
  loggerService.environment();

  ReactDOM.render(
    <>
      <Provider store={store}>
        <Helmet>
          <title>WSM Analytics</title>
          <script async />
        </Helmet>
        <Router>
          <Routes>
            <Route path="/exporttoexcel" element={<ExporToExcel />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/reports" element={<ReportsPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/preference" element={<UserPreferencePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/tour" element={<TourPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Navigate to={"/login"} />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
        <CustomNotificationsContainer />
      </Provider>
    </>,
    root
  );
};

/**
 * This is the entry point to the application. The entire processing start from here.
 */
initialize();
