import { Button } from "@progress/kendo-react-all";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "@progress/kendo-theme-default/dist/all.css";

const AlertPopup = ({ isOpen, setIsPopupOpen, title, message }) => {
  return (
    <Dialog title={title} visible={isOpen} closeIcon={false}>
      <div style={{display:'flex', flexDirection: 'column'}}>
        <h5
          style={{ fontFamily: "Arial", fontSize: "14px", fontWeight: "400", paddingBottom: '50px' }}
        >
          {message}
        </h5>
        <div style={{display:'flex', paddingBottom: '20px'}}>
        <DialogActionsBar>
          <Button
            className="k-button close-btn"
            onClick={() => setIsPopupOpen(false)}
          >
            <p
              style={{
                fontFamily: "Arial",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Ok
            </p>
          </Button>
        </DialogActionsBar>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertPopup;
