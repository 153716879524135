import { DropDownList } from "@progress/kendo-react-dropdowns";
import { addAllItem } from "../../reportUtils";
import { Button } from "@progress/kendo-react-buttons";
import { ShipToLinkCell } from "../common-utils";

export const defaultOption = "---- select ----";

const getAllSlocs = (sites) => {
  let slocs = [];
  sites?.forEach((siteData) => {
    if (siteData?.sloc) {
      const isExist = slocs?.find((item) => item.id === siteData?.sloc);
      if (!isExist) {
        const sloc = {
          id: siteData?.sloc,
          text: siteData?.sloc,
        };
        slocs = [...slocs, sloc];
      }
    }
  });

  return slocs;
};

export const getRegionSiteIds = (regions, selectedRegion) => {
  const region = regions?.find(
    (regionData) => regionData?.region === selectedRegion
  );
  if (region) {
    const shipTos = region?.sites?.map((item) => ({
      id: item?.globalSiteId,
      text: item?.globalSiteId,
    }));
    return shipTos?.length > 0 ? shipTos : [];
  }
};

export const getRegionForSiteId = (regions, siteId) => {
  let region = null;
  regions?.some((regionData) => {
    const currentSites = regionData?.sites;
    const siteFound = currentSites?.find(
      (site) => site?.globalSiteId === siteId
    );
    if (siteFound) {
      region = regionData?.region;
      return true;
    }
    return false;
  });
  if (region) {
    return [
      {
        id: region?.toUpperCase(),
        text: region?.toUpperCase(),
      },
    ];
  }
};

const allRegionsSites = (filterData) => {
  let sites = [];
  filterData?.forEach((regionData) => {
    const currentSites = regionData?.sites;
    if (currentSites?.length > 0) {
      sites = [...sites, ...currentSites];
    }
  });

  return sites;
};

const getAllPlants = (sites) => {
  let plants = [];
  sites?.forEach((siteData) => {
    if (siteData?.plant) {
      const isExist = plants?.find((item) => item.id === siteData?.plant);
      if (!isExist) {
        const plant = {
          id: siteData?.plant,
          text: siteData?.plant,
        };
        plants = [...plants, plant];
      }
    }
  });

  return plants;
};

export const getSlocValues = (filterData, filters = null) => {
  let selectedRegion = filters?.selectedRegions?.[0];
  if (!selectedRegion) {
    return [];
  } else {
    let slocs = [];
    let sites = [];
    if (selectedRegion.id === "all") {
      sites = allRegionsSites(filterData);
    } else {
      filters?.selectedRegions?.forEach((selectedRegion) => {
        const regionData = filterData?.find(
          (region) => region.region === selectedRegion.id
        );
        const currentSites = regionData?.sites;
        if (currentSites?.length > 0) {
          sites = [...sites, ...currentSites];
        }
      });
    }
    const selectedShipTo = filters?.selectedShipTo?.[0]?.id;

    if (selectedShipTo === "all") {
      slocs = getAllSlocs(sites);
    } else {
      filters?.selectedShipTo?.forEach((shipTo) => {
        const siteData = sites?.find(
          (siteData) => siteData?.globalSiteId === shipTo.id
        );
        if (siteData?.sloc) {
          const isExist = slocs?.find((item) => item.id === siteData?.sloc);
          if (!isExist) {
            const sloc = {
              id: siteData?.sloc,
              text: siteData?.sloc,
            };
            slocs = [...slocs, sloc];
          }
        }
      });
    }
    slocs = slocs?.length > 1 ? addAllItem(slocs) : slocs;
    return slocs;
  }
};
export const getPlantValues = (
  filterData,
  filters = null,
  addAllOption = true
) => {
  let selectedRegion = filters?.selectedRegions?.[0];
  if (!selectedRegion) {
    return [];
  } else {
    let plants = [];
    let sites = [];
    if (selectedRegion.id === "all") {
      sites = allRegionsSites(filterData);
    } else {
      filters?.selectedRegions?.forEach((selectedRegion) => {
        const regionData = filterData?.find(
          (region) => region.region === selectedRegion.id
        );
        const currentSites = regionData?.sites;
        if (currentSites?.length > 0) {
          sites = [...sites, ...currentSites];
        }
      });
    }

    const selectedShipTo = filters?.selectedShipTo?.[0]?.id;

    if (selectedShipTo === "all") {
      plants = getAllPlants(sites);
    } else {
      filters?.selectedShipTo?.forEach((shipTo) => {
        const siteData = sites?.find(
          (siteData) => siteData?.globalSiteId === shipTo.id
        );
        if (siteData?.plant) {
          const isExist = plants?.find((item) => item.id === siteData?.plant);
          if (!isExist) {
            const plant = {
              id: siteData?.plant,
              text: siteData?.plant,
            };
            plants = [...plants, plant];
          }
        }
      });
    }
    return plants?.length > 1 && addAllOption ? addAllItem(plants) : plants;
  }
};

const DropDownCell = (props) => {
  const {
    dataItem,
    field,
    onChange,
    commentsOptions,
    disabled = false,
  } = props;

  const handleDropDownChange = (event) => {
    onChange({
      dataItem: { ...dataItem, [field]: event.target.value },
      field: field,
      syntheticEvent: event,
    });
  };
  const options = commentsOptions;
  return (
    <td className="dropDownCellContainer">
      <DropDownList
        data={options}
        value={dataItem?.[field] || defaultOption}
        onChange={handleDropDownChange}
        disabled={disabled}
      />
    </td>
  );
};

export const CustomHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const classNames = "k-customHeader";
  return (
    <th {...props}>
      <div className={classNames} title={props?.label || columnTitle}>
        {columnTitle}
        {props.children}
      </div>
    </th>
  );
};

export const colsToShow = (
  trendAnalysisData,
  commentsOptions,
  ref,
  selectedItem,
  onChange,
  currentRegion,
  handleShipToLinkAction
) => {
  let columns = [
    {
      title: "Document Date",
      field: "documentDate",
      headerCell: CustomHeaderCell,
      filterable: true,
    },
    {
      title: "Ship To",
      field: "shipTo",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      filterable: true,
    },
    {
      title: "Plant",
      field: "plant",
      headerCell: CustomHeaderCell,
      filterable: true,
    },
    {
      title: "SLOC",
      field: "sloc",
      filterable: true,
    },
    {
      title: "Material No",
      field: "materialNumber",
      headerCell: CustomHeaderCell,
      filterable: true,
    },
    {
      title: "OutBound Delivery No",
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.085,
      filterable: true,
    },
    {
      title:
        currentRegion === "DE" ? "Calculated Delivery" : "Detected Delivery",
      field: "calculatedDelivery",
      headerCell: CustomHeaderCell,
      filterable: true,
    },
    {
      title: "Confirmed Delivery",
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      filterable: true,
    },
    {
      title: "Difference",
      field: "difference",
      headerCell: CustomHeaderCell,
      filterable: true,
    },
    {
      title: "Comment",
      field: "comment",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <DropDownCell {...props} commentsOptions={commentsOptions} />;
      },
      width: window.innerWidth * 0.12,
    },
  ];

  return columns;
};

export const auditColsToShow = (currentRegion, handleShipToLinkAction) => {
  let columns = [
    {
      title: "Document Date",
      field: "documentDate",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.06,
      filterable: true,
    },
    {
      title: "Ship To",
      field: "shipTo",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: window.innerWidth * 0.06,
      filterable: true,
    },
    {
      title: "Material No",
      field: "materialNumber",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.06,
      filterable: true,
    },
    {
      title: "Tank",
      field: "tankId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.06,
      filterable: true,
    },
    {
      title: "Entity",
      field: "entity",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.08,
      filterable: true,
    },
    {
      title: "OutBound Delivery No",
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.09,
      filterable: true,
    },
    {
      title: "Confirmed Delivery",
      field: "confirmedDelivery",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.07,
      filterable: true,
    },
    {
      title: "Comment",
      field: "comment",
      headerCell: CustomHeaderCell,
    },
    {
      title: "Modified By",
      field: "userName",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
    },
    {
      title: "Modified On",
      field: "modifiedOn",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: window.innerWidth * 0.08,
    },
  ];

  return columns;
};

export const colsToExport = (currentRegion) => {
  let columns = [
    {
      title: "Plant",
      field: "plant",
    },
    {
      title: "Document Date",
      field: "documentDate",
    },
    {
      title: "Ship To",
      field: "shipTo",
    },
    {
      title: "SLOC",
      field: "sloc",
    },
    {
      title: "Material No",
      field: "materialNumber",
    },
    {
      title: "OutBound Delivery Id's",
      field: "outBoundDeliveryId",
    },
    {
      title: "Tank /Material Level",
      field: "type",
    },
    {
      title: "Delivery Start Date",
      field: "deliveryStartDate",
    },
    {
      title: "Start Temperature",
      field: "startTemperature",
    },
    {
      title: "Delivery End Date",
      field: "deliveryEndDate",
    },
    {
      title: "End Temperature",
      field: "endTemperature",
    },
    {
      title: "Tank",
      field: "tank",
    },
    {
      title:
        currentRegion === "DE" ? "Calculated Delivery" : "Detected Delivery",
      field: "calculatedDelivery",
    },
    {
      title: "Confirmed Delivery",
      field: "confirmedDelivery",
    },
    {
      title: "Difference",
      field: "difference",
    },
    {
      title: "Comment",
      field: "comment",
    },
  ];

  return columns;
};

const EditDeliveryCell = (props) => {
  const { rowItem, field, dataItem } = props;
  const title = dataItem?.[field];
  const EditIcon = require("../../../../../assets/edit-icon.png");
  const className = title === "Edit" ? "edit-btn" : "cancel-btn";
  const saveEnabled = dataItem?.saveEnabled;
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        disabled={!saveEnabled && title === "Save"}
        onClick={(event) => {
          if (title === "Save") {
            const x = event.clientX;
            const y = event.clientY;
            const offset = {
              left: x,
              top: y,
            };
            props?.onSave(rowItem, dataItem, offset);
          } else {
            props?.onEdit(rowItem, dataItem);
          }
        }}
      >
        {title === "Edit" ? (
          <img
            className="editIcon"
            src={EditIcon}
            alt="Edit"
            title={"Edit Details"}
          />
        ) : (
          title
        )}
      </Button>
    </td>
  );
};

const CancelDeliveryCell = (props) => {
  const { rowItem, field, dataItem } = props;
  const title = dataItem?.[field];
  const className = "cancel-btn";
  return title === "Cancel" ? (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        onClick={(event) => {
          props?.onCancel(rowItem, dataItem);
        }}
      >
        {title}
      </Button>
    </td>
  ) : (
    <td></td>
  );
};

export const detailColsToShow = ({
  rowItem,
  editing,
  onEdit,
  onSave,
  onCancel,
  onUpdate,
  currentRegion,
}) => {
  let columns = [
    {
      title: "Delivery Start Time",
      field: "deliveryStartDate",
      headerCell: CustomHeaderCell,
      editable: false,
      // editor: "date",
      // cell: (props) => {
      //   return <TimePickerCell {...props} onUpdate={onUpdate} />;
      // },
    },
    {
      title: "Start Temperature",
      field: "startTemperature",
      editable: false,
      // editor: "text",
      headerCell: CustomHeaderCell,
    },
    {
      title: "Delivery End Time",
      field: "deliveryEndDate",
      editable: false,
      headerCell: CustomHeaderCell,
      // cell: (props) => {
      //   return <TimePickerCell {...props} onUpdate={onUpdate} />;
      // },
      // editable: true,
      // editor: "date",
    },
    {
      title: "End Temperature",
      field: "endTemperature",
      editable: false,
      headerCell: CustomHeaderCell,
      // editable: true,
      // editor: "text",
    },
    {
      title: "OutBound Delivery No",
      field: "outBoundDeliveryId",
      headerCell: CustomHeaderCell,
      // width: window.innerWidth * 0.09,
      editable: true,
      editor: "text",
    },
    {
      title: "Tank",
      field: "tank",
      editable: false,
      headerCell: CustomHeaderCell,
      // editable: true,
      // editor: "text",
    },
    {
      title:
        currentRegion === "DE" ? "Calculated Delivery" : "Detected Delivery",
      field: "calculatedDelivery",
      headerCell: CustomHeaderCell,
      editable: false,
      // editable: true,
      // editor: "text",
    },
    {
      title: "Confirmed Delivery",
      field: "confirmedDelivery",
      // width: window.innerWidth * 0.08,
      headerCell: CustomHeaderCell,
      editable: true,
      editor: "text",
    },
    {
      title: "Difference",
      field: "difference",
      editable: false,
      headerCell: CustomHeaderCell,
      // editable: true,
      // editor: "text",
    },
    {
      title: "",
      field: "title",
      width: window.innerWidth * 0.035,
      headerCell: (props) => <></>,
      cell: (props) => {
        return (
          <EditDeliveryCell
            {...props}
            rowItem={rowItem}
            onEdit={onEdit}
            onSave={onSave}
          />
        );
      },
    },
    {
      title: "",
      field: "subTitle",
      headerCell: (props) => <></>,
      cell: (props) => {
        return (
          <CancelDeliveryCell
            {...props}
            rowItem={{}}
            childItem={{}}
            onCancel={onCancel}
          />
        );
      },
    },
  ];

  return columns;
};
