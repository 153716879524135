import jwtDecode from "jwt-decode";
import { loggerService } from "../services/logger";
import { triggerNotification } from "../state-management/actions";
import { store } from "../state-management/store";
/**
 * This service will help in token management.
 * It is capable of decoding, refreshing and disposing a token.
 */
class TokenManager {
  sessionTimer = null;
  token = null;

  /**
   * This function will decode a given JWT into human readable JSON format.
   * @param {string} token - A valid JWT token.
   */
  decodeToken(token) {
    this.token = token;
    try {
      return token ? jwtDecode(token) : null;
    } catch (err) {
      store.dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: false,
          },
          message: `Unable to decode the token.`,
        })
      );
      return null;
    }
  }

  /**
   * This function will trigger a silent refresh, so that a new JWT can be retrived.
   */
  triggerSilentRefresh() {
    const decodedToken = this.decodeToken(this.token);
    if (decodedToken) {
      setInterval(() => {
        // TODO
      }, 60 * 5 * 1000);
    }
  }

  /**
   * This method starts a session timeout, once the timeout runs out the session will be disposed off and user will be logged out.
   */
  triggerSessionTimeout() {
    this.sessionTimer = setTimeout(() => {
      this.disposeSession();
    }, 30 * 60 * 1000); // A session timeout of 30 mins
  }

  /**
   * This method resets the session timeout, and gives the user another 30 min.
   */
  resetSessionTimeout() {
    clearTimeout(this.sessionTimer);
    this.triggerSessionTimeout();
  }

  /**
   * This method is used to dispose off an ongoing session and log the user out of system.
   */
  disposeSession() {
    clearTimeout(this.sessionTimer);
    window.location.replace("/login");
  }
}

loggerService.dev("Creating Token Manager Service");
const tokenManager = new TokenManager();

loggerService.dev("Exporting Token Manager Service");
export { tokenManager };
