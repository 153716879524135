import "./contact.scss";

const ContactPage = () => {
  return (
    <div className="about-container">
      <div className="content">
        <img src={require("../../../assets/logo.png")} alt="shell" />
        <h1>Contact us</h1>
        <p>
          Please reach out to &nbsp;
          <a href="mailto:GXSBOBNGRDIDevTeam@shell.com">WSM Analytics Team</a>
          &nbsp; for further questions.
        </p>
      </div>
    </div>
  );
};
export { ContactPage };
