import "./main.scss";
import { IssueListScreen } from "../issue-list/issue-list";

const IssueLogScreen = () => {

    return (
        <div className="container user-management-screen-container">
            <div className="row content-container">
                {
                    <IssueListScreen/>
                }
            </div>
        </div>
    )
}
export { IssueLogScreen };