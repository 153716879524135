import {
  DropDownList,
  Input,
  Error,
  Loader,
  Label,
} from "@progress/kendo-react-all";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { siteService } from "../../../../../services/sites";
import { userService } from "../../../../../services/user";
import { triggerNotification } from "../../../../../state-management/actions";
import { MultiSelectTreeDropdown } from "../../../../components/multi-select-tree/main";

const UserOnboardingScreen = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [step, setStep] = useState(1);
  const [userRoles, setUserRoles] = useState([]);
  const [siteIdsList, setSiteIdsList] = useState([]);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    userType: "",
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    uniqueUserId: "",
    userRole: "",
    siteAccess: [],
  });
  const [errors, setErrors] = useState({
    firstName: null,
    lastNameError: null,
    email: false,
    country: false,
    uniqueUserId: false,
    userRole: false,
    siteAccess: false,
  });

  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const countries = ["DE", "BENEFRUX"];
  const userTypes = [
    { label: "Shell User", value: "shellUser" },
    { label: "Non-Shell User", value: "nonShellUser" },
  ];

  useEffect(() => {
    const userType = values.userType;
    if (userType === "shellUser")
      userService.fetchUserRoles("shellUser").then((res) => {
        if (res.length) setUserRoles(res);
      });
    if (userType === "nonShellUser")
      userService.fetchUserRoles("nonShellUser").then((res) => {
        if (res.length) setUserRoles(res);
      });
  }, [values.userType]);

  const onCountryChange = ({ value }) => {
    siteService.fetchSiteIdList(value).then((res) => {
      if (res.length) {
        const idList = res.map((siteId) => ({ text: siteId, id: siteId }));
        setSiteIdsList([
          {
            text: value,
            id: 1,
            items: idList,
          },
        ]);
      }
    });
    setValues({ ...values, country: value });
    setErrors({ ...errors, country: !value });
  };

  /**
   * This function returns a message when there is no site ids.
   */
  const NoSiteIds = () => {
    return (
      <div className="k-nodata">
        <h4>
          {values.country
            ? siteIdsList.length
              ? `NO DATA FOUND.` // This will show when no data found on filter.
              : `${`Fetching ${values.country} site ids...`}` // This will show while fetching site ids of selected country from server
            : `Please select a country.`}
          {/* This will show if user doesn't select any country */}
        </h4>
      </div>
    );
  };

  const resetForm = () => {
    setValues({
      userType: "",
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      uniqueUserId: "",
      userRole: "",
      siteAccess: [],
    });
    setErrors({
      firstName: false,
      lastNameError: false,
      email: false,
      country: false,
      uniqueUserId: false,
      userRole: false,
      siteAccess: false,
    });
    setStep(1);
  };

  const handleNextStep = () => {
    const { country, email, firstName, lastName } = values;
    if (
      step === 1 &&
      firstName &&
      lastName &&
      email &&
      !email.includes("@shell.com") &&
      country
    ) {
      const nonShellUser = {
        firstName,
        lastName,
        email,
        country,
      };
      setLoading(true);
      setLoadingMessage("Registering user to console...");
      userService.preRegisterNonShellUser(nonShellUser).then((uuid) => {
        if (uuid) {
          setValues({ ...values, uniqueUserId: uuid });
          setStep(step + 1);
        }
        setLoading(false);
        setLoadingMessage("");
      });
    }
    if (email.includes("@shell.com")) setErrors({ ...errors, email: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      country,
      email,
      firstName,
      lastName,
      userRole,
      uniqueUserId,
      siteAccess,
    } = values;

    if (
      firstName &&
      lastName &&
      email &&
      emailRegex.test(email) &&
      country &&
      userRole &&
      uniqueUserId
    ) {
      const userRoleId = userRoles.find((role) => role.RoleName === userRole)[
        "RoleId"
      ];
      let accessControl = siteAccess.map((site) => site.text);
      if (userRoleId === 1 || userRoleId === 3) {
        setLoading(true);
        setLoadingMessage("Onboarding user...");
        accessControl = ["ALL"];
        const payload = {
          UserEmail: email,
          UserFirstName: firstName,
          UserLastName: lastName,
          UniqueUserId: uniqueUserId,
          InternalUserId: uniqueUserId,
          UserRole: userRole,
          RoleId: userRoleId,
          UserType: "Wetstock",
          IsActive: "N",
          Country: country,
          AccessControl: accessControl,
        };
        userService.onboardNewUser(payload).then((res) => {
          if (res.Error) {
            setLoading(false);
            dispatch(
              triggerNotification({
                type: {
                  style: "error",
                  icon: true,
                },
                message: res.Error,
              })
            );
          } else {
            dispatch(
              triggerNotification({
                type: {
                  style: "success",
                  icon: true,
                },
                message: "User onboarded successfully",
              })
            );
            setLoadingMessage("");
            setLoading(false);
            resetForm();
            setStep(1);
          }
        });
      } else {
        const errorFields = {};
        if (!siteAccess.length) {
          errorFields.siteAccess = true;
          setErrors({ ...errors, ...errorFields });
        } else {
          setLoading(true);
          setLoadingMessage("Onboarding user...");
          accessControl = country === "DE" ? ["DE"] : ["BE", "NL", "FR", "LU"];
          const payload = {
            UserEmail: email,
            UserFirstName: firstName,
            UserLastName: lastName,
            UniqueUserId: uniqueUserId,
            InternalUserId: uniqueUserId,
            UserRole: userRole,
            RoleId: userRoleId,
            UserType: "Wetstock",
            IsActive: "N",
            Country: country,
            AccessControl: accessControl,
          };
          userService.onboardNewUser(payload).then((res) => {
            if (res.Error) {
              setLoading(false);
              dispatch(
                triggerNotification({
                  type: {
                    style: "error",
                    icon: true,
                  },
                  message: res.Error,
                })
              );
            } else {
              dispatch(
                triggerNotification({
                  type: {
                    style: "success",
                    icon: true,
                  },
                  message: "User onboarded successfully",
                })
              );
              setLoadingMessage("");
              setLoading(false);
              resetForm();
              setStep(1);
            }
          });
        }
      }
    } else {
      const errorFields = {};
      setErrors({ ...errors, ...errorFields });

      if (!firstName) errorFields.firstName = true;
      if (!lastName) errorFields.lastNameError = true;
      if (!email || !emailRegex.test(email)) errorFields.email = true;
      if (!country) errorFields.country = true;
      if (!userRole) errorFields.userRole = true;
      if (!uniqueUserId) errorFields.uniqueUserId = true;
      if (!siteAccess.length) errorFields.siteAccess = true;
      setErrors({ ...errors, ...errorFields });
    }
  };

  return (
    <div className="container data-grid">
      <div className="row ">
        {/* <h4 className="title">User Onboarding Screen</h4> */}
        {loading ? (
          <div className="loader-container">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
            <h4>{loadingMessage}</h4>
          </div>
        ) : (
          <div className="data-grid-container" style={{ height: "68vh" }}>
            <form
              className="k-form row onboarding-form"
              onSubmit={handleSubmit}
            >
              <fieldset>
                <div className="col-xs-12 onboarding-form-user-type">
                  <Label>Please select User Type you want to onboard</Label>
                  <RadioGroup
                    name="userType"
                    label="User Type (Mandatory)"
                    layout="horizontal"
                    data={userTypes}
                    onChange={(e) =>
                      setValues({ ...values, userType: e.value })
                    }
                    value={values.userType}
                    disabled={step !== 1}
                  />
                </div>
                {values.userType && (
                  <div className="">
                    <div className="col-xs-12 onboarding-form-input-group">
                      <div className="col-xs-6">
                        <Input
                          name="firstName"
                          label="First Name (Mandatory)"
                          maxLength={30}
                          onChange={(e) => {
                            setValues({ ...values, firstName: e.value });
                            setErrors({
                              ...errors,
                              firstName:
                                !e.value && "This field can't be empty.",
                            });
                          }}
                          value={values.firstName}
                          valid={!errors.firstName}
                          disabled={step === 2}
                        />
                        {values.firstName.length > 29 ? (
                          <Error>First Name Should Be Less Than 30.</Error>
                        ) : (
                          errors.firstName && <Error>{errors.firstName}</Error>
                        )}
                      </div>
                      <div className="col-xs-6">
                        <Input
                          name="lastName"
                          label="Last Name (Mandatory)"
                          maxLength={30}
                          onChange={(e) => {
                            setValues({ ...values, lastName: e.value });
                            setErrors({ ...errors, lastNameError: !e.value });
                          }}
                          value={values.lastName}
                          valid={!errors.lastNameError}
                          disabled={step === 2}
                        />
                        {values.lastName.length > 29 ? (
                          <Error>Last Name Should Be Less Than 30.</Error>
                        ) : (
                          errors.lastNameError && (
                            <Error>{errors.lastNameError}</Error>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-xs-12 onboarding-form-input-group">
                      <div className="col-xs-6">
                        <Input
                          name="email"
                          label="Email (Mandatory)"
                          maxLength={30}
                          onChange={(e) => {
                            setValues({ ...values, email: e.value });
                            setErrors({
                              ...errors,
                              email: !e.value || !emailRegex.test(e.value),
                            });
                          }}
                          value={values.email}
                          valid={!errors.email}
                          disabled={step === 2}
                        />
                        {errors.email && (
                          <Error>Please enter valid email.</Error>
                        )}
                      </div>
                      <div className="col-xs-6">
                        <DropDownList
                          name="country"
                          label="Country (Mandatory)"
                          className="user-onboarding-dropdown"
                          onChange={onCountryChange}
                          data={countries}
                          value={values.country}
                          valid={!errors.country}
                          disabled={step === 2}
                        />
                        {errors.country && (
                          <Error>This field can't be empty.</Error>
                        )}
                      </div>
                    </div>
                    {(values.userType === "shellUser" || step === 2) && (
                      <>
                        <div className="col-xs-12 onboarding-form-input-group">
                          <div className="col-xs-6">
                            <Input
                              name="uniqueUserId"
                              label="Unique User ID (Mandatory)"
                              maxLength={50}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  uniqueUserId: e.value,
                                });
                                setErrors({
                                  ...errors,
                                  uniqueUserId: !e.value,
                                });
                              }}
                              value={values.uniqueUserId}
                              valid={!errors.uniqueUserId}
                              disabled={step === 2}
                            />
                            {values.uniqueUserId.length > 49 ? (
                              <Error>Unique Id Should Be Less Than 50.</Error>
                            ) : (
                              errors.uniqueUserId && (
                                <Error>This field can't be empty.</Error>
                              )
                            )}
                          </div>
                          <div className="col-xs-6">
                            <DropDownList
                              name="userRole"
                              label="User Role (Mandatory)"
                              className="user-onboarding-dropdown"
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  userRole: e.value.text,
                                  roleId: e.value.id,
                                });
                                setErrors({ ...errors, userRole: !e.value });
                              }}
                              data={userRoles.map((role) => ({
                                text: role.RoleName,
                                id: role.RoleId,
                              }))}
                              value={{
                                text: values.userRole,
                                id: values.roleId,
                              }}
                              dataItemKey="id"
                              textField="text"
                              valid={!errors.userRole}
                            />
                            {errors.userRole && (
                              <Error>This field can't be empty.</Error>
                            )}
                          </div>
                        </div>
                        <div className="col-xs-6">
                          {values.roleId === 1 || values.roleId === 3 ? (
                            <h5 className="site-access-note">
                              <b>Note: </b>
                              {`${values.userRole} have all site access.`}
                            </h5>
                          ) : (
                            <>
                              <MultiSelectTreeDropdown
                                name="siteAccess"
                                label="Site Access (Mandatory)"
                                data={siteIdsList}
                                value={values.siteAccess}
                                noData={NoSiteIds}
                                style={{ borderRadius: 0 }}
                                onChange={(value) => {
                                  setValues({ ...values, siteAccess: value });
                                  setErrors({
                                    ...errors,
                                    siteAccess: !value.length,
                                  });
                                }}
                                disabled={
                                  values.roleId === 1 || values.roleId === 3
                                }
                                validityStyles={errors.siteAccess}
                              />
                              {errors.siteAccess && (
                                <Error>Please select atleast one site.</Error>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </fieldset>
              <div className="k-form-buttons col-xl-12 col-lg-12 col-md-12 col-xs-12 col-xs-12">
                <button
                  onClick={resetForm}
                  type={"reset"}
                  className="user-management-screen-btn-reset "
                >
                  Reset
                </button>

                {values.userType === "shellUser" ||
                (values.userType === "nonShellUser" && step === 2) ? (
                  <button
                    type="submit"
                    className="user-management-screen-btn-submit "
                  >
                    Submit
                  </button>
                ) : (
                  <input
                    type="button"
                    onClick={handleNextStep}
                    className="user-management-screen-btn-submit "
                    value="Next"
                    disabled={
                      !values.firstName ||
                      !values.lastName ||
                      !values.email ||
                      !values.country
                    }
                  />
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
export { UserOnboardingScreen };
