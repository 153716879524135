import "./tank-leaks.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedReport,
  triggerNotification,
} from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { Button, DateInput, DateRangePicker } from "@progress/kendo-react-all";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { store } from "../../../../../state-management/store";
import {
  addAllOptions,
  colsToShow,
  getBenefruxRegions,
  getTankGroups,
} from "./tank-leaks-utils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { ButtonGroup } from "@progress/kendo-react-buttons";
import { getFormattedDate } from "../../../../../services/utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import {
  getAllSelectedOptions,
  SCREEN_IDS,
  getYesterdayDate,
  getRegions,
  getShipTos,
  getSiteNames,
} from "../../reportUtils";
import { DEFAULT_PAGE_SIZE, isBFX } from "../common-utils";

export const LeakTabOptions = {
  TANK_LEAKS: 0,
  DAILY_REPORT: 1,
  MINS_REPORT: 2,
};

const TankLeaksScreen = () => {
  const dispatch = useDispatch();
  const allOption = {
    id: "all",
    text: "All",
  };
  const [selectedTab, setSelectedTab] = useState(LeakTabOptions.TANK_LEAKS);
  const [resetIndex, setResetIndex] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);

  const [filters, setFilters] = useState({
    selectedRegions: [getBenefruxRegions()?.[0]],
    regions: getBenefruxRegions(),
    selectedSiteId: null,
    selectedSiteName: null,
    selectedProducts: [],
    products: [],
    selectedTanks: [],
    tankGroup: [],
    selectedDates: {
      start: getYesterdayDate(),
      end: getYesterdayDate(),
    },
    prevSelectedDates: {
      start: null,
      end: null,
    },
    allSiteIds: [],
    allSiteNames: [],
  });

  const [masterData, setMasterData] = useState();
  const [leaksData, setLeaksData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [masterDataUpdated, setMasterDataUpdated] = useState(false);
  const [dateUpdated, setDateUpdated] = useState(false);
  const [tabChanged, setTabChanged] = useState(false);

  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const defaultRegion = useSelector((state) => state?.selectedRegion);
  const [currentRegion, setCurrentRegion] = useState(defaultRegion);
  const report = store.getState()?.selectedReport;

  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setCurrentRegion(selectedRegion);
  });

  const getTabIndex = (title) => {
    switch (title) {
      case "DAILY RECONCILIATION REPORT":
        return LeakTabOptions.DAILY_REPORT;
      case "15MINS RECONCILIATION REPORT":
        return LeakTabOptions.MINS_REPORT;
      default:
        return LeakTabOptions.TANK_LEAKS;
    }
  };

  useEffect(() => {
    const { selectedReport } = store.getState();
    if (selectedReport?.pageId === SCREEN_IDS.TANK_THRESHOLD_RESULTS) {
      setSelectedTab(getTabIndex(selectedReport?.tab));
    }
  }, [report?.reportId]);

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedSiteId
  );

  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );

  const [datePickerClosed, setDatePickerClosed] = useState(true);

  useEffect(() => {
    fetchMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  useEffect(() => {
    const regions = getRegions(masterData);
    const selectedRegion =
      isBFX(currentRegion) && regions?.length > 0
        ? [regions?.[0]]
        : [allOption];
    setFilters({
      ...filters,
      selectedRegions: selectedRegion,
    });
    setRegionSelectedValues(selectedRegion);
    const siteIds =
      filters.selectedRegions?.length > 0
        ? getShipTos(masterData, filters.selectedRegions, false)
        : [];

    const siteNames =
      filters.selectedRegions?.length > 0
        ? getSiteNames(masterData, filters.selectedRegions, false)
        : [];

    setFilters((prev) => {
      const siteId = prev?.selectedSiteId
        ? prev?.selectedSiteId
        : report?.filters?.GlobalSiteId?.[0]
        ? report?.filters?.GlobalSiteId?.[0]
        : siteIds?.[0]?.globalSiteId;
      const siteData = siteIds?.find((item) => item.id === siteId);
      const selectedDate = report?.filters?.RequestDate
        ? {
            start: report.filters.RequestDate?.startDate,
            end: report.filters.RequestDate?.endDate,
          }
        : {
            start: prev.selectedDates.start,
            end: prev.selectedDates.end,
          };

      return {
        ...prev,
        regions: regions,
        selectedRegions: selectedRegion,
        selectedSiteId: siteId,
        selectedSiteName: siteData?.siteName,
        allSiteIds: siteIds,
        allSiteNames: siteNames,
        selectedDates: selectedDate,
      };
    });
    setMasterDataUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    if (masterDataUpdated && tabChanged) {
      fetchThresholdData();
    }
    setMasterDataUpdated(false);
    setDateUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterDataUpdated]);

  const leakTabComponent = () => {
    return (
      <div className="modeContainer">
        <ButtonGroup>
          <Button
            togglable={true}
            selected={selectedTab === LeakTabOptions.TANK_LEAKS}
            onClick={() => {
              setSelectedTab(LeakTabOptions.TANK_LEAKS);
            }}
          >
            Tank Leaks
          </Button>
          <Button
            togglable={true}
            selected={selectedTab === LeakTabOptions.DAILY_REPORT}
            onClick={() => {
              setSelectedTab(LeakTabOptions.DAILY_REPORT);
            }}
          >
            Daily Report
          </Button>
          <Button
            togglable={true}
            selected={selectedTab === LeakTabOptions.MINS_REPORT}
            onClick={() => {
              setSelectedTab(LeakTabOptions.MINS_REPORT);
            }}
          >
            15 Mins Report
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  const getProductInfo = (productCodes, siteProducts) => {
    if (productCodes?.length) {
      let products = [];
      productCodes?.forEach((code) => {
        const product = siteProducts?.find((item) => item.id === code);
        if (product) {
          products.push(product);
        }
      });
      return products;
    }
    return null;
  };

  const setFilterOptions = () => {
    const siteData = filters?.allSiteIds?.find(
      (site) => site?.globalSiteId === filters?.selectedSiteId
    );
    const siteProducts = siteData?.materials?.map((product) => ({
      id: product?.materialNumber,
      text: product?.productName,
      ...product,
    }));
    const productsData = addAllOptions(siteProducts || []);
    const filterProducts = getProductInfo(
      report?.filters?.Product,
      productsData
    );
    const products =
      filterProducts?.length > 0 ? filterProducts : [productsData[0]];
    const selectedProducts = productsData?.length > 0 ? products : [];

    setFilters((prev) => {
      return {
        ...prev,
        products: productsData,
        selectedProducts: selectedProducts,
      };
    });
    if (siteProducts?.length) {
      setProductSelectedValues(selectedProducts);
    } else {
      setProductSelectedValues([]);
    }
  };
  useEffect(() => {
    if (!filters?.selectedRegions || filters?.selectedRegions?.length === 0) {
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: [],
          allSiteIds: [],
          allSiteNames: [],
        };
      });
    } else {
      const siteIds =
        filters.selectedRegions?.length > 0
          ? getShipTos(masterData, filters.selectedRegions, false)
          : [];

      const siteNames =
        filters.selectedRegions?.length > 0
          ? getSiteNames(masterData, filters.selectedRegions, false)
          : [];
      setFilters((prev) => {
        return {
          ...prev,
          allSiteIds: siteIds,
          allSiteNames: siteNames,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedRegions]);

  useEffect(() => {
    const site = filters?.allSiteNames?.find(
      (site) => site?.globalSiteId === filters?.selectedSiteId
    );
    if (site) {
      setSiteNameSelectedValues([site]);
    } else {
      setSiteNameSelectedValues([]);
    }
    setFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteId]);

  const getTankInfo = (tankCodes, tankGroupsData) => {
    if (tankCodes?.length) {
      let tanks = [];
      tankCodes?.forEach((code) => {
        const tank = tankGroupsData?.find((item) => item.id === code);
        if (tank) {
          tanks.push(tank);
        }
      });
      return tanks;
    }
    return null;
  };

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: regionSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    const tankGroups = getTankGroups(
      masterData,
      filters,
      productSelectedValues
    );
    const tankGroupsData =
      tankGroups?.length === 1 ? tankGroups : addAllOptions(tankGroups, true);
    const filterTanks = getTankInfo(
      report?.filters?.TankGroupId,
      tankGroupsData
    );
    const tanks = filterTanks?.length > 0 ? filterTanks : [tankGroupsData[0]];
    const selectedTankGroups = tanks?.length > 0 ? tanks : [];
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
        tankGroup: tankGroupsData,
        selectedTanks: selectedTankGroups,
      };
    });
    if (productSelectedValues?.length) {
      setTankSelectedValues(selectedTankGroups);
    } else {
      setTankSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTanks: tankSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    if (filters?.allSiteIds?.length) {
      const siteData = filters?.allSiteIds?.find(
        (site) => site?.globalSiteId === shipToSelectedValues?.[0]?.id
      );
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: shipToSelectedValues?.[0]?.id,
          selectedSiteName: siteData?.siteName,
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    const site = filters?.allSiteIds?.find(
      (site) => site?.siteName === filters?.selectedSiteName
    );
    if (site) {
      setShipToSelectedValues([site]);
      if (!siteNameSelectedValues?.length) {
        const site = filters?.allSiteNames?.find(
          (site) => site?.globalSiteId === filters?.selectedSiteId
        );
        if (site) {
          setSiteNameSelectedValues([site]);
          setFilterOptions();
        }
      }
    } else {
      setShipToSelectedValues([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteName]);

  useEffect(() => {
    if (filters?.allSiteIds) {
      const siteData = filters?.allSiteIds?.find(
        (site) => site?.siteName === siteNameSelectedValues?.[0]?.id
      );
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: siteData?.globalSiteId,
          selectedSiteName: siteData?.siteName,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  const onShipToSelection = (event) => {
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues(
        getMultiSelectTreeValue(filters?.allSiteIds, {
          ...multiSelectionDropDownFields,
          ...event,
          value: shipToSelectedValues,
        })
      );
    }
  };

  const onSiteNameSelection = (event) => {
    if (event.operation === "delete") {
      setSiteNameSelectedValues([]);
    } else {
      setSiteNameSelectedValues(
        getMultiSelectTreeValue(filters?.allSiteNames, {
          ...multiSelectionDropDownFields,
          ...event,
          value: siteNameSelectedValues,
        })
      );
    }
  };

  const onProductSelection = (event) => {
    if (event.operation === "delete") {
      setProductSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(
        event,
        productSelectedValues
      );
      setProductSelectedValues(
        getMultiSelectTreeValue(filters?.products, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, tankSelectedValues);
      setTankSelectedValues(
        getMultiSelectTreeValue(filters?.tankGroup, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, regionSelectedValues);
      setRegionSelectedValues(
        getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const fetchMasterData = async () => {
    try {
      setLeaksData([]);
      setShowError(false);
      setFilterDataLoading(true);

      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentRegion,
      });
      setFilterDataLoading(false);
      if (response) {
        setMasterData([...response]);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the data.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
      setError("Server error. Unable to fetch data.");
      setFilterDataLoading(false);
      setError("");
    }
  };

  const onShipToAction = (props) => {
    const dataItem = props.dataItem;
    if (dataItem) {
      const selectedDate = {
        start: new Date(dataItem.requestDate),
        end: new Date(dataItem.requestDate),
      };
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: dataItem?.shipTo,
          selectedDates: selectedDate,
        };
      });
    }

    if (selectedTab === LeakTabOptions.TANK_LEAKS) {
      setSelectedTab(LeakTabOptions.DAILY_REPORT);
    }

    if (selectedTab === LeakTabOptions.DAILY_REPORT) {
      setSelectedTab(LeakTabOptions.MINS_REPORT);
    }
  };

  const fetchThresholdData = async (tabLoaded = false, refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const siteId = filter?.allSiteIds?.find(
      (site) => site?.globalSiteId === filter?.selectedSiteId
    )?.localSiteId;
    const selectedTankIds = filter?.selectedTanks?.map((item) => item?.id);
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    const selectedProducts = filter?.selectedProducts?.map(
      (product) => product?.text
    );
    const filteredProducts =
      selectedProducts?.length > 0 ? selectedProducts : ["All"];
    const filteredTankGroups =
      selectedTankIds?.length > 0 ? selectedTankIds : ["All"];

    const startDate = fromDate && getFormattedDate(fromDate);
    const endDate = toDate && getFormattedDate(toDate);
    const region = filter?.selectedRegions?.[0]?.id;

    let payload = {
      countryCode: isBFX(currentRegion) ? region : currentRegion,
      leakStartDate: startDate,
      leakEndDate: endDate,
    };

    let valuesSelected = fromDate && toDate;

    if (selectedTab !== LeakTabOptions.TANK_LEAKS) {
      payload = {
        countryCode: isBFX(currentRegion) ? region : currentRegion,
        startDate: startDate,
        endDate: endDate,
        shipTo: filter?.selectedSiteId,
        product: filteredProducts,
        isDaily: selectedTab === LeakTabOptions.DAILY_REPORT,
      };

      if (!isBFX(currentRegion)) {
        payload = {
          ...payload,
          tankGroup: filteredTankGroups,
        };
      }

      valuesSelected = valuesSelected && siteId;
    }

    if (valuesSelected) {
      setTabChanged(false);
      setShowError(false);
      setSubmitLoading(true);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        let response = null;
        switch (selectedTab) {
          case LeakTabOptions.TANK_LEAKS:
            response = await reportsService.fetchLeaksData(payload);
            break;

          case LeakTabOptions.DAILY_REPORT:
            response = await reportsService.fetchReconciliationResults(payload);
            break;

          case LeakTabOptions.MINS_REPORT:
            response = await reportsService.fetchReconciliationResults(payload);
            break;
          default:
            response = [];
        }

        setSubmitLoading(false);
        setLeaksData(response);
        setResetIndex(!resetIndex);
      } catch (error) {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Server error. Unable to fetch data.",
          })
        );
        setError("Server error. Unable to fetch data.");
        setSubmitLoading(false);
        setError("");
      }
    } else {
      if (!tabLoaded) {
        setShowError(true);
      }
    }
  };

  useEffect(() => {
    if (selectedTab === LeakTabOptions.MINS_REPORT) {
      setFilters((prev) => {
        const selectedDate = report?.filters?.RequestDate
          ? {
              start: report.filters.RequestDate?.startDate,
              end: report.filters.RequestDate?.endDate,
            }
          : {
              start: prev.selectedDates.start,
              end: prev.selectedDates.start,
            };
        return {
          ...prev,
          selectedDates: selectedDate,
        };
      });
    }
    setTabChanged(true);
    setLeaksData([]);
    if (selectedTab !== LeakTabOptions.MINS_REPORT) {
      setShowError(false);
      if (!dateUpdated) {
        fetchThresholdData(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === LeakTabOptions.MINS_REPORT && datePickerClosed) {
      fetchThresholdData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedDates]);

  const StartDateInput = (props) => {
    const dateClassName =
      props.value !== null || !showError ? "date" : "empty-date";
    return (
      <div className="dateContainer">
        <label htmlFor="startDate">Start Date</label>
        <DateInput
          {...props}
          label={undefined}
          className={`${dateClassName}`}
        />
      </div>
    );
  };

  const EndDateInput = (props) => {
    const dateClassName =
      props.value !== null || !showError ? "date" : "empty-date";
    return (
      <div className="dateContainer">
        <label htmlFor="endDate">End Date</label>
        <DateInput
          {...props}
          label={undefined}
          className={`${dateClassName}`}
        />
      </div>
    );
  };

  const handleDatePickerClose = () => {
    setDatePickerClosed(true);
  };

  const handleReportLink = () => {
    const products = filters.selectedProducts?.map((item) => item.id);
    const tanks = filters.selectedTanks?.map((item) => item.id);

    const { userScreenList } = store.getState();
    const module = userScreenList?.find(
      (item) => item.id === SCREEN_IDS.LEAK_DETECTION
    );
    const subModule = module?.children?.find(
      (item) => item.id === SCREEN_IDS.RECONCILIATION_REPORTS
    );
    let report = subModule?.children?.find(
      (item) => item.id === SCREEN_IDS.FIFTEEN_MINS_RECONCILIATION_REPORT
    );

    let selectedReport = {
      moduleId: subModule.parentId,
      submoduleId: report.parentId,
      pageId: report.id,
      pageName: report.pageName,
      reportId: report.reportId,
      title: report.title,
      tableName: report.tableName,
      filters: {
        GlobalSiteId: [filters.selectedSiteId],
        Product: products,
        TankGroupId: tanks,
        RequestDate: {
          startDate: filters.selectedDates?.start,
          endDate: filters.selectedDates?.end,
        },
      },
    };
    if (selectedTab === LeakTabOptions.DAILY_REPORT) {
      let report = subModule?.children?.find(
        (item) => item.id === SCREEN_IDS.DAILY_RECONCILIATION_REPORT
      );
      selectedReport = {
        moduleId: subModule.parentId,
        submoduleId: report.parentId,
        pageId: report.id,
        pageName: report.pageName,
        reportId: report.reportId,
        title: report.title,
        tableName: report.tableName,
        filters: {
          GlobalSiteId: [filters.selectedSiteId],
          Product: products,
          TankGroupId: tanks,
          RequestDate: {
            startDate: filters.selectedDates?.start,
            endDate: filters.selectedDates?.end,
          },
        },
      };
    }
    localStorage.setItem("reportsData", JSON.stringify(selectedReport));
    store.dispatch(setSelectedReport(selectedReport));
  };

  const showHeaders = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <div className="filter-date">
            <DateRangePicker
              defaultValue={filters.selectedDates}
              value={filters.selectedDates}
              startDateInput={StartDateInput}
              endDateInput={EndDateInput}
              onChange={onDateSelection}
              onClose={handleDatePickerClose}
              onOpen={() => {
                setDatePickerClosed(false);
              }}
              max={getYesterdayDate()}
            />
          </div>
          {isBFX(currentRegion) && (
            <span className="filter">
              <label htmlFor="region">Country</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                filterable={false}
                showError={showError}
                customStyle={{
                  minWidth: "5vw",
                  minHeight: "2vh",
                }}
                maxSelection={1}
              />
            </span>
          )}

          {selectedTab !== LeakTabOptions.TANK_LEAKS && (
            <div className="filters-container">
              <span className="filter">
                <label htmlFor="shipTo">Ship To</label>
                <MultiSelectionDropDown
                  data={filters.allSiteIds}
                  values={shipToSelectedValues}
                  onChange={onShipToSelection}
                  filterable={true}
                  showError={showError}
                  customStyle={{ minWidth: "7vw", minHeight: "3vh" }}
                  maxSelection={1}
                />
              </span>
              <span className="filter">
                <label htmlFor="shipTo">Site Name</label>
                <MultiSelectionDropDown
                  data={filters.allSiteNames}
                  values={siteNameSelectedValues}
                  onChange={onSiteNameSelection}
                  filterable={true}
                  showError={showError}
                  customStyle={{ minWidth: "10vw", minHeight: "3vh" }}
                  maxSelection={1}
                />
              </span>
              <span className="filter">
                <label htmlFor="region">Product</label>
                <MultiSelectionDropDown
                  data={filters.products}
                  values={productSelectedValues}
                  onChange={onProductSelection}
                  filterable={false}
                  placeholder="Select ..."
                  customStyle={{ minWidth: "10vw", minHeight: "3vh" }}
                />
              </span>
              {!isBFX(currentRegion) && (
                <span className="filter">
                  <label htmlFor="region">Tank Group</label>
                  <MultiSelectionDropDown
                    data={filters.tankGroup}
                    values={tankSelectedValues}
                    onChange={onTankSelection}
                    filterable={false}
                    placeholder="Select ..."
                    customStyle={{ minWidth: "7vw", minHeight: "3vh" }}
                  />
                </span>
              )}
            </div>
          )}
        </div>
        <span className="filter-button">
          <Button
            className="get-data-btn"
            onClick={() => {
              fetchThresholdData();
              let selectedReport = {
                ...report,
                filters: null,
              };
              localStorage.setItem(
                "reportsData",
                JSON.stringify(selectedReport)
              );
              store.dispatch(setSelectedReport(selectedReport));
            }}
            disabled={filterDataLoading}
          >
            {filterDataLoading ? "Fetching..." : "Get data"}
          </Button>
        </span>
        <span className="filter-button">
          <Button
            className="refresh-btn"
            onClick={() => {
              fetchThresholdData(false, true);
            }}
            disabled={!appliedFilters}
          >
            Refresh
          </Button>
        </span>
        {selectedTab !== LeakTabOptions.TANK_LEAKS && (
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={handleReportLink}
              disabled={filterDataLoading}
            >
              More...
            </Button>
          </span>
        )}
      </div>
    );
  };

  const showGridToolBar = () => {
    return <div className="gridToolbar">{showHeaders()}</div>;
  };

  return (
    <div className="tank-leaks-screen-container data-grid">
      <div className="row ">
        {/* <h4 className="title">Tank Threshold Results</h4> */}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {leakTabComponent()}
          {showGridToolBar()}

          <AppDataGrid
            resetIndex={resetIndex}
            setResetIndex={setResetIndex}
            take={DEFAULT_PAGE_SIZE}
            style={{ height: "61vh" }}
            pageable={true}
            filterable={true}
            data={leaksData}
            columnsToShow={colsToShow(
              leaksData,
              selectedTab,
              currentRegion,
              onShipToAction
            )}
            sortable={true}
            exportFileName="Tank Leaks"
            // rowHeight={30}
            // total={leaksData?.total}
            // scrollable={"virtual"}
            // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
          />
          {submitLoading && (
            <LoadingPanel message="Fetching the leaks data please wait." />
          )}
          {filterDataLoading && (
            <LoadingPanel message="Fetching the configurations please wait.." />
          )}
        </div>
      </div>
    </div>
  );
};

export { TankLeaksScreen };
