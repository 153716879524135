import React from "react";
import { OutOfToleranceSummaryScreen } from "./out-of-tolerance-summary/out-of-tolerance-summary-screen";

const OOTSummaryContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <OutOfToleranceSummaryScreen />
        </div>
      </div>
    </>
  );
};

export { OOTSummaryContainer };
