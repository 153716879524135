import React, { useEffect, useRef, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Loader } from "@progress/kendo-react-indicators";
import "./out-of-tolerance-trend-analysis.scss";
import { GridToolbar } from "@progress/kendo-react-grid";
import {
  TrendViewMode,
  colsToShow,
} from "./out-of-tolerance-trend-analysis-utils";
import {
  getAllSelectedOptions,
  getLastDates,
  getMaterials,
  getFilterRegions,
  getShipTos,
  getTanks,
  getYesOrNoValues,
} from "../../reportUtils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { reportsService } from "../../../../../services/reports";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../../state-management/store";
import { triggerNotification } from "../../../../../state-management/actions";
import { SiteInfoScreen } from "./out-of-tolerance-site-info";
import { DEFAULT_PAGE_SIZE } from "../common-utils";
import { DatePicker } from "@progress/kendo-react-all";
import {
  getDaysBeforeDate,
  getFormattedDate,
} from "../../../../../services/utils";
import { Switch } from "@progress/kendo-react-inputs";

const OutOfToleranceTrendAnalysisV2 = (props) => {
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const user = useSelector((state) => state?.user);
  const [countValues, setCountValues] = useState({
    gainCount: 0,
    lossCount: 0,
    fiveCumVariance: 0,
    fiveCumThruPut: 0,
    thirtyCumVariance: 0,
    thirtyCumThruPut: 0,
  });

  const [historicalViewEnabled, setHistoricalViewEnabled] = useState(false);
  const [trendAnalysisData, setTrendAnalysisData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [commentsOptions, setCommentsOptions] = useState([]);
  const [masterDataLoading, setMasterDataLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [blankCount, setBlankCount] = useState(0);
  const [missingCommentsCount, setMissingCommentsCount] = useState(0);
  const [missingOutOfToleranceCount, setMissingOutOfToleranceCount] =
    useState(0);
  const exportGridRef = useRef(null);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const defaultRegion = useSelector((state) => state?.selectedRegion);
  const [currentRegion, setCurrentRegion] = useState(defaultRegion);
  const [resetIndex, setResetIndex] = useState(false);
  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setCurrentRegion(selectedRegion);
  });

  const [filters, setFilters] = useState({
    selectedDate: getLastDates(
      1,
      currentRegion === "DE" ? 2 : 1,
      currentRegion
    ),
    selectedDateVal: null,
    selectedDates: {
      start: getDaysBeforeDate(currentRegion === "DE" ? 10 : 30),
      end: getDaysBeforeDate(currentRegion === "DE" ? 1 : 1),
    },
    dates: getLastDates(4, currentRegion === "DE" ? 2 : 1, currentRegion),
    selectedRegions: [],
    regions: getFilterRegions(filterData),
    selectedShipTo: [],
    shipTos: [],
    selectedTanks: [],
    tanks: getTanks(filterData),
    selectedMaterial: [],
    materials: getMaterials(filterData),
    selectedComments: [],
    comments: [],
    selectedDay250: [getYesOrNoValues()[0]],
    oneDayGain: getYesOrNoValues(),
    selectedOneDayMins: [getYesOrNoValues()[0]],
    oneDayLoss: getYesOrNoValues(),
    selectedFiveDCumulVar: [getYesOrNoValues()[0]],
    isFiveDaysCumulative: getYesOrNoValues(),
    selectedFiveDCumulTh: [getYesOrNoValues()[0]],
    isFiveDaysCumulativePercent: getYesOrNoValues(),
    selectedThirtyDaysCumul: [getYesOrNoValues()[0]],
    isThirtyDaysCumulative: getYesOrNoValues(),
    selectedThirtyDaysCumulPercentage: [getYesOrNoValues()[0]],
    isThirtyDaysCumulativePercent: getYesOrNoValues(),
  });

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedMode, setSelectedMode] = useState(TrendViewMode.MATERIAL);
  const [firstLoad, setFirstLoad] = useState(false);
  const [reportConfig, setReportConfig] = useState();
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  useEffect(() => {
    setTrendAnalysisData([]);
  }, [historicalViewEnabled]);

  useEffect(() => {
    setBlankCount(0);
    setMissingCommentsCount(0);
    setMissingOutOfToleranceCount(0);
  }, [fetchingData]);

  const fetchReportData = async () => {
    try {
      const response = await reportsService.getRestoreData();
      setFetchingData(false);
      if (response) {
        const ootReportConfig = response?.find(
          (item) => item?.reportCode === "oot-daily"
        );
        setReportConfig(ootReportConfig);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the data.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
      setFetchingData(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const startDate = new Date(reportConfig?.restoreStartDate);
    const endDate = new Date(reportConfig?.restoreEndDate);

    if (startDate && endDate) {
      setFilters((prev) => {
        return {
          ...prev,
          selectedDates: {
            start: startDate,
            end: endDate,
          },
          selectedDateVal: endDate,
        };
      });
    }
  }, [reportConfig]);

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const [dateSelectedValues, setDateSelectedValues] = useState(
    filters.selectedDate
  );

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters.selectedTanks
  );

  const [materialSelectedValues, setMaterialSelectedValues] = useState(
    filters.selectedMaterial
  );

  const [commentSelectedValues, setCommentSelectedValues] = useState(
    filters.selectedComments
  );

  const [day250SelectedValues, setDay250SelectedValues] = useState(
    filters.selectedDay250
  );

  const [oneDayMinsSelectedValues, setOneDayMinsSelectedValues] = useState(
    filters.selectedOneDayMins
  );

  const [fiveDCumVarSelectedValues, setFiveDCumVarSelectedValues] = useState(
    filters.selectedFiveDCumulVar
  );

  const [fiveDCumThSelectedValues, setFiveDCumThSelectedValues] = useState(
    filters.selectedFiveDCumulTh
  );

  const [thirtyDaysCumulSelectedValues, setThirtyDaysCumulSelectedValues] =
    useState(filters.selectedThirtyDaysCumul);

  const [
    thirtyDaysCumulPercentageSelectedValues,
    setThirtyDaysCumulPercentageSelectedValues,
  ] = useState(filters.selectedThirtyDaysCumulPercentage);

  const fetchTrendAnalysisReport = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;

    setFetchingData(true);
    setChanged(false);
    setChangedItems([]);
    const currentRegions = filter.selectedRegions?.map(
      (region) => region?.text
    );

    const selectedCountryCodes = filter.selectedRegions?.map(
      (region) => region?.countryCode
    );

    let payload = {
      country: selectedCountryCodes?.[0],
      countryCode: currentRegion === "DE" ? "dach" : currentRegion,
      isHistoricalView: historicalViewEnabled,
      isMaterialView: selectedMode === TrendViewMode.MATERIAL,
      date: filter.selectedDate?.[0]?.text,
      regions: currentRegions,
      shipTos: filter.selectedShipTo?.map((shipTo) => shipTo.text),
      tankIds:
        selectedMode === TrendViewMode.MATERIAL
          ? []
          : filter.selectedTanks?.map((tank) => tank.text),
      materialNumbers: filter.selectedMaterial?.map(
        (material) => material.text
      ),
      comment: filter.selectedComments?.map((comment) => comment.text),
      oneDayPlus: filter.selectedDay250?.[0]?.text,
      oneDayMinus: filter.selectedOneDayMins?.[0]?.text,
      fiveDaysCumul: filter.selectedFiveDCumulVar?.[0]?.text,
      fiveDaysCumulPercentage: filter.selectedFiveDCumulTh?.[0]?.text,
      thirtyDaysCumul: filter.selectedThirtyDaysCumul?.[0]?.text,
      thirtyDaysCumulPercentage:
        filter?.selectedThirtyDaysCumulPercentage?.[0]?.text,
    };

    if (historicalViewEnabled) {
      payload = {
        ...payload,
        date: getFormattedDate(filters?.selectedDateVal),
        // startDate: getFormattedDate(filters?.selectedDates?.start),
        // endDate: getFormattedDate(filters?.selectedDates?.end),
      };
    }

    try {
      let response = await reportsService.fetchToleranceTrendDataV2(
        payload,
        historicalViewEnabled
      );
      const countryCode =
        payload?.country === "all" ? currentRegion : payload?.country;

      setFetchingData(false);
      if (response) {
        if (!refresh) {
          setAppliedFilters(filter);
        }
        response.sort((a, b) => a.materialNo - b.materialNo);
        response.sort((a, b) => a.sloc - b.sloc);
        response.sort((a, b) => a.shipTo - b.shipTo);

        let count = {};
        let missingCount = {};
        let missingCommentsCount = 0;
        const trendData = response?.map((dataItem) => {
          let disabled = false;
          if (!dataItem.comment) {
            missingCommentsCount += 1;
          }
          const datesArray = Object.fromEntries(
            dataItem?.dates.map((dateObj, index) => {
              let value = dateObj.value;
              if (index === 0) {
                disabled = true;
              }
              if (!dateObj.value && dateObj.value !== 0) {
                value = "";
                const current = count?.[`day${index}`]
                  ? count?.[`day${index}`]
                  : 0;
                count[`day${index}`] = current + 1;
              }
              if (dateObj?.missingOutOfTolerance) {
                const current = missingCount?.[`day${index}`]
                  ? missingCount?.[`day${index}`]
                  : 0;
                missingCount[`day${index}`] = current + 1;
              }
              value = value ? Math.round(value) : value;
              return [[`day${index}`], value];
            })
          );
          return {
            ...dataItem,
            ...datesArray,
            country: dataItem?.country || countryCode,
            fiveDaysTotal: Math.round(dataItem?.fiveDaysTotal),
            tenDaysTotal: Math.round(dataItem?.tenDaysTotal),
            thirtyDaysTotal: Math.round(dataItem?.thirtyDaysTotal),
            disabled,
          };
        });
        setBlankCount(count);
        setMissingCommentsCount(missingCommentsCount);
        setMissingOutOfToleranceCount(missingCount);
        setOriginalData(trendData ? trendData : []);
        setTrendAnalysisData(trendData ? trendData : []);

        let counterItems = {
          gainCount: 0,
          lossCount: 0,
          fiveCumVariance: 0,
          fiveCumThruPut: 0,
          thirtyCumVariance: 0,
          thirtyCumThruPut: 0,
        };

        trendData?.forEach((item) => {
          if (item?.oneDayGain === "Y") {
            counterItems.gainCount += 1;
          }
          if (item?.oneDayLoss === "Y") {
            counterItems.lossCount += 1;
          }
          if (item?.isFiveDaysCumulative === "Y") {
            counterItems.fiveCumVariance += 1;
          }
          if (item?.isFiveDaysCumulativePercent === "Y") {
            counterItems.fiveCumThruPut += 1;
          }
          if (item?.isThirtyDaysCumulative === "Y") {
            counterItems.thirtyCumVariance += 1;
          }
          if (item?.isThirtyDaysCumulativePercent === "Y") {
            counterItems.thirtyCumThruPut += 1;
          }
        });
        setCountValues(counterItems);
        setFetchingData(false);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the data.",
          })
        );
        setFetchingData(false);
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
      setFetchingData(false);
    }
  };

  const hasEmptyValues = () => {
    let emptySelection =
      filters?.selectedDate?.length === 0 ||
      filters?.selectedRegions?.length === 0 ||
      filters?.selectedShipTo?.length === 0 ||
      filters?.selectedMaterial?.length === 0 ||
      filters?.selectedComments?.length === 0 ||
      filters?.selectedDay250?.length === 0 ||
      filters?.selectedOneDayMins?.length === 0 ||
      filters?.selectedFiveDCumulVar?.length === 0 ||
      filters?.selectedFiveDCumulTh?.length === 0 ||
      filters?.selectedThirtyDaysCumul?.length === 0 ||
      filters?.selectedThirtyDaysCumulPercentage?.length === 0;

    return emptySelection;
  };

  const loadData = (modeChanged = false) => {
    let emptySelection = hasEmptyValues();

    emptySelection =
      emptySelection ||
      (selectedMode === 1 && filters?.selectedTanks?.length === 0);
    if (!emptySelection && (modeChanged || firstLoad)) {
      fetchTrendAnalysisReport();
      setFirstLoad(false);
    }
  };

  const fetchMasterData = async () => {
    setMasterDataLoading(true);
    const masterData = await reportsService.fetchToleranceTrendMasterData({
      country: currentRegion,
    });
    setFilterData(masterData ? masterData : []);
    setMasterDataLoading(false);
  };
  const fetchCommentsMasterData = async () => {
    setMasterDataLoading(true);
    const response = await reportsService.fetchCommentsMasterData({
      country: currentRegion,
    });
    const options = response?.map((option) => option?.categoryText);
    setCommentsOptions(options ? options : []);
    setMasterDataLoading(false);
    setFetchingData(true);
  };

  useEffect(() => {
    const selectedDate = getLastDates(
      1,
      currentRegion === "DE" ? 2 : 1,
      currentRegion
    );
    setFilters((prev) => {
      return {
        ...prev,
        selectedDate: selectedDate,
        dates: getLastDates(4, currentRegion === "DE" ? 2 : 1, currentRegion),
        selectedRegions: [],
      };
    });
    fetchMasterData();
    fetchCommentsMasterData();
    setChanged(false);
    setTrendAnalysisData([]);
    setBlankCount(0);
    setMissingCommentsCount(0);
    setMissingOutOfToleranceCount(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  useEffect(() => {
    loadData(true);
    setChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMode]);

  useEffect(() => {
    setTrendAnalysisData([]);
    setOriginalData([]);
  }, [selectedMode]);

  useEffect(() => {
    let comments = commentsOptions?.map((option) => ({
      id: option,
      text: option,
    }));
    comments = [{ id: "all", text: "All" }, ...comments];
    const selectedComment = comments?.[0];
    setFilters({
      ...filters,
      comments: comments,
      selectedComments: selectedComment ? [selectedComment] : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsOptions]);

  useEffect(() => {
    const dates = getLastDates(
      4,
      currentRegion === "DE" ? 2 : 1,
      currentRegion
    );
    const selectedDate = getLastDates(
      1,
      currentRegion === "DE" ? 2 : 1,
      currentRegion
    );
    const regions = getFilterRegions(filterData);
    const preferenceRegion = user?.UserPreference?.Region;
    let selectedRegions = regions?.find(
      (region) => region?.id?.toUpperCase() === preferenceRegion?.toUpperCase()
    );
    if (selectedRegions) {
      selectedRegions = [selectedRegions];
    } else {
      selectedRegions = regions?.length > 1 ? [regions?.[1]] : [];
    }
    setFilters({
      ...filters,
      regions,
      // selectedRegions,
      dates,
      selectedDate,
    });
    setFetchingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    setDateSelectedValues(filters.selectedDate);
    setRegionSelectedValues(filters.selectedRegions);
    const shipTos =
      filters.selectedRegions?.length > 0
        ? getShipTos(filterData, filters.selectedRegions)
        : [];
    const selectedShipTo =
      filters.selectedRegions?.length > 0 ? [shipTos?.[0]] : [];

    setFilters({
      ...filters,
      shipTos,
      selectedShipTo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    setShipToSelectedValues(filters.selectedShipTo);
    const tanks =
      filters.selectedShipTo?.length > 0 ? getTanks(filterData, filters) : [];
    const selectedTanks = tanks?.length > 0 ? [tanks?.[0]] : [];
    const materials =
      filters.selectedShipTo?.length > 0
        ? getMaterials(filterData, filters)
        : [];
    const selectedMaterial = materials?.length > 0 ? [materials[0]] : [];

    setFilters({
      ...filters,
      materials,
      selectedMaterial,
      tanks,
      selectedTanks,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedShipTo, filters.shipTos]);

  useEffect(() => {
    setMaterialSelectedValues(filters.selectedMaterial);
  }, [filters.selectedMaterial]);

  useEffect(() => {
    setCommentSelectedValues(filters.selectedComments);
  }, [filters.selectedComments]);

  useEffect(() => {
    setTankSelectedValues(filters.selectedTanks);
  }, [filters.selectedTanks]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDate: dateSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedRegions: regionSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedShipTo: shipToSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedTanks: tankSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedMaterial: materialSelectedValues,
    }));
    // loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedComments: commentSelectedValues,
    }));
    // loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDay250: day250SelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day250SelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedOneDayMins: oneDayMinsSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDayMinsSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedFiveDCumulVar: fiveDCumVarSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiveDCumVarSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedFiveDCumulTh: fiveDCumThSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiveDCumThSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedThirtyDaysCumul: thirtyDaysCumulSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyDaysCumulSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedThirtyDaysCumulPercentage:
        thirtyDaysCumulPercentageSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyDaysCumulPercentageSelectedValues]);

  const handleDataChange = (e) => {
    const changeItem = e?.dataItem;
    const updatedItems = trendAnalysisData?.map((item, index) => {
      return index === changeItem.id ? e?.dataItem : item;
    });
    setTrendAnalysisData(updatedItems);

    const actualItem = originalData?.find(
      (item) =>
        item.shipTo === changeItem.shipTo &&
        item.tank === changeItem.tank &&
        item.materialNo === changeItem.materialNo
    );

    setChanged(actualItem.comment !== changeItem.comment);
    if (actualItem.comment !== changeItem.comment) {
      setChangedItems([...changedItems, changeItem]);
    } else {
      setChangedItems(
        changedItems?.filter(
          !(
            actualItem.shipTo === changeItem.shipTo &&
            actualItem.tank === changeItem.tank &&
            actualItem.materialNo === changeItem.materialNo
          )
        )
      );
    }
  };

  const handleRowClick = (e) => {
    const changeItem = e?.dataItem;
    const updatedItems = trendAnalysisData?.map((item, index) => {
      return index === changeItem.id
        ? {
            ...e?.dataItem,
            selected: true,
          }
        : {
            ...item,
            selected: false,
          };
    });
    setTrendAnalysisData(updatedItems);
  };

  const handleRowRender = (event, props) => {
    const rowData = props.dataItem;
    const backgroundColor = rowData?.selected ? "#eee" : "#fff";
    event.props.style.backgroundColor = backgroundColor;
    return event;
  };

  const createPayload = () => {
    return changedItems?.map((item) => ({
      CountryCode: currentRegion,
      Comment: item.comment,
      SiteId: item.shipTo,
      MaterialNo: item.materialNo,
      RequestDate: filters.selectedDate?.[0]?.date,
      UserEmail: store.getState().user.UserEmail,
    }));
  };

  const checkError = (response) => {
    const responseErrors = response?.filter((data) => data.StatusCode !== 200);
    responseErrors?.forEach((data) => {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: data?.Value,
        })
      );
    });
    if (responseErrors?.length === 0) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: "Comments saved successfully.",
        })
      );
      setChanged(false);
      setChangedItems([]);
      fetchTrendAnalysisReport();
    }
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.saveOOTAReportComment(payload);
    if (response?.status >= 200 && response?.status < 300) {
      checkError(response?.data);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to submit the comments.",
        })
      );
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setTrendAnalysisData(originalData);
    setChanged(false);
    setChangedItems([]);
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };

  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const headerButtons = () => {
    const saveColor = changed && "#0092a5";
    const cancelColor = changed && "#fbce07";
    const borderWidth = changed && "0.15vw";
    return (
      <div className="buttonsContainer">
        <div className="buttonsLeftContainer">
          <Button
            className="get-data-btn"
            onClick={fetchData}
            disabled={fetchingData}
          >
            {fetchingData ? "Fetching..." : "Get data"}
          </Button>
          <Button
            className="refresh-btn"
            onClick={() => {
              fetchTrendAnalysisReport(true);
            }}
            disabled={!appliedFilters}
          >
            Refresh
          </Button>
          {selectedMode === TrendViewMode.MATERIAL && (
            <Button
              className="exportButton"
              onClick={exportData}
              disabled={exporting || !trendAnalysisData?.length}
            >
              {exporting ? "Exporting..." : "Export"}
            </Button>
          )}
        </div>
        {selectedMode === TrendViewMode.MATERIAL && (
          <div className="submitButtonContainer">
            <button
              title="Save"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={saveChanges}
              style={{ borderColor: saveColor, borderWidth: borderWidth }}
              disabled={!changed}
            >
              Save
            </button>
            <button
              title="Cancel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
              onClick={cancelChanges}
              style={{ borderColor: cancelColor, borderWidth: borderWidth }}
              disabled={!changed}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  };

  const getCumColumnWidth = () => {
    let width = selectedMode === TrendViewMode.MATERIAL ? 3.4 : 3.2;
    width = currentRegion !== "DE" ? width * 0.85 : width;
    return `${width}vw`;
  };

  const getCumFlagWidth = () => {
    let width = selectedMode === TrendViewMode.MATERIAL ? 6.8 : 6.4;
    width = currentRegion !== "DE" ? width * 0.85 : width;
    return `${width}vw`;
  };

  const headerColumns = () => (
    <div className="rightContainer">
      <div className="countContainer">
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.gainCount}
        </div>
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.lossCount}
        </div>
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.fiveCumVariance}
        </div>
        <div
          className="counter"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          {countValues.fiveCumThruPut}
        </div>
        {currentRegion !== "DE" && (
          <span className="countContainer">
            <div
              className="counter"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              {countValues.thirtyCumVariance}
            </div>
            <div
              className="counter"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              {countValues.thirtyCumThruPut}
            </div>
          </span>
        )}
      </div>
      <div className="labelContainer">
        <span
          className="labelFlag"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          1-day Gain flag?
        </span>
        <span
          className="labelFlag"
          style={{
            width: getCumColumnWidth(),
          }}
        >
          1-day Loss flag?
        </span>
        <span className="cumulativeContainer">
          <span
            className="cumulativeFlag"
            style={{
              width: getCumFlagWidth(),
            }}
          >
            5-Day Cumulative?
          </span>
          <span className="labelContainer">
            <span
              className="cumulativeVar"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              +/- 250 Cum. Var
            </span>
            <span
              className="cumulativeVar"
              style={{
                width: getCumColumnWidth(),
              }}
            >
              {currentRegion !== "DE" ? "2% of Throughput" : "3% of Throughput"}
            </span>
          </span>
        </span>
        {currentRegion !== "DE" && (
          <span className="cumulativeContainer">
            <span
              className="cumulativeFlag"
              style={{
                width: getCumFlagWidth(),
              }}
            >
              30-Day Cumulative?
            </span>
            <span className="labelContainer">
              <span
                className="cumulativeVar"
                style={{
                  width: getCumColumnWidth(),
                }}
              >
                +/- 750 Cum. Var
              </span>
              <span
                className="cumulativeVar"
                style={{
                  width: getCumColumnWidth(),
                }}
              >
                2% of Throughput
              </span>
            </span>
          </span>
        )}
      </div>
    </div>
  );

  const onDateSelection = (event) => {
    if (event.operation === "delete") {
      setDateSelectedValues([]);
    } else {
      setDateSelectedValues(
        getMultiSelectTreeValue(filters?.dates, {
          ...multiSelectionDropDownFields,
          ...event,
          value: dateSelectedValues,
        })
      );
    }
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, regionSelectedValues);
      setRegionSelectedValues(
        getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onShipToSelection = (event) => {
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, shipToSelectedValues);
      setShipToSelectedValues(
        getMultiSelectTreeValue(filters?.shipTos, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, tankSelectedValues);
      setTankSelectedValues(
        getMultiSelectTreeValue(filters?.tanks, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onMaterialSelection = (event) => {
    if (event.operation === "delete") {
      setMaterialSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(
        event,
        materialSelectedValues
      );
      setMaterialSelectedValues(
        getMultiSelectTreeValue(filters?.materials, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onCommentSelection = (event) => {
    if (event.operation === "delete") {
      setCommentSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(
        event,
        commentSelectedValues
      );
      setCommentSelectedValues(
        getMultiSelectTreeValue(filters?.comments, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onDay250Selection = (event) => {
    if (event.operation === "delete") {
      setDay250SelectedValues([]);
    } else {
      setDay250SelectedValues(
        getMultiSelectTreeValue(filters?.oneDayGain, {
          ...multiSelectionDropDownFields,
          ...event,
          value: day250SelectedValues,
        })
      );
    }
  };

  const onOneDayMinsSelection = (event) => {
    if (event.operation === "delete") {
      setOneDayMinsSelectedValues([]);
    } else {
      setOneDayMinsSelectedValues(
        getMultiSelectTreeValue(filters?.oneDayLoss, {
          ...multiSelectionDropDownFields,
          ...event,
          value: oneDayMinsSelectedValues,
        })
      );
    }
  };

  const onFiveDCumVarSelection = (event) => {
    if (event.operation === "delete") {
      setFiveDCumVarSelectedValues([]);
    } else {
      setFiveDCumVarSelectedValues(
        getMultiSelectTreeValue(filters?.isFiveDaysCumulative, {
          ...multiSelectionDropDownFields,
          ...event,
          value: fiveDCumVarSelectedValues,
        })
      );
    }
  };

  const onFiveDCumThSelection = (event) => {
    if (event.operation === "delete") {
      setFiveDCumThSelectedValues([]);
    } else {
      setFiveDCumThSelectedValues(
        getMultiSelectTreeValue(filters?.isFiveDaysCumulativePercent, {
          ...multiSelectionDropDownFields,
          ...event,
          value: fiveDCumThSelectedValues,
        })
      );
    }
  };

  const onThirtyDayCumThSelection = (event) => {
    if (event.operation === "delete") {
      setThirtyDaysCumulSelectedValues([]);
    } else {
      setThirtyDaysCumulSelectedValues(
        getMultiSelectTreeValue(filters?.isThirtyDaysCumulative, {
          ...multiSelectionDropDownFields,
          ...event,
          value: thirtyDaysCumulSelectedValues,
        })
      );
    }
  };

  const onThirtyDayCumPercentageSelection = (event) => {
    if (event.operation === "delete") {
      setThirtyDaysCumulPercentageSelectedValues([]);
    } else {
      setThirtyDaysCumulPercentageSelectedValues(
        getMultiSelectTreeValue(filters?.isThirtyDaysCumulativePercent, {
          ...multiSelectionDropDownFields,
          ...event,
          value: thirtyDaysCumulPercentageSelectedValues,
        })
      );
    }
  };

  const fetchData = () => {
    let canShowError = hasEmptyValues();

    canShowError =
      canShowError ||
      (selectedMode === 1 && filters?.selectedTanks?.length === 0);

    setShowError(canShowError);
    if (!canShowError) {
      fetchTrendAnalysisReport();
      setResetIndex(true);
    }
  };

  const onDateChange = (e) => {
    const date = new Date(e?.value);
    setFilters((prev) => {
      return {
        ...prev,
        selectedDateVal: date,
      };
    });
  };

  const headerFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          {historicalViewEnabled ? (
            // <div className="filter-date">
            //   /* <DateRangePicker
            //     defaultValue={filters.selectedDates}
            //     value={filters.selectedDates}
            //     startDateInput={StartDateInput}
            //     endDateInput={EndDateInput}
            //     onChange={onDateRangeSelection}
            //     onClose={handleDatePickerClose}
            //     onOpen={() => {}}
            //     max={new Date(reportConfig?.restoreEndDate)}
            //     min={new Date(reportConfig?.restoreStartDate)}
            //   /> */
            // </div>
            <span className="filter-date">
              <label htmlFor="region">Date</label>
              <DatePicker
                format={"dd-MMM-yyyy"}
                value={filters?.selectedDateVal}
                onChange={onDateChange}
                max={new Date(reportConfig?.restoreEndDate)}
                min={new Date(reportConfig?.restoreStartDate)}
              />
            </span>
          ) : (
            <span className="filter">
              <label htmlFor="Date">Date</label>
              <MultiSelectionDropDown
                data={filters.dates}
                values={dateSelectedValues}
                onChange={onDateSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ minWidth: "7vw", minHeight: "3vh" }}
              />
            </span>
          )}
          <span className="filter">
            <label htmlFor="region">
              {currentRegion === "DE" ? "Region" : "Country"}
            </label>
            <MultiSelectionDropDown
              data={filters.regions}
              values={regionSelectedValues}
              onChange={onRegionSelection}
              filterable={false}
              showError={showError}
              customStyle={{
                minWidth: currentRegion === "DE" ? "9vw" : "5vw",
                minHeight: "3vh",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">Ship To</label>
            <MultiSelectionDropDown
              data={filters.shipTos}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError}
              customStyle={{ minWidth: "7vw", minHeight: "3vh" }}
            />
          </span>
          <span className="filter">
            <label htmlFor="material">Material No</label>
            <MultiSelectionDropDown
              data={filters.materials}
              values={materialSelectedValues}
              onChange={onMaterialSelection}
              filterable={true}
              showError={showError}
              customStyle={{ minWidth: "7.0vw", minHeight: "3vh" }}
            />
          </span>

          <span className="filter">
            <label htmlFor="comments">Comments</label>
            <MultiSelectionDropDown
              data={filters.comments}
              values={commentSelectedValues}
              onChange={onCommentSelection}
              filterable={true}
              showError={showError}
              customStyle={{
                minWidth: currentRegion === "DE" ? "10vw" : "9vw",
                minHeight: "3vh",
              }}
            />
          </span>
          {selectedMode === 1 && (
            <span className="filter">
              <label htmlFor="tank">Tank Group</label>
              <MultiSelectionDropDown
                data={filters.tanks}
                values={tankSelectedValues}
                onChange={onTankSelection}
                filterable={true}
                showError={showError}
                customStyle={{ minWidth: "7.0vw", minHeight: "3vh" }}
              />
            </span>
          )}
        </div>
        {selectedMode === TrendViewMode.MATERIAL && (
          <div className="filters-container">
            <span className="filter">
              <label htmlFor="+250">
                {currentRegion === "DE" ? "1 Day +250" : "1 Day +150"}
              </label>
              <MultiSelectionDropDown
                data={filters.oneDayGain}
                values={day250SelectedValues}
                onChange={onDay250Selection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ minWidth: "4vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter">
              <label htmlFor="-250">
                {currentRegion === "DE" ? "1 Day -250" : "1 Day -150"}
              </label>
              <MultiSelectionDropDown
                data={filters.oneDayLoss}
                values={oneDayMinsSelectedValues}
                onChange={onOneDayMinsSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ minWidth: "4vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter-column">
              <label htmlFor="fiveDCumVar">5D Cumul +/-250</label>
              <MultiSelectionDropDown
                data={filters.isFiveDaysCumulative}
                values={fiveDCumVarSelectedValues}
                onChange={onFiveDCumVarSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ minWidth: "4vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter-column">
              <label htmlFor="fiveDCumThu">5D Cumul 3% Th</label>
              <MultiSelectionDropDown
                data={filters.isFiveDaysCumulativePercent}
                values={fiveDCumThSelectedValues}
                onChange={onFiveDCumThSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ minWidth: "4vw", minHeight: "3vh" }}
              />
            </span>
            {currentRegion !== "DE" && (
              <div className="filters-container">
                <span className="filter-column">
                  <label htmlFor="fiveDCumVar">30D Cumul +/-750</label>
                  <MultiSelectionDropDown
                    data={filters.isThirtyDaysCumulative}
                    values={thirtyDaysCumulSelectedValues}
                    onChange={onThirtyDayCumThSelection}
                    filterable={false}
                    showError={showError}
                    maxSelection={1}
                    customStyle={{ minWidth: "4vw", minHeight: "3vh" }}
                  />
                </span>
                <span className="filter-column">
                  <label htmlFor="fiveDCumThu">30D Cumul 2% Th</label>
                  <MultiSelectionDropDown
                    data={filters.isThirtyDaysCumulativePercent}
                    values={thirtyDaysCumulPercentageSelectedValues}
                    onChange={onThirtyDayCumPercentageSelection}
                    filterable={false}
                    showError={showError}
                    maxSelection={1}
                    customStyle={{ minWidth: "4vw", minHeight: "3vh" }}
                  />
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const showGridToolBar = () => {
    return (
      <GridToolbar>
        <div className="gridToolbar">
          {headerFilters()}
          <div className="rowContainer">
            {headerButtons()}
            {selectedMode === TrendViewMode.MATERIAL && headerColumns()}
          </div>
        </div>
      </GridToolbar>
    );
  };

  const showModeComponent = () => {
    return (
      <div className="tab-container">
        <div className="modeContainer">
          <ButtonGroup>
            <Button
              togglable={true}
              selected={selectedMode === TrendViewMode.MATERIAL}
              onClick={() => {
                setSelectedMode(TrendViewMode.MATERIAL);
              }}
            >
              Material View
            </Button>
            <Button
              togglable={true}
              selected={selectedMode === TrendViewMode.TANK}
              onClick={() => {
                setSelectedMode(TrendViewMode.TANK);
              }}
            >
              Tank Group View
            </Button>
          </ButtonGroup>
        </div>
        <div className="switchContainer">
          <Switch
            onLabel={""}
            offLabel={""}
            size="small"
            checked={historicalViewEnabled}
            onChange={() => {
              const enabled = !historicalViewEnabled;
              setHistoricalViewEnabled(enabled);
              props?.historyViewAction(enabled);
            }}
          />
          <span className="switch-label">Enable Historical View</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="out-of-trend-analysis-screen-container data-grid">
        <div className="row ">
          {masterDataLoading ? (
            <div className="loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h4>Fetching Details, Please Wait...</h4>
            </div>
          ) : (
            <div
              className={`data-grid-container ${
                selectedMode === TrendViewMode.TANK ? "grid-tank-view" : ""
              }`}
            >
              {showModeComponent()}
              {showGridToolBar()}

              {showSiteInfoModal && (
                <SiteInfoScreen
                  globalSiteId={selectedSite?.siteId}
                  countryCode={selectedSite?.country || currentRegion}
                  close={closeModal}
                />
              )}
              <AppDataGrid
                resetIndex={resetIndex}
                setResetIndex={setResetIndex}
                take={DEFAULT_PAGE_SIZE}
                style={{
                  height:
                    selectedMode === TrendViewMode.MATERIAL
                      ? historicalViewEnabled
                        ? "54vh"
                        : "50.8vh"
                      : historicalViewEnabled
                      ? "60.3vh"
                      : "57vh",
                }}
                pageable={true}
                filterable={true}
                data={trendAnalysisData}
                pageSizes={[5, 10, 20, 50, 100]}
                columnsToShow={colsToShow(
                  trendAnalysisData,
                  commentsOptions,
                  selectedMode,
                  filters.selectedDate?.[0]?.text,
                  currentRegion,
                  blankCount,
                  missingOutOfToleranceCount,
                  handleShipToLinkAction,
                  historicalViewEnabled,
                  missingCommentsCount
                )}
                onItemChange={handleDataChange}
                onRowClick={handleRowClick}
                rowRender={handleRowRender}
                sortable={true}
                ref={exportGridRef}
                exportFileName="OOATT"
                noRecordsMessage="* Please select filters to fetch the data *"
                // rowHeight={40}
                // total={trendAnalysisData?.total}
                // scrollable={"virtual"}
                // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
              />
              {exporting && (
                <LoadingPanel message="Exporting the data.. please wait.." />
              )}
              {fetchingData && (
                <LoadingPanel message="Fetching the data.. please wait.." />
              )}
              {submitting && (
                <LoadingPanel message="Submitting comments.. please wait.." />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export { OutOfToleranceTrendAnalysisV2 };
