import { Navigate, Outlet } from "react-router-dom";
import { NavBar } from "../navbar/navbar";
import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import InactivityPopup from "../inactivity-popup/InactivityPopup";

const ProtectedRoutes = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [lastActivity, setLastActivity] = useState(new Date().getTime());

  const handleActivity = () => {
    setLastActivity(new Date().getTime());
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const inactivityTime = currentTime - lastActivity;
      if (inactivityTime > 30 * 60 * 1000) {
        setIsPopupOpen(true);
      } 
    }, 1000);

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      clearInterval(timer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [lastActivity]);

  const store = useStore();
  const [authenticated, setAuthenticated] = useState(
    store.getState().user ? true : false
  );

  useEffect(() => {
    const authStatus = store.getState().user ? true : false;
    setAuthenticated(authStatus);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return authenticated ? (
    <>
      {isPopupOpen &&<InactivityPopup
        isOpen={isPopupOpen}   setIsPopupOpen={setIsPopupOpen}   />}
      <NavBar />
      <Outlet />
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

export { ProtectedRoutes };
