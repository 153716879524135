import "./page-not-found.scss";

const PageNotFound = () => {
  return (
    <div className="container-fluid page-not-found">
      <div className="row">
        <h1>Sorry this page is unavailable!</h1>
        <a href={window.origin + "/login"}>Back to Login</a>
      </div>
    </div>
  );
};

export { PageNotFound };
