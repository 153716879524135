import React from "react";
import { GainLossOverviewScreen } from "./gain-loss-overview/gain-loss-overview";

const GainLossOverviewContainer = () => {
  return (
    <>
      <div className="out-of-trend-analysis-screen-container">
        <div className="row content-container">
          <GainLossOverviewScreen />
        </div>
      </div>
    </>
  );
};

export { GainLossOverviewContainer };
