import React from "react";
import { PumpIndexScreen } from "./pump-index-report-screen/pump-index-report-screen";

const PumpIndexContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <PumpIndexScreen />
        </div>
      </div>
    </>
  );
};

export { PumpIndexContainer };
