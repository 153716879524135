import { CountryConfigScreen } from './country-config-screen/country-config-screen';

const CountryConfigScreenContainer = () => {
    return (
        <>
            <div className="container user-management-screen-container">
                <div className="row content-container">
                    <CountryConfigScreen />
                </div>
            </div>
        </>
    );
};

export { CountryConfigScreenContainer };
