import React, { useEffect, useState } from "react";
import { userService } from "../../../../../services/user";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Loader } from "@progress/kendo-react-indicators";
import { DropdownFilterCell } from "../../../../components/dropdown-filter/dropdown-filter";
import { DEFAULT_PAGE_SIZE } from '../common-utils';

let isMounted = false;

const UserLoginHistoryScreen = () => {
  const [loginHistory, setLoginHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchLoginHistory() {
    const response = await userService.fetchLoginHistory();
    if (isMounted) setLoginHistory(response ? response : []);
    setLoading(false);
  }

  useEffect(() => {
    isMounted = true;
    fetchLoginHistory();
    return () => {
      isMounted = false;
    };
  }, []);

  const userRoles = Array.from(
    new Set(
      loginHistory.map((data) =>
        data.RoleName ? data.RoleName.toUpperCase() : ""
      )
    )
  );

  const userRoleFilterCell = (props) => {
    return (
      <DropdownFilterCell
        {...props}
        data={userRoles}
        defaultItem={"Select Role"}
      />
    );
  };

  const colsToShow = [
    {
      title: "First Name",
      field: "FirstName",
      filterable: true,
    },
    {
      title: "Last Name",
      field: "LastName",
      filterable: true,
    },
    {
      title: "Unique User ID",
      field: "UniqueUserId",
    },
    {
      title: "User Role",
      field: "RoleName",
      filterable: true,
      filterCell: userRoleFilterCell,
    },
    {
      title: "Last Login Date",
      field: "LastLogInTime",
    },
  ];

  return (
    <>
      <div className="container data-grid">
        <div className="row ">
          {/* <h4 className="title">User Login History</h4> */}
          {loading ? (
            <div className="loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h4>Fetching Details, Please Wait...</h4>
            </div>
          ) : (
            <div className="data-grid-container">
              <AppDataGrid
                style={{ height: "73vh" }}
                exportable={true}
                take={DEFAULT_PAGE_SIZE}
                exportFileName="User Login History"
                filterable={true}
                pageable={true}
                data={loginHistory}
                columnsToShow={colsToShow}
                // rowHeight={30}
                // total={loginHistory?.total}
                // scrollable={"virtual"}
                // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export { UserLoginHistoryScreen };
