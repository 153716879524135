import React, { useEffect, useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";
import { useStore } from "react-redux";
import { setSelectedReport } from "../../../../state-management/actions";

export const ReportMenu = (props) => {
  const store = useStore();
  const { userScreenList } = store.getState();
  const [inputValue, setInputValue] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [submoduleId, setSubmoduleId] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [reportsList, setReportsList] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const savedReportsData = localStorage.getItem("reportsData");
  const parsedData = savedReportsData && JSON.parse(savedReportsData);
  const report = store.getState()?.selectedReport;

  useEffect(() => {
    if (parsedData) {
      const { moduleId, submoduleId } = parsedData;
      setExpanded([moduleId, submoduleId]);
      setSelectedItem(parsedData);
      store.dispatch(setSelectedReport(parsedData));
    }
    setReportsList(userScreenList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (parsedData) {
      const { moduleId, submoduleId, id } = parsedData;
      setExpanded([moduleId, submoduleId]);
      setSelectedItem(parsedData);
      let filteredItems = [];
      const hilightedItems = [];
      reportsList.forEach((list) => {
        const filterList = (data, parentId) => {
          data.forEach((child) => {
            if (child.id.toLowerCase() === id?.toLowerCase()) {
              filteredItems.push(parentId); // module id
              filteredItems.push(child.parentId); // submodule id
              hilightedItems.push(child.id); // child id
            }
            if (child.children) filterList(child.children, child.parentId);
          });
        };
        if (list.children) filterList(list.children, list.id);
      });
      filteredItems = Array.from(new Set(filteredItems));
      if (filteredItems?.length) {
        setExpanded(filteredItems);
      } else {
        setExpanded([moduleId, submoduleId]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  useEffect(() => {
    setReportsList(userScreenList);
  }, [userScreenList]);

  const listItems = PanelBarUtils.mapItemsToComponents(reportsList);

  const onSelect = (e) => {
    const {
      id,
      reportId,
      title,
      pageName,
      children,
      tableName,
      parentUniquePrivateKey, // array of selected item's parent ids
    } = e.target.props;
    let updatedExpandedItems = [];
    if (inputValue) setInputValue("");
    if (parentUniquePrivateKey.length === 0) setModuleId(id);
    if (parentUniquePrivateKey.length === 1) setSubmoduleId(id);
    const index = expanded.indexOf(id);
    if (index > -1) {
      const updateExpanded = [...expanded];
      updateExpanded.splice(index, 1);
      updatedExpandedItems = updateExpanded;
    } else if (!children) {
      const selectedReport = {
        moduleId: moduleId || parentUniquePrivateKey[0],
        submoduleId: submoduleId || parentUniquePrivateKey[1],
        pageId: id,
        pageName,
        reportId,
        title,
        tableName,
      };
      localStorage.setItem("reportsData", JSON.stringify(selectedReport));
      updatedExpandedItems = parentUniquePrivateKey;
      setSelectedItem(selectedReport);
      store.dispatch(setSelectedReport(selectedReport));
    } else updatedExpandedItems = [...expanded, id];
    setExpanded(updatedExpandedItems);
  };

  const handleSearch = (value) => {
    setInputValue(value);
    let filteredItems = [];
    const hilightedItems = [];
    if (value) {
      reportsList.forEach((list) => {
        const filterList = (data, parentId) => {
          data.forEach((child) => {
            if (child.title.toLowerCase().includes(value.toLowerCase())) {
              filteredItems.push(parentId); // module id
              filteredItems.push(child.parentId); // submodule id
              hilightedItems.push(child.id); // child id
            }
            if (child.children) filterList(child.children, child.parentId);
          });
        };
        if (list.children) filterList(list.children, list.id);
      });
      filteredItems = Array.from(new Set(filteredItems));
      setExpanded(filteredItems);
    } else setExpanded([selectedItem.moduleId, selectedItem.submoduleId]);
  };

  const searchClass = props?.menuCollapse
    ? "search-container search-collapse-width"
    : "search-container search-width";

  return (
    <div className="accordian-container">
      <div className={searchClass}>
        <img
          alt="Search"
          className="search-icon"
          src={require("../../../../assets/search.svg").default}
        />
        <Input
          onChange={(e) => handleSearch(e.value)}
          value={inputValue}
          placeholder="Search Title"
          className="input-field"
        />
      </div>
      <PanelBar
        isControlled
        expandMode="multiple"
        expanded={expanded}
        selected={selectedItem.pageId}
        children={listItems}
        onSelect={onSelect}
      />
    </div>
  );
};
