import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./dropdown.scss";

// Mandatory props
// 1. options: An array of options. {title:"Option 1", value:"option1"}
// 2. handleSelection: A handler function that executes upon Selection.
// Optional props
// 1. style: A style object for overriding default styles
// 2. defaultText: A default text to be shown before any selection has been made.
const DropDown = (props, ref=null) => {
  const [show, toggleShow] = useState(false);
  const [selected, setSelectedValue] = useState("");

  const dropDownRef = useRef();

  const clickOutSideAction = e => {
    if (!dropDownRef.current.contains(e.target)) {
      toggleShow(false);
    }
  };
   /* search the data and set the data to show on screen */
  useImperativeHandle(ref, () => ({
    resetSelection() {
      setSelectedValue('')
    },
  }));

  useEffect(() => {
    document.addEventListener("mousedown", clickOutSideAction);
    return () => document.removeEventListener("mousedown", clickOutSideAction);
  });

  const handleSelection = (option) => {
    setSelectedValue(option.title);
    toggleShow(false);
    props.onDropdownSelection({ name: props.name, selection: option.value });
  };

  return props.options ? (
    <div ref={dropDownRef} className="dropdown" style={props.style}>
      <button
        disabled={props?.disabled}
        style={props.style.button}
        onClick={() => {
          toggleShow(!show);
        }}
        className="dropbtn"
      >
        {selected
          ? selected
          : props.defaultText
          ? props.defaultText
          : "Select an item"}{" "}
        &nbsp; <ChevronDownIcon />
      </button>
      <div className={`dropdown-content${show ? " show" : ""}`}>
        {props.options.map((option, index) => (
          <option
            value={option.value}
            key={index}
            onClick={() => handleSelection(option)}
          >
            {option.title}
          </option>
        ))}
      </div>
    </div>
  ) : (
    <h4>No dropdown options</h4>
  );
};

export default forwardRef(DropDown);

const ChevronDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-chevron-down dropdown-chevron-down-icon"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};
