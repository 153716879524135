import { useNavigate } from "react-router-dom";
import "./navbar.scss";
import React, { useRef, useState } from "react";
import { Popup } from "@progress/kendo-react-popup";
import Dropdown from "../dropdown/dropdown";
import { Label } from "../label/label";
import { useDispatch, useStore } from "react-redux";
import { loginService } from "../../../services/login";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { userService } from "../../../services/user";
import {
  setCurrentRegion,
  setSelectedReport,
  setUserScreenList,
} from "../../../state-management/actions";

const SOM_ROLE_ID = "3";
const ADMIN_ROLE_ID = "1";

const NavBar = () => {
  const [showAvatarOptions, setShowAvatarOptions] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState();

  const store = useStore();
  const dispatch = useDispatch();
  const avatarContentRef = useRef(null);
  const avatar = useRef(null);
  const blurTimeoutAvatarRef = useRef(null);

  const user = store.getState().user;

  const userFullName = user
    ? store.getState().user.UserFirstName +
      " " +
      store.getState().user.UserLastName
    : "random";

  const roleId = user.RoleId;
  const country = user?.Country;

  const refreshSelectedReport = (screenList, report) => {
    screenList?.forEach((item) => {
      if (item?.children) {
        refreshSelectedReport(item?.children, report);
      } else {
        if (item?.id === report?.pageId) {
          dispatch(
            setSelectedReport({
              ...report,
              pageName: item?.pageName,
              reportId: item?.reportId,
              tableName: item?.tableName,
            })
          );
          return;
        }
      }
    });
  };

  const handelReportChange = async (option) => {
    if (selectedRegion === option.selection) {
      return;
    }

    setSelectedRegion(option.selection);
    // setShowLoader(true);
    const country = option?.selection === "eu" ? "BENEFRUX" : "DE";
    dispatch(setCurrentRegion(country));
    const User = await userService.fetchUser(user?.UniqueUserId, country);
    if (User && User.hasOwnProperty("UserFirstName")) {
      dispatch(setUserScreenList(User.ScreenList));
      const { selectedReport } = store.getState();
      refreshSelectedReport(User.ScreenList, selectedReport);
      setShowLoader(false);
    }
  };

  const onAvatarOpen = () => {
    avatarContentRef.current && avatarContentRef.current.focus();
  };
  const onAvatarFocus = () => {
    clearTimeout(blurTimeoutAvatarRef.current);
  };

  const onBlurAvatarTimeout = () => {
    setShowAvatarOptions(false);
  };
  const onAvatarBlur = () => {
    clearTimeout(blurTimeoutAvatarRef.current);
    blurTimeoutAvatarRef.current = setTimeout(onBlurAvatarTimeout, 200);
  };
  const navigate = useNavigate();
  const handleAvatarClick = () => {
    setShowAvatarOptions(!showAvatarOptions);
  };
  const handeLogOut = async () => {
    const email = store.getState().user.UserEmail;
    if (email.includes("@shell.com")) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      window.localStorage.clear();
      await loginService.nonShellUserLogout();
    }
  };
  const region = country === "DE" ? "DACH" : `${country}`;
  const defaultRegion = country ? region : "Region(s)";
  const regionDisabled =
    roleId === ADMIN_ROLE_ID || roleId === SOM_ROLE_ID ? false : true;

  return (
    <div className="container-fluid ">
      <div className="row app-navbar">
        {showLoader && <LoadingOverlay message=" " />}
        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 brand-section">
          <img
            src={require("../../../assets/logo.png")}
            alt="Shell Logo"
            className="logo"
            onClick={() => {
              navigate("/home");
            }}
          />
          <h3
            className="brand-name"
            onClick={() => {
              navigate("/home");
            }}
          >
            <Label i18nKey={"LABEL_APP_NAME"} />
          </h3>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 links-section">
          <div className="links-container">
            {/* <span className="link">
              <DropDown
                defaultText="Language"
                style={{
                  button: {
                    color: "#595959",
                    width: "150%",
                    fontSize: "0.8vw",
                  },
                }}
                onDropdownSelection={handelLanguageChange}
                options={[
                  { title: "English", value: "english" },
                  { title: "Japanese", value: "japanese" },
                ]}
                name="language"
              />
            </span> */}
            <span className="link">
              <Dropdown
                defaultText={defaultRegion}
                disabled={regionDisabled}
                style={{
                  button: {
                    color: "#595959",
                    width: "150%",
                    fontSize: "0.8vw",
                  },
                }}
                onDropdownSelection={handelReportChange}
                options={[
                  { title: "DACH", value: "de" },
                  { title: "BENEFRUX", value: "eu" },
                ]}
                name="reports"
              />
            </span>
            <span className="link">
              <img
                src={require("../../../assets/about.svg").default}
                alt="Info Icon"
                className="link-icon"
              />
              <p className="link-text" onClick={() => navigate("/about")}>
                About us
              </p>
            </span>
            <span className="link">
              <img
                src={require("../../../assets/tour.svg").default}
                alt="Info Icon"
                className="link-icon"
              />
              <p className="link-text" onClick={() => navigate("/tour")}>
                Take a tour
              </p>
            </span>
            <span className="link">
              <img
                src={require("../../../assets/contact.svg").default}
                alt="Info Icon"
                className="link-icon"
              />
              <p className="link-text" onClick={() => navigate("/contact")}>
                Contact Us
              </p>
            </span>
            <span className="link">
              <button
                id="avatar-btn"
                className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base overflow-button"
                ref={avatar}
                onClick={handleAvatarClick}
              >
                <img
                  src={`https://ui-avatars.com/api/?name=${userFullName}&color=random&background=random`}
                  alt="User Icon"
                  className="profile-pic img img-circle"
                />
              </button>
              <Popup
                className={"mdm--popup-options-container"}
                anchor={avatar.current}
                anchorAlign={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
                popupAlign={{
                  horizontal: "right",
                  vertical: "top",
                }}
                onOpen={onAvatarOpen}
                show={showAvatarOptions}
              >
                <div
                  className="pop-content"
                  ref={avatarContentRef}
                  tabIndex={0}
                  onFocus={onAvatarFocus}
                  onBlur={onAvatarBlur}
                >
                  <ul className="pop-content-ul" style={{ zIndex: 1 }}>
                    <li onClick={() => navigate("/profile")}>
                      <span>Profile</span>
                    </li>
                    <li onClick={() => navigate("/preference")}>
                      <span>Preference</span>
                    </li>
                    <li>
                      <span onClick={handeLogOut}>Logout</span>
                    </li>
                  </ul>
                </div>
              </Popup>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NavBar };
