import React from "react";
import { AppCircularGauge } from "../../../components/circular-gauge/circularGauge";
import DropDown from "../../../components/dropdown/dropdown";
import { Label } from "../../../components/label/label";

const SummaryPanel = () => {
  const handelSelection = (selectedValue) => {
  };
  return (
    <div className="col-md-4 col-sm-4 col-xs-4 col-lg-4 col-xl-4 summary-panel">
      <h1>
        <Label i18nKey={"LABEL_WELCOME_TEXT"} />
      </h1>
      <h6>
        <Label i18nKey={"LABEL_WELCOME_DESCRIPTION"} />
      </h6>
      <div className="row gauge-container">
        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 gauge-chart-container">
          <DropDown
            style={{ margin: "3% 4%", fontSize: '1vw' }}
            options={[
              { title: "Potential Leaks", value: "option1" },
              { title: "Secondary Option", value: "option2" },
              { title: "Tertiary Option", value: "option3" },
              { title: "Primary Option", value: "option4" },
            ]}
            onDropdownSelection={handelSelection}
          />
          <AppCircularGauge
            style={{ position: "relative", height: "10vw", marginLeft: '-20%', marginTop: '12%' }}
            value={75}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6 gauge-list-container">
          <ul className="list">
            <li className="list-item">
              <span className="list-item-count">1</span>
              <span className="list-item-text">Alert Name</span>
            </li>
          </ul>
          <ul className="list">
            <li className="list-item">
              <span className="list-item-count">2</span>
              <span className="list-item-text">Alert Name</span>
            </li>
          </ul>
          <ul className="list">
            <li className="list-item">
              <span className="list-item-count">3</span>
              <span className="list-item-text">Alert Name</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { SummaryPanel };
