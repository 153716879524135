import React from "react";
import { TankThresholdScreen } from "./tank-threshold-screen/tank-threshold-screen";

const TankThresholdContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container">
        <div className="row content-container">
          <TankThresholdScreen />
        </div>
      </div>
    </>
  );
};

export { TankThresholdContainer };
