import { Button, DropDownList } from "@progress/kendo-react-all";
import { useEffect, useState } from "react";
import { userService } from "../../../../../services/user";
import { siteService } from "../../../../../services/sites";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { MultiSelectTreeDropdown } from "../../../../components/multi-select-tree/main";
import "../../reports.scss";
import { triggerNotification } from "../../../../../state-management/actions";
import { useDispatch } from "react-redux";
import { DEFAULT_PAGE_SIZE } from '../common-utils';

const EditUserScreen = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [siteIds, setSiteIds] = useState([]);
  const dispatch = useDispatch();

  const fetchAllUsers = async () => {
    const users = await userService.fetchAuditList();
    // if (users && users.length > 0) {
    //   users.map((user) => (user["AccessControl"] = user["AccessControl"]));
    // }
    setUsers(users);
  };
  const fetchUserRoles = async () => {
    const roles = await userService.fetchUserRoles();
    setRoles(roles);
  };

  const fetchAllSiteIds = async () => {
    const siteIds = await siteService.fetchSiteIdList("DE");
    setSiteIds(siteIds);
  };

  useEffect(() => {
    fetchAllUsers();
    fetchUserRoles();
    fetchAllSiteIds();
  }, []);

  const colsToShow = [
    {
      title: "First Name",
      field: "UserFirstName",
      filterable: true,
    },
    {
      title: "Last Name",
      field: "UserLastName",
      filterable: true,
    },
    {
      title: "Email",
      field: "UserEmail",
      filterable: true,
    },
    {
      title: "Is Active",
      field: "IsActive",
      filterable: true,
    },
    {
      title: "Role",
      field: "RoleName",
      filterable: true,
    },
  ];

  const DetailsContainer = ({ dataItem }) => {
    const [isActive, setIsActive] = useState(dataItem["IsActive"] === "Y");
    const [selectedRole, setSelectedRole] = useState(dataItem["RoleName"]);
    const [selectedRoleId, setSelectedRoleId] = useState(dataItem["RoleId"]);
    const saveChanges = async () => {
      dispatch(
        triggerNotification({
          type: {
            style: "info",
            icon: true,
          },
          message: "Updating this user please wait...",
        })
      );
      const {
        AccessControl,
        Country,
        IsActive,
        RoleId,
        RoleName,
        UniqueUserId,
        UserEmail,
        UserFirstName,
        UserLastName,
        UserType,
      } = dataItem;
      const payload = {
        AccessControl: RoleName === "Global Admin" ? [Country] : AccessControl,
        Country: Country,
        IsActive: IsActive,
        RoleId: RoleId.toString(),
        UserRole: RoleName,
        UniqueUserId: UniqueUserId,
        UserEmail: UserEmail,
        UserFirstName: UserFirstName,
        UserLastName: UserLastName,
        UserType: UserType,
      };
      const response = await userService.updateUserRecord(payload);
      if (response) {
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: "Record updated successfully!",
          })
        );
      }
    };

    const EditRoleDropdown = () => {
      const permissibleRoles = roles.map((role) => role.RoleName);
      const handelChange = (e) => {
        const roleName = e.value;
        const roleId = roles.filter((role) => role.RoleName === roleName)[0]
          .RoleId;

        dataItem["RoleId"] = roleId;
        dataItem["RoleName"] = roleName;
        setSelectedRole(roleName);
        setSelectedRoleId(roleId);
      };
      return (
        <DropDownList
          className="details-container-dropdown"
          value={selectedRole}
          onChange={handelChange}
          data={permissibleRoles}
        />
      );
    };

    const ActivitySelection = () => {
      const handelChange = (e) => {
        const selectedValue = e.value;
        const isActive = selectedValue === "Active";
        setIsActive(isActive);
        dataItem["IsActive"] = isActive ? "Y" : "N";
      };

      return (
        <DropDownList
          className="details-container-dropdown"
          value={isActive ? "Active" : "In-Active"}
          onChange={handelChange}
          data={["Active", "In-Active"]}
        />
      );
    };

    const fields = [
      { fieldName: "Country", title: "Country", editable: false },
      { fieldName: "InternalUserId", title: "Internal UID", editable: false },
      {
        fieldName: "IsActivatedInCIPM",
        title: "CIPM Activation",
        editable: false,
      },
      {
        fieldName: "IsActive",
        title: "Is Active",
        editable: true,
        component: <ActivitySelection />,
      },
      { fieldName: "UniqueUserId", title: "UUID", editable: false },
      {
        fieldName: "RoleName",
        title: "Role Name",
        editable: true,
        component: <EditRoleDropdown />,
      },
    ];

    const gridData = [
      {
        text: dataItem["Country"],
        id: 0,
        items: siteIds.map((item, index) => {
          return { text: item, id: ++index };
        }),
      },
    ];

    const handelSiteIdChange = (newSiteIds) => {
      const newIds = newSiteIds.map((site) => site.text);
      dataItem["AccessControl"] = newIds.includes("DE") ? ["DE"] : newIds;
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <table>
              <tbody>
                {fields.map((item, index) => (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>
                      {item.editable
                        ? item.component
                        : dataItem[item.fieldName]}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>Site Access</td>
                  <td>
                    {selectedRoleId === "1" ? (
                      "This user has all access."
                    ) : (
                      <MultiSelectTreeDropdown
                        className="details-container-dropdown"
                        preSelectionEnabled={true}
                        dataItem={dataItem}
                        data={gridData}
                        onChange={handelSiteIdChange}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <h4>Details</h4>
            <p>Edit the user and click "Save" to consolidate your changes.</p>
            <Button
              className="user-management-edit-user-screen-btn-submit"
              onClick={saveChanges}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container data-grid">
        <div className="row ">
          {/* <h4 className="title">Edit Users</h4> */}
          <div className="data-grid-container">
            <AppDataGrid
              exportable={true}
              exportFileName="Users Information"
              style={{ height: "73vh" }}
              take={DEFAULT_PAGE_SIZE}
              pageable={true}
              filterable={true}
              expandable={true}
              sortable={false}
              data={users}
              detailsContainer={DetailsContainer}
              columnsToShow={colsToShow}
              // rowHeight={30}
              // total={users?.total}
              // scrollable={"virtual"}
              // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { EditUserScreen };
