import "./site-information.scss";
import React, { useEffect, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { siteService } from "../../../../../services/sites";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  clearSiteData,
  persistSiteData,
  triggerNotification,
  updateDetails,
} from "../../../../../state-management/actions";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-all";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../../../services/utils";
import { RK_SITE_ROLE_ID } from "../../../../constants";
import {
  colsToShow,
  nozzleValidator,
  pumpValidator,
  tankGroupColsToShow,
  tankPumpColsToShow,
} from "./site-information-utils";
import { SiteBasicDetails } from "./site-details-screen";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { reportsService } from "../../../../../services/reports";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";
import { SiteTankDetails } from "./site-tank-details-screen.jsx";
import { PumpInputCell } from "./PumpInputCell";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { DEFAULT_INITIAL_PAGE_SIZE } from "../common-utils";

const CONFIRMATION_OPTION = {
  TANK_DELETE: 0,
  PUMP_DELETE: 1,
  SAVE_PUMP_DATA: 2,
};

const SiteInformationScreen = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const currentRegion = useSelector((state) => state?.selectedRegion);
  const user = useSelector((state) => state?.user);
  const [showSiteDetails, setShowSiteDetails] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [siteStatuses, setSiteStatuses] = useState([]);
  const [regionList, setRegionList] = useState([]);
  useEffect(() => {
    const writeEnabled = user?.RoleId?.toString() === "3";
    setReadOnly(!writeEnabled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [region, setRegion] = useState(currentRegion);
  const [siteData, setSiteData] = useState([]);
  const [currentSiteItem, setCurrentSiteItem] = useState();
  const [currentTankItem, setCurrentTankItem] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [selectedTankItem, setSelectedTankItem] = useState();
  const [selectedTankPumpItem, setSelectedTankPumpItem] = useState();
  const [currentOffSet, setCurrentOffSet] = useState();
  const [showTankDetails, setShowTankDetails] = useState(false);
  const [addTankDetails, setAddTankDetails] = useState(false);
  const [currentOperation, setCurrentOperation] = useState();
  const [siteStatus, setSiteStatus] = useState({
    total: 0,
    active: 0,
    closed: 0,
    others: 0,
  });
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();

  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setRegion(selectedRegion);
  });

  useEffect(() => {
    dispatch(clearSiteData());
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  const fetchData = async () => {
    setLoading(true);
    await fetchSiteData();
    setLoading(false);
  };

  const fetchOtherData = async () => {
    setLoading(true);
    if (regionList?.length === 0) {
      await fetchRegionData();
    }

    if (siteStatuses?.length === 0) {
      await fetchSiteStatuses();
    }
    setLoading(false);
  };

  const cancelDetailsModal = () => {
    setShowSiteDetails(false);
  };

  const saveDetails = async (siteInfo) => {
    setCurrentSiteItem(siteInfo);
    setShowSiteDetails(false);
    let data = await fetchSiteData();
    data = data?.map((item) => {
      let updatedItem = item;
      if (item?.expanded) {
        updatedItem = {
          ...item,
          ...siteInfo,
          Country: siteInfo?.CountryCode,
          Plant: siteInfo?.PLANT,
          Sloc: siteInfo?.SLOC,
          expanded: true,
        };
      }
      return updatedItem;
    });

    setSiteData(data);
  };

  const cancelTankDetailsModal = () => {
    setShowTankDetails(false);
    setAddTankDetails(false);
  };

  const saveTankDetails = (siteInfo) => {
    setCurrentSiteItem(siteInfo);
    setShowTankDetails(false);
    setAddTankDetails(false);
    const updatedData = siteData?.map((item) =>
      item.GlobalSiteId === siteInfo.GlobalSiteId
        ? { ...item, ...siteInfo }
        : item
    );
    setSiteData(updatedData);
  };

  const TankDetailsContainer = (props) => {
    const tankData = props.dataItem;
    const [pumpEditing, setPumpEditing] = useState();
    const [showPumpSaveConfirmation, setShowPumpSaveConfirmation] =
      useState(false);
    const [currentOffSet, setCurrentOffSet] = useState();
    const [pumpData, setPumpData] = useState([]);
    const [disableAdd, setDisableAdd] = useState(false);
    const tankItem = {
      ...tankData,
      GlobalSiteId: currentSiteItem?.GlobalSiteId,
    };

    const sortKeys = (keys) => {
      const sortedArray = [...keys].sort((a, b) => {
        const aStr = a.toString();
        const bStr = b.toString();

        if (aStr.length !== bStr.length) {
          return aStr.length - bStr.length;
        }
        return a - b;
      });
      return sortedArray;
    };

    const formatPumpData = () => {
      const pumpNozzle = tankItem?.PumpNozzle;
      const tankEdit =
        pumpEditing && tankItem?.deviceID === selectedTankItem?.deviceID;

      let pumpList = [];
      const nozzleKeys = pumpNozzle && sortKeys(Object.keys(pumpNozzle));
      pumpNozzle &&
        nozzleKeys?.forEach((key, index) => {
          if (key?.length === 0) {
            setDisableAdd(true);
          }
          const editing =
            key?.length === 0 ||
            (tankEdit && key === selectedTankPumpItem?.pump?.toString());
          const nozzles = pumpNozzle?.[key];
          if (Array.isArray(nozzles)) {
            nozzles.forEach((pump) => {
              const pumpItem = {
                pump: key,
                nozzle: pump,
                location: index,
                inEdit: editing,
                title: editing ? "Save" : "Edit",
                subTitle: editing ? "Cancel" : "Delete",
                pumpValid: pumpValidator(key),
                nozzleValid: nozzleValidator(pump),
              };
              pumpList = [...pumpList, pumpItem];
            });
          } else {
            const pumpItem = {
              pump: key,
              nozzle: nozzles,
              location: index,
              inEdit: editing,
              title: editing ? "Save" : "Edit",
              subTitle: editing ? "Cancel" : "Delete",
              pumpValid: pumpValidator(key),
              nozzleValid: nozzleValidator(nozzles),
            };
            pumpList = [...pumpList, pumpItem];
          }
        });

      setPumpData(pumpList);
    };

    useEffect(() => {
      formatPumpData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPumpAndNozzleExist = (item, pumpData) => {
      let error = null;
      const exist = pumpData?.find(
        (data) =>
          data?.pump?.toString() === item?.pump?.toString() &&
          data?.nozzle?.toString() === item?.nozzle?.toString()
      );
      if (exist) {
        error = "The Pump & Nozzle is already exist.";
      }
      return error;
    };

    const handleDataChange = (e) => {
      const { dataItem, field, value } = e;
      const updatedData = pumpData?.map((item) => {
        if (item?.location === dataItem?.location) {
          item[field] = value;
          const filteredPumpData = pumpData?.filter(
            (pump) => pump.location !== dataItem?.location
          );
          const error = isPumpAndNozzleExist(item, filteredPumpData);
          if (error) {
            item["pumpValid"] = error;
            item["nozzleValid"] = error;
          } else {
            item["pumpValid"] = pumpValidator(item?.pump);
            item["nozzleValid"] = nozzleValidator(item?.nozzle);
          }
        }
        return item;
      });
      setPumpData(updatedData);
    };

    const handleTankPumpEditSave = async (pumpData, tankItem, item, offset) => {
      setCurrentOffSet(offset);
      setPumpData(pumpData);
      setShowPumpSaveConfirmation(true);
    };

    const tankPumpSaveAction = async () => {
      setShowPumpSaveConfirmation(false);
      setPumpEditing(false);

      const siteItem = siteData?.find(
        (data) => data?.GlobalSiteId === tankItem?.GlobalSiteId
      );

      const tankDetails = siteItem?.TankInfo?.map((tank) => {
        const tankDetails = tank;
        if (
          tankDetails?.deviceID === tankItem?.deviceID &&
          tankDetails?.tankName === tankItem?.tankName
        ) {
          const pumpNozzle = {};
          pumpData?.forEach((item) => {
            pumpNozzle[item.pump] = [item?.nozzle];
          });
          tankDetails.PumpNozzle = pumpNozzle;
        }
        return tankDetails;
      });
      siteItem.TankInfo = tankDetails;
      setSubmitting(true);
      const response = await siteService.updateSiteInfo(
        siteItem?.Country,
        siteItem?.GlobalSiteId,
        siteItem
      );
      if (response?.status >= 200 && response?.status < 300) {
        setSelectedTankPumpItem(null);
        dispatch(
          triggerNotification({
            type: {
              style: "success",
              icon: true,
            },
            message: response?.data?.message || response?.data,
          })
        );
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Failed to save site information!",
          })
        );
      }
      setSubmitting(false);
    };

    const handleTankPumpEditCancel = (
      pumpData,
      tankItem,
      dataItem,
      addingPump
    ) => {
      if (addingPump) {
        const updatedSiteData = siteData?.map((data) => {
          if (data?.GlobalSiteId === tankItem?.GlobalSiteId) {
            data.expanded = true;
            const tankDetails = data?.TankInfo?.map((tank) => {
              if (
                tank?.deviceID === tankItem?.deviceID &&
                tank?.tankName === tankItem?.tankName
              ) {
                tank.expanded = true;
                const pumpNozzle = {};
                pumpData?.forEach((item) => {
                  if (item.pump?.length > 0) {
                    pumpNozzle[item.pump] = [item?.nozzle];
                  }
                });
                tank.PumpNozzle = pumpNozzle;
              }
              return tank;
            });
            data.TankInfo = tankDetails;
          }
          return data;
        });
        setSiteData(updatedSiteData);
      } else {
        formatPumpData();
      }
      setPumpEditing(false);
      setSelectedTankPumpItem(null);
    };

    const handleTankPumpEdit = (tankItem, item) => {
      setPumpEditing(true);
      const pumpList = pumpData?.map((pump) => {
        if (pump.pump === item.pump) {
          return {
            ...pump,
            inEdit: true,
            title: "Save",
            subTitle: "Cancel",
          };
        } else {
          return {
            ...pump,
            inEdit: false,
            title: "Edit",
            subTitle: "Delete",
          };
        }
      });
      setPumpData(pumpList);
    };

    const handleTankPumpAdd = (tankItem) => {
      const pumpItem = {
        pump: "",
        nozzle: "",
        location: 0,
        inEdit: true,
        title: "Save",
        subTitle: "Cancel",
        pumpValid: pumpValidator(null),
        nozzleValid: nozzleValidator(null),
      };
      const pumpList = [pumpItem, ...pumpData]?.map((item, index) => ({
        ...item,
        location: index,
      }));
      setPumpData(pumpList);
      setPumpEditing(true);
    };
    return (
      <>
        <div className="container-fluid">
          <div className="pumpGridContainer">
            <AppDataGrid
              style={{ maxHeight: "50vh", borderColor: "#fbce07" }}
              take={10000000}
              pageable={false}
              sortable={true}
              data={pumpData}
              onItemChange={handleDataChange}
              cells={{
                edit: {
                  numeric: PumpInputCell,
                },
              }}
              columnsToShow={tankPumpColsToShow({
                tankItem: tankItem,
                pumpData: pumpData,
                onEdit: handleTankPumpEdit,
                onDelete: handleTankPumpDelete,
                onSave: handleTankPumpEditSave,
                onCancel: handleTankPumpEditCancel,
                onAdd: handleTankPumpAdd,
                disableAdd: disableAdd,
                readOnly: readOnly,
              })}
            />
            {showPumpSaveConfirmation && (
              <ConfirmPopup
                offset={currentOffSet}
                position="top"
                isOpen={showPumpSaveConfirmation}
                title={"Are you sure you want to update the site information?"}
                message={""}
                saveAction={() => {
                  tankPumpSaveAction();
                }}
                cancelAction={() => {
                  // handleTankPumpEditCancel();
                  setShowPumpSaveConfirmation(false);
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const updateItemData = async (item) => {
    const dataItem = item;
    setCurrentSiteItem(dataItem);
    if (
      !dataItem?.OperatingHours &&
      !dataItem?.TankInfo &&
      !dataItem?.NozzleInfo
    ) {
      setFetchingDetails(true);
      const response = await siteService.fetchSiteDetailsInfo(
        dataItem?.PartitionKey || dataItem?.Country,
        dataItem?.RowKey || dataItem?.GlobalSiteId
      );
      if (response) {
        const openingDate = getFormattedDate(
          new Date(response?.SiteOpeningDate)
        );
        const closingDate = getFormattedDate(
          new Date(response?.SiteClosingDate)
        );
        dataItem.City = response?.City ? response?.City : "";
        dataItem.OpeningDate = openingDate ? openingDate : "";
        dataItem.ClosingDate = closingDate ? closingDate : "";
        dataItem.NozzleInfo = response?.NozzleInfo;
        dataItem.OperatingHours = response?.OperatingHours;
        dataItem.TankInfo = response?.TankInfo?.map((item) => ({
          ...item,
          expanded: false,
        }));
        dataItem.Remarks = response?.Remarks ? response?.Remarks : "";
        dispatch(updateDetails(dataItem));

        const data = store.getState().siteData;
        let newData = data.map((item) => {
          if (item.id === dataItem.id) {
            if (dataItem?.expand) {
              dataItem.expanded = true;
            }
            return dataItem;
          }

          return item;
        });
        setSiteData(newData);
      }
      setFetchingDetails(false);
    }
    return dataItem;
  };

  const handleDetailsEdit = async (props) => {
    if (regionList?.length === 0 || siteStatuses?.length === 0) {
      await fetchOtherData();
    }
    let item = props;
    if (!props?.OperatingHours && !props?.TankInfo && !props?.NozzleInfo) {
      // item = await updateItemData({ ...props, expand: true });
      item = await updateItemData({ ...props });
    }
    setCurrentSiteItem(item);
    setShowSiteDetails(true);
  };

  const handleTankGroupEdit = (item) => {
    setCurrentTankItem(item);
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === item?.GlobalSiteId) {
        data.expanded = true;
        const tankDetails = data?.TankInfo?.map((tank) => {
          if (
            tank?.deviceID === item?.deviceID &&
            tank?.tankName === item?.tankName
          ) {
            // tank.expanded = true;
          }
          return tank;
        });
        data.TankInfo = tankDetails;
      }
      return data;
    });
    setSiteData(updatedSiteData);
    setShowTankDetails(true);
  };

  const tankPumpDeleteAction = async () => {
    setShowConfirmationPopup(false);
    const siteItem = siteData?.find(
      (data) => data?.GlobalSiteId === selectedTankItem?.GlobalSiteId
    );

    const tankDetails = siteItem?.TankInfo?.map((tank) => {
      const tankDetails = tank;
      if (
        tankDetails?.deviceID === selectedTankItem?.deviceID &&
        tankDetails?.tankName === selectedTankItem?.tankName
      ) {
        const { [selectedTankPumpItem?.pump]: _, ...pumpNozzle } =
          tankDetails?.PumpNozzle;
        tankDetails.PumpNozzle = pumpNozzle;
      }
      return tankDetails;
    });
    siteItem.TankInfo = tankDetails;
    setSubmitting(true);
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      siteItem
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Failed to save site information!",
        })
      );
    }
    setSubmitting(false);
  };

  const tankDeleteAction = async (props) => {
    setShowConfirmationPopup(false);
    const siteItem = siteData?.find(
      (data) => data?.GlobalSiteId === props?.GlobalSiteId
    );

    const tankDetails = siteItem?.TankInfo?.filter((tank) => {
      const sameTank =
        tank?.deviceID === props?.deviceID &&
        tank?.tankName === props?.tankName &&
        tank?.additionalProductInfo === props?.additionalProductInfo &&
        tank?.capacity === props?.capacity &&
        tank?.materialNo === props?.materialNo &&
        tank?.productCode === props?.productCode &&
        tank?.tankGroup === props?.tankGroup &&
        tank?.workCapacity === props?.workCapacity;
      return !sameTank;
    });
    siteItem.TankInfo = tankDetails;
    setSubmitting(true);
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      siteItem
    );
    if (response?.status >= 200 && response?.status < 300) {
      dispatch(
        triggerNotification({
          type: {
            style: "success",
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to delete.",
        })
      );
    }
    setSubmitting(false);
  };
  const handleTankGroupDelete = async (item, offset) => {
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === item?.GlobalSiteId) {
        data.expanded = true;
        const tankDetails = data?.TankInfo?.map((tank) => {
          if (
            tank?.deviceID === item?.deviceID &&
            tank?.tankName === item?.tankName
          ) {
            // tank.expanded = true;
          }
          return tank;
        });
        data.TankInfo = tankDetails;
      }
      return data;
    });
    setSiteData(updatedSiteData);
    setCurrentOffSet(offset);
    setConfirmationTitle("Please Confirm");
    setConfirmationMessage("Are you sure you want to delete this Tank Group?");
    setCurrentOperation(CONFIRMATION_OPTION.TANK_DELETE);
    setSelectedTankItem(item);
    setShowConfirmationPopup(true);
  };

  const handleTankPumpDelete = async (tankItem, item, offset) => {
    setCurrentOffSet(offset);
    setConfirmationTitle("Please Confirm");
    setConfirmationMessage("Are you sure you want to delete this Tank Pump?");
    setCurrentOperation(CONFIRMATION_OPTION.PUMP_DELETE);
    setSelectedTankItem(tankItem);
    setSelectedTankPumpItem(item);
    setShowConfirmationPopup(true);
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === tankItem?.GlobalSiteId) {
        data.expanded = true;
        const tankDetails = data?.TankInfo?.map((tank) => {
          if (
            tank?.deviceID === tankItem?.deviceID &&
            tank?.tankName === tankItem?.tankName
          ) {
            tank.expanded = true;
          }
          return tank;
        });
        data.TankInfo = tankDetails;
      }
      return data;
    });
    setSiteData(updatedSiteData);
  };

  const handleTankGroupAdd = (props) => {
    setCurrentSiteItem(props?.siteItem);
    setCurrentTankItem(null);
    setShowTankDetails(true);
    setAddTankDetails(true);
  };

  const CustomDetailsContainer = (props) => {
    const dataItem = props?.dataItem;
    let NozzleInfo = dataItem?.TankInfo;
    NozzleInfo?.sort((a, b) => a.deviceID - b.deviceID);
    NozzleInfo?.sort((a, b) => {
      const tankGroup1 = a?.tankGroup?.replace(/\D/g, "");
      const tankGroup2 = b?.tankGroup?.replace(/\D/g, "");
      return tankGroup1 - tankGroup2;
    });
    NozzleInfo = NozzleInfo?.map((item) => ({
      ...item,
      TankNo: item?.deviceID || item?.tankName,
      tankGroup: item?.tankGroup || `TG${item?.deviceID}`,
      GlobalSiteId: dataItem?.GlobalSiteId,
    }));

    return (
      <>
        <div className="container-fluid">
          <div className="openingHoursContainer">
            <AppDataGrid
              style={{ maxHeight: "50vh", borderColor: "#b8e2ea" }}
              take={10000000}
              pageable={false}
              filterable={true}
              expandable={true}
              sortable={true}
              data={NozzleInfo}
              detailsContainer={TankDetailsContainer}
              numericSortFields={["tankGroup"]}
              columnsToShow={tankGroupColsToShow({
                onEdit: handleTankGroupEdit,
                onDelete: handleTankGroupDelete,
                onAdd: handleTankGroupAdd,
                selectedItem: currentTankItem,
                siteItem: props?.dataItem,
                readOnly: readOnly,
              })}
              expandChange={(data) => {
                const siteId = data?.[0]?.GlobalSiteId;
                const updatedData = siteData?.map((item) => {
                  if (item?.GlobalSiteId === siteId) {
                    return {
                      ...item,
                      TankInfo: data,
                    };
                  }
                  return item;
                });
                setSiteData(updatedData);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const fetchSiteStatuses = async () => {
    const response = await siteService.fetchSiteStatuses();
    if (response) {
      setSiteStatuses(response);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Unable to fetch the Site Statuses data.",
        })
      );
    }
  };

  const fetchRegionData = async () => {
    try {
      const payload = {
        country: currentRegion,
      };
      const response = await reportsService.fetchRegionsData(payload);
      if (response) {
        setRegionList(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the Region data.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
    }
  };

  const fetchSiteData = async (shouldExpand = true) => {
    try {
      // if (store.getState().siteData && store.getState().siteData.length > 0) {
      //   setLoading(false);
      //   setSiteData(store.getState().siteData);
      //   return;
      // }
      setLoading(true);
      // const rkSiteRole = user?.RoleId === RK_SITE_ROLE_ID;
      const response = await siteService.fetchSiteList(region);
      if (response) {
        const siteStatusObj = {
          total: 0,
          active: 0,
          closed: 0,
          others: 0,
        };
        let data = response?.reduce((info, item) => {
          const expanded = currentSiteItem?.RowKey === item?.RowKey;
          const status = item?.SiteStatus;
          siteStatusObj.total += 1;
          if (status?.toLowerCase() === "active") {
            siteStatusObj.active += 1;
          } else if (status?.toLowerCase()?.includes("closed")) {
            siteStatusObj.closed += 1;
          } else {
            siteStatusObj.others += 1;
          }
          return [
            ...info,
            {
              ...item,
              Country: item?.PartitionKey ? item?.PartitionKey : "",
              PartitionKey: item?.PartitionKey ? item?.PartitionKey : "",
              RowKey: item?.RowKey ? item?.RowKey : "",
              Region: item?.Region ? item?.Region : "",
              City: item?.City ? item?.City : "",
              IsRKSite: item?.IsRKSite ? item?.IsRKSite : "",
              Plant: item?.PLANT ? item?.PLANT : "",
              Sloc: item?.SLOC ? item?.SLOC : "",
              GlobalSiteId: item?.RowKey ? item?.RowKey : "",
              OBN: item?.OBN ? item?.OBN : "",
              SiteId: item?.SiteId ? item?.SiteId : "",
              Name: item?.Name ? item?.Name : "",
              Remarks: item?.Remarks ? item?.Remarks : "",
              SiteStatus: item?.SiteStatus ? item?.SiteStatus : "",
              TankInfo: item?.TankInfo,
              NozzleInfo: item?.NozzleInfo,
              OperatingHours: item?.OperatingHours,
              IsWSMASupportedSite:
                item?.IsWSMASupportedSite === false ? "N" : "Y",
              Timestamp: item?.Timestamp
                ? getFormattedDateAndTime(new Date(item?.Timestamp))
                : "",
              expanded: shouldExpand === false ? false : expanded,
            },
          ];
        }, []);

        data?.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setSiteStatus(siteStatusObj);
        setSiteData(data);
        dispatch(persistSiteData(data));
        setLoading(false);
        return data;
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the site information.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
      setError("Server error. Unable to fetch data.");
      setLoading(false);
      setError("");
    }
  };

  const columnsToExport = [
    {
      title: "Country",
      field: "Country",
      filterable: true,
    },
    {
      title: "Region",
      field: "Region",
      filterable: true,
    },
    {
      title: "City",
      field: "City",
      filterable: true,
    },
    {
      title: "Plant",
      field: "Plant",
      filterable: true,
    },
    {
      title: "SLOC",
      field: "Sloc",
      filterable: true,
    },
    {
      title: "Ship To",
      field: "GlobalSiteId",
      filterable: true,
    },
    {
      title: "Site ID",
      field: "SiteId",
      filterable: true,
    },
    {
      title: "Site Name",
      field: "Name",
      filterable: true,
    },
    {
      title: "Status",
      field: "SiteStatus",
      filterable: true,
    },
    {
      title: "Material",
      field: "materialNo",
      filterable: true,
    },
    {
      title: "Tank Code",
      field: "deviceID",
      filterable: true,
    },
    {
      title: "Max. Fuel",
      field: "capacity",
      filterable: true,
    },
  ];

  const exportData = async () => {
    setExporting(true);
    const rkSiteRole = user?.RoleId === RK_SITE_ROLE_ID;
    await siteService.downloadSiteExcel(region, rkSiteRole);
    setExporting(false);
  };

  const size = submitting ? "large" : "small";
  const BoxItem = ({ item }) => {
    return (
      <div className="boxContainer">
        <span className="highlighter"></span>
        <span className="box-title">{item?.title}</span>
        <span className="box-count">{item?.count}</span>
      </div>
    );
  };
  const showHeaderBox = () => {
    return (
      <div className="header-box-container">
        <BoxItem
          item={{
            title: "Total Sites",
            count: siteStatus?.total || "0",
          }}
        />
        <BoxItem
          item={{
            title: "Total Active Sites",
            count: siteStatus?.active || "0",
          }}
        />
        <BoxItem
          item={{
            title: "Total Closed Sites",
            count: siteStatus?.closed || "0",
          }}
        />
      </div>
    );
  };

  const exportHeader = () => {
    return (
      <span className="exportContainer">
        <Button
          className="exportButton"
          onClick={exportData}
          disabled={exporting}
        >
          {exporting ? "Exporting..." : "Export"}
        </Button>
        <Button
          className="refreshButton"
          onClick={() => {
            fetchSiteData(false);
          }}
          disabled={loading}
        >
          Refresh
        </Button>
      </span>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {/* <h4 className="title">Site Information</h4> */}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          <div className="header-container">
            {showHeaderBox()}
            {exportHeader()}
          </div>
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={selectedSite?.country || currentRegion}
              close={closeModal}
            />
          )}

          <div className="siteList-grid-container">
            <AppDataGrid
              style={{ height: "67vh" }}
              pageable={true}
              take={DEFAULT_INITIAL_PAGE_SIZE}
              filterable={true}
              fixedScroll={true}
              expandable={true}
              sortable={true}
              data={siteData}
              detailsContainer={CustomDetailsContainer}
              columnsToShow={colsToShow({
                onEdit: handleDetailsEdit,
                region: currentRegion,
                handleShipToLinkAction: handleShipToLinkAction,
              })}
              columnsToExport={columnsToExport}
              updateItemData={updateItemData}
              expandChange={(data) => {
                setSiteData(data);
              }}
              // rowHeight={30}
              // total={siteData?.total}
              // scrollable={"virtual"}
              // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
            />
          </div>
          {showSiteDetails && (
            <SiteBasicDetails
              selectedItem={currentSiteItem}
              currentRegion={currentRegion}
              cancel={cancelDetailsModal}
              save={saveDetails}
              readOnly={readOnly}
              siteStatuses={siteStatuses}
              regionList={regionList}
            />
          )}
          {!showSiteDetails && showTankDetails && (
            <SiteTankDetails
              currentSiteItem={currentSiteItem}
              selectedItem={addTankDetails ? null : currentTankItem}
              currentRegion={currentRegion}
              cancel={cancelTankDetailsModal}
              save={saveTankDetails}
              isAddTank={addTankDetails}
            />
          )}
          {loading && (
            <LoadingPanel message="Fetching the data.. please wait.." />
          )}
          {fetchingDetails && (
            <LoadingPanel message="Fetching the data.. please wait.." />
          )}
          {(submitting || exporting) && (
            <span className="remarks-loader-container">
              <Loader
                size={size}
                themeColor="warning"
                type="converging-spinner"
              />
              {exporting && (
                <p className="loader-description">Exporting data...</p>
              )}
            </span>
          )}
          {showConfirmationPopup && (
            <ConfirmPopup
              offset={currentOffSet}
              position={
                currentOperation === CONFIRMATION_OPTION.SAVE_PUMP_DATA
                  ? "top"
                  : "left"
              }
              isOpen={showConfirmationPopup}
              title={confirmationTitle}
              message={confirmationMessage}
              saveAction={() => {
                if (currentOperation === CONFIRMATION_OPTION.TANK_DELETE) {
                  tankDeleteAction(selectedTankItem);
                } else if (
                  currentOperation === CONFIRMATION_OPTION.PUMP_DELETE
                ) {
                  tankPumpDeleteAction();
                }
              }}
              cancelAction={() => {
                setShowConfirmationPopup(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { SiteInformationScreen };
