import "./gain-loss-overview.scss";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { Button, DateInput, DateRangePicker } from "@progress/kendo-react-all";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { store } from "../../../../../state-management/store";
import { colsToExport, colsToShow } from "./gain-loss-overview-utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { ButtonGroup } from "@progress/kendo-react-buttons";
import {
  getDateBeforeMonths,
  getDaysBeforeDate,
  getFormattedDate,
} from "../../../../../services/utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import {
  getAllSelectedOptions,
  getFilterRegions,
  getShipTos,
  getSiteNames,
  getMaterials,
  getTankGroupWithTankList,
} from "../../reportUtils";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { DEFAULT_PAGE_SIZE } from "../common-utils";

export const GainLossTabOptions = {
  MATERIAL: 0,
  TANK_GROUP: 1,
};

const DropDownSelection = {
  ShipTo: 0,
  SiteName: 1,
};

const GainLossOverviewScreen = () => {
  const dispatch = useDispatch();
  const exportGridRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(GainLossTabOptions.MATERIAL);
  const [resetIndex, setResetIndex] = useState(false);
  const defaultRegion = useSelector((state) => state?.selectedRegion);
  const [currentRegion, setCurrentRegion] = useState(defaultRegion);

  const [filters, setFilters] = useState({
    selectedRegions: [],
    regions: [],
    selectedShipTo: null,
    allSiteIds: [],
    selectedSiteName: null,
    allSiteNames: [],
    selectedProducts: [],
    products: [],
    selectedTanks: [],
    tankGroup: [],
    selectedDates: {
      start: getDaysBeforeDate(currentRegion === "DE" ? 10 : 30),
      end: getDaysBeforeDate(currentRegion === "DE" ? 1 : 1),
    },
    prevSelectedDates: {
      start: null,
      end: null,
    },
  });

  const [appliedFilters, setAppliedFilters] = useState(null);

  const [masterData, setMasterData] = useState();
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [footerItem, setFooterItem] = useState(null);
  const [gainLossData, setGainLossData] = useState([]);
  const [gainLossExportData, setGainLossExportData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  store.subscribe(() => {
    const { selectedRegion } = store.getState();
    setCurrentRegion(selectedRegion);
  });

  const hasEmptyValues = () => {
    let emptySelection =
      filters?.selectedRegions?.length === 0 ||
      filters?.selectedShipTo?.length === 0 ||
      filters?.selectedProducts?.length === 0 ||
      filters?.selectedMaterials?.length === 0 ||
      filters?.selectedTanks?.length === 0;

    return emptySelection;
  };

  const loadData = () => {
    let emptySelection = hasEmptyValues();

    if (!emptySelection) {
      fetchGainLossData();
    }
  };

  const exportData = async () => {
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());
  };

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: {
          start: getDaysBeforeDate(currentRegion === "DE" ? 10 : 30),
          end: getDaysBeforeDate(currentRegion === "DE" ? 1 : 1),
        },
      };
    });
    setRegionSelectedValues([]);
    fetchMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  useEffect(() => {
    setGainLossData([]);
    setFooterItem(null);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    const exportData = gainLossData?.map((item) => {
      const outBound = item?.outBound?.replace(/\n/g, ", ");
      return {
        ...item,
        outBound: outBound,
      };
    });
    setGainLossExportData(exportData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gainLossData]);

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );
  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );

  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );

  useEffect(() => {
    const regions = getFilterRegions(masterData, true, currentRegion);

    setFilters((prev) => {
      return {
        ...prev,
        // selectedRegions: selectedRegions,
        regions: regions,
      };
    });
    // setRegionSelectedValues(selectedRegions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    const siteIds =
      filters.selectedRegions?.length > 0
        ? getShipTos(masterData, filters.selectedRegions, false)
        : [];

    if (siteIds?.length) {
      // setShipToSelectedValues([siteIds?.[0]]);
    } else {
      setShipToSelectedValues([]);
    }

    const siteNames =
      filters.selectedRegions?.length > 0
        ? getSiteNames(masterData, filters.selectedRegions, false)
        : [];
    setFilters((prev) => {
      return {
        ...prev,
        allSiteIds: siteIds,
        allSiteNames: siteNames,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  const getAllProducts = () => {
    const productsData = getMaterials(masterData, filters, true);
    const allProducts = productsData?.map((product) => {
      const text =
        product?.id === "all"
          ? product?.text
          : `${product?.id} | ${product?.text}`;

      return {
        ...product,
        id: product?.id,
        text: text,
      };
    });

    return allProducts;
  };

  useEffect(() => {
    const allProducts = getAllProducts();
    const selectedProduct = allProducts?.[0];
    const selectedProducts = selectedProduct ? [selectedProduct] : [];

    setFilters((prev) => {
      return {
        ...prev,
        products: allProducts,
        selectedProducts: selectedProducts,
        tankGroup: [],
      };
    });

    setProductSelectedValues(selectedProducts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedShipTo]);

  const leakTabComponent = () => {
    return (
      <div className="modeContainer">
        <ButtonGroup>
          <Button
            togglable={true}
            selected={selectedTab === GainLossTabOptions.MATERIAL}
            onClick={() => {
              setSelectedTab(GainLossTabOptions.MATERIAL);
            }}
          >
            Material View
          </Button>
          <Button
            togglable={true}
            selected={selectedTab === GainLossTabOptions.TANK_GROUP}
            onClick={() => {
              setSelectedTab(GainLossTabOptions.TANK_GROUP);
            }}
          >
            Tank Group View
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: regionSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      const siteData = filters?.allSiteIds?.find(
        (site) => site?.id === shipToSelectedValues?.[0]?.id
      );

      const site = filters?.allSiteNames?.find(
        (site) => site?.siteName === siteData?.siteName
      );
      const siteNames = site && siteData ? [site] : [];
      setFilters((prev) => {
        return {
          ...prev,
          selectedShipTo: shipToSelectedValues ? shipToSelectedValues : [],
          selectedSiteName: siteNames,
        };
      });
      setSiteNameSelectedValues(siteNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      if (filters?.selectedSiteName) {
        if (filters?.selectedSiteName?.[0]?.id === "all") {
          const site = filters?.allSiteIds?.find((site) => site?.id === "all");
          setShipToSelectedValues([site]);
        } else {
          const site = filters?.allSiteIds?.find(
            (site) => site?.siteName === filters?.selectedSiteName
          );
          if (site) {
            setShipToSelectedValues([site]);
          }
        }
      } else {
        setShipToSelectedValues([]);
      }

      const allProducts = getAllProducts();
      const selectedProduct = allProducts?.[0];
      const selectedProducts = selectedProduct ? [selectedProduct] : [];

      setFilters((prev) => {
        return {
          ...prev,
          products: allProducts,
          selectedProducts: selectedProducts,
          tankGroup: [],
        };
      });
      if (allProducts?.length) {
        setProductSelectedValues([allProducts[0]]);
      } else {
        setProductSelectedValues([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedSiteName]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      const siteData = filters?.allSiteNames?.find(
        (site) => site?.id === siteNameSelectedValues?.[0]?.id
      );
      const site = filters?.allSiteIds?.find(
        (site) => site?.globalSiteId === siteData?.globalSiteId
      );
      const selectedShipTo = site && siteData ? [site] : [];
      setFilters((prev) => {
        return {
          ...prev,
          selectedShipTo: selectedShipTo,
          selectedSiteName: siteNameSelectedValues,
        };
      });

      setShipToSelectedValues(selectedShipTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  useEffect(() => {
    const tankGroupsData = getTankGroupWithTankList(masterData, filters, true);
    const selectedTanks = tankGroupsData?.length > 0 ? [tankGroupsData[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        tankGroup: tankGroupsData,
        selectedTanks: selectedTanks,
      };
    });
    let tankGroups = selectedTanks;
    if (selectedTanks?.length && selectedTanks?.[0]?.items) {
      tankGroups = [...selectedTanks, ...selectedTanks?.[0]?.items];
    }
    if (tankGroupsData?.length) {
      setTankSelectedValues(tankGroups);
    } else {
      setTankSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedProducts]);

  // useEffect(() => {
  //   loadData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters.selectedTanks]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTanks: tankSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues(
        getMultiSelectTreeValue(filters?.allSiteIds, {
          ...multiSelectionDropDownFields,
          ...event,
          value: shipToSelectedValues,
        })
      );
    }
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    if (event.operation === "delete") {
      setSiteNameSelectedValues([]);
    } else {
      setSiteNameSelectedValues(
        getMultiSelectTreeValue(filters?.allSiteNames, {
          ...multiSelectionDropDownFields,
          ...event,
          value: siteNameSelectedValues,
        })
      );
    }
  };

  const onProductSelection = (event) => {
    if (event.operation === "delete") {
      setProductSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(
        event,
        productSelectedValues
      );
      setProductSelectedValues(
        getMultiSelectTreeValue(filters?.products, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, tankSelectedValues);
      setTankSelectedValues(
        getMultiSelectTreeValue(filters?.tankGroup, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      const selectedValues = getAllSelectedOptions(event, regionSelectedValues);
      setRegionSelectedValues(
        getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        })
      );
    }
  };

  const fetchMasterData = async () => {
    try {
      setGainLossData([]);
      setShowError(false);
      setFilterDataLoading(true);

      const payload = {
        country: currentRegion,
      };
      const response = await reportsService.fetchToleranceTrendMasterData(
        payload
      );
      setFilterDataLoading(false);
      if (response) {
        setMasterData([...response]);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Unable to fetch the data.",
          })
        );
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: {
            style: "error",
            icon: true,
          },
          message: "Server error. Unable to fetch data.",
        })
      );
      setError("Server error. Unable to fetch data.");
      setFilterDataLoading(false);
      setError("");
    }
  };

  const getUpdatedResponse = (response) => {
    response?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
    const cummDiffData = {};

    let totalSales = null;
    let totalDeliveries = null;
    let totalDifferences = null;
    let totalDifferencePercent = null;
    let openingStock = null;
    let closingStock = null;

    const region = filters?.selectedShipTo?.[0]?.region;

    let updatedData = response?.map((item) => {
      const sales = item?.sales ? Math.round(item?.sales) : item?.sales;
      const stock = item?.stock ? Math.round(item?.stock) : item?.stock;
      const deliveries = item?.deliveries
        ? Math.round(item?.deliveries)
        : item?.deliveries;
      const differences = item?.differences
        ? Math.round(item?.differences)
        : item?.differences;

      if (!openingStock) {
        openingStock = stock;
      }
      closingStock = stock;
      //Diff Percent
      const differenceVal = differences && sales ? differences / sales : 0;
      const diffPercent = differenceVal ? Math.round(differenceVal * 100) : 0;
      if ((sales || sales === 0) && !totalSales) {
        totalSales = 0 + sales;
      } else if (sales) {
        totalSales += sales;
      }

      if ((deliveries || deliveries === 0) && !totalDeliveries) {
        totalDeliveries = 0 + deliveries;
      } else if (deliveries) {
        totalDeliveries += deliveries;
      }

      // if ((differences || differences === 0) && !totalDifferences) {
      //   totalDifferences = 0 + differences;
      // } else if (differences) {
      //   totalDifferences += differences;
      // }

      // if ((diffPercent || diffPercent === 0) && !totalDifferencePercent) {
      //   totalDifferencePercent = 0 + diffPercent;
      // } else if (diffPercent) {
      //   totalDifferencePercent += diffPercent;
      // }

      const cumulateType =
        selectedTab === GainLossTabOptions.MATERIAL
          ? parseInt(item?.material)
          : item?.tankGroup;

      let outBound = item?.outBound?.replace(/ /g, '');
      const regex = new RegExp(",", "g");
      outBound = outBound.replace(regex, "\n");

      //Cumm Differences
      cummDiffData[cumulateType] = cummDiffData?.[cumulateType]
        ? cummDiffData?.[cumulateType]
        : {};

      const cummDiff =
        (cummDiffData?.[cumulateType]?.difference
          ? cummDiffData?.[cumulateType]?.difference
          : 0) + differences;
      cummDiffData[cumulateType].difference = cummDiff;

      //Cumm Sales
      const cumSales =
        (cummDiffData?.[cumulateType]?.sales
          ? cummDiffData?.[cumulateType]?.sales
          : 0) + sales;
      cummDiffData[cumulateType].sales = cumSales;

      //cumDiffPercent
      const diffPercentVal =
        cummDiff === 0 && cumSales === 0 ? 0 : cummDiff / cumSales;
      const cumDiffPercent = diffPercentVal
        ? Math.round(diffPercentVal * 100)
        : diffPercentVal;

      return {
        ...item,
        sales: sales,
        stock: stock,
        deliveries: deliveries,
        differences: differences,
        outBound: outBound,
        diffPercent: `${diffPercent}%`,
        cumDiff: cummDiff,
        cumSales: cumSales,
        cumDiffPercent:
          cumDiffPercent !== -Infinity && cumDiffPercent !== Infinity
            ? `${cumDiffPercent}%`
            : null,
      };
    });

    if (
      filters?.selectedProducts?.length === 1 &&
      filters?.selectedProducts?.[0]?.id !== "all"
    ) {
      totalDifferences =
        closingStock + totalSales - (openingStock + totalDeliveries);
      const variancePercent = (totalDifferences / totalSales) * 100;
      totalDifferencePercent =
        totalDifferences === 0 ? 0 : Math.round(variancePercent);
      //single product selection
      setFooterItem({
        region: region,
        openingStock: openingStock,
        closingStock: closingStock,
        sales: totalSales,
        deliveries: totalDeliveries,
        differences: totalDifferences,
        diffPercent:
          totalDifferencePercent !== -Infinity &&
          totalDifferencePercent !== Infinity
            ? `${totalDifferencePercent}%`
            : null,
      });
    }

    // //Total Footer Row
    // if (updatedData?.length > 0) {
    //   const footerItem = {
    //     plant: 'TOTAL',
    //     locked: true,
    //     date: 'Opening Stcok  1000',
    //     sales: totalSales,
    //     stock: totalStock,
    //     deliveries: totalDeliveries,
    //     differences: totalDifferences,
    //     diffPercent: `${totalDifferencePercent}%`,
    //   };
    //   updatedData = [...updatedData, footerItem];
    // }

    return updatedData;
  };

  const fetchGainLossData = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    let selectedProducts = filter?.selectedProducts?.map(
      (product) => product?.id
    );

    const filteredProducts =
      selectedProducts?.[0] === "all" ? [] : selectedProducts;

    const selectedShipTo = filter?.selectedShipTo?.[0]?.id;

    const selectedTankIds = filter?.selectedTanks?.map((tank) => tank?.id);
    const filteredTanks = selectedTankIds?.[0] === "all" ? [] : selectedTankIds;

    const startDate = fromDate && getFormattedDate(fromDate);
    const endDate = toDate && getFormattedDate(toDate);
    const region = filter?.selectedRegions?.[0]?.id;
    const countryCode = filter?.selectedRegions?.[0]?.countryCode;
    const shipTo = filter?.selectedShipTo?.[0]?.id;

    let payload = {
      RegionOrCountryCode: countryCode,
      FromDate: startDate,
      ToDate: endDate,
      GlobalSiteIds: [selectedShipTo],
      MaterialNumbers: filteredProducts,
    };

    let valuesSelected =
      fromDate &&
      toDate &&
      region?.length > 0 &&
      shipTo?.length > 0 &&
      filter?.selectedProducts?.length > 0;

    if (selectedTab === GainLossTabOptions.TANK_GROUP) {
      valuesSelected = valuesSelected && filter?.selectedTanks?.length > 0;
      payload = {
        ...payload,
        TankGroupIds: filteredTanks,
      };
    }

    if (valuesSelected) {
      if (
        filter.selectedProducts?.length > 1 ||
        filter.selectedProducts?.[0]?.id === "all"
      ) {
        setFooterItem(null);
      }
      setShowError(false);
      setSubmitLoading(true);
      try {
        let response = [];
        if (selectedTab === GainLossTabOptions.TANK_GROUP) {
          response = await reportsService.fetchGainLossTankGroupData(payload);
        } else {
          response = await reportsService.fetchGainLossMaterialData(payload);
        }

        if (!refresh) {
          setAppliedFilters(filter);
        }

        response = response?.map((item) => ({
          plant: item?.plant,
          sloc: item?.sloc,
          obn: item?.obn,
          globalSiteId: item?.globalSiteId,
          date: item?.requestDateUTC,
          material: item?.materialNumber,
          outBound: item?.outboundDeliveryIdsStr,
          stock: item?.currentStock,
          sales: item?.sales,
          deliveries: item?.keplerDeliveries,
          differences: item?.variance,
          tankGroup: item?.tankGroupId,
          tankGroupName: item?.tankGroupName,
        }));
        response = getUpdatedResponse(response);

        setSubmitLoading(false);
        setGainLossData(response);
        setResetIndex(!resetIndex);
      } catch (error) {
        dispatch(
          triggerNotification({
            type: {
              style: "error",
              icon: true,
            },
            message: "Server error. Unable to fetch data.",
          })
        );
        setError("Server error. Unable to fetch data.");
        setSubmitLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };

  const StartDateInput = (props) => {
    const dateClassName =
      props.value !== null || !showError ? "date" : "empty-date";
    return (
      <div className="dateContainer">
        <label htmlFor="startDate">Start Date</label>
        <DateInput
          {...props}
          label={undefined}
          className={`${dateClassName}`}
        />
      </div>
    );
  };

  const EndDateInput = (props) => {
    const dateClassName =
      props.value !== null || !showError ? "date" : "empty-date";
    return (
      <div className="dateContainer">
        <label htmlFor="endDate">End Date</label>
        <DateInput
          {...props}
          label={undefined}
          className={`${dateClassName}`}
        />
      </div>
    );
  };

  const handleDatePickerClose = () => {};

  const showHeaders = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <div className="filter-date">
            <DateRangePicker
              defaultValue={filters.selectedDates}
              value={filters.selectedDates}
              startDateInput={StartDateInput}
              endDateInput={EndDateInput}
              onChange={onDateSelection}
              onClose={handleDatePickerClose}
              onOpen={() => {}}
              max={getDaysBeforeDate(currentRegion === "DE" ? 1 : 1)}
              min={getDateBeforeMonths(6)}
            />
          </div>

          <span className="filter">
            <label htmlFor="region">
              {currentRegion === "BENEFRUX" ? "Country" : "Region"}
            </label>
            <MultiSelectionDropDown
              data={filters.regions}
              values={regionSelectedValues}
              onChange={onRegionSelection}
              filterable={false}
              showError={showError}
              customStyle={{
                minWidth: "9vw",
                minHeight: "2vh",
              }}
            />
          </span>

          <div className="filters-container">
            <span className="filter">
              <label htmlFor="shipTo">Ship To</label>
              <MultiSelectionDropDown
                data={filters.allSiteIds}
                values={shipToSelectedValues}
                onChange={onShipToSelection}
                filterable={true}
                showError={showError}
                customStyle={{ minWidth: "7vw", minHeight: "3vh" }}
                maxSelection={1}
              />
            </span>
            <span className="filter">
              <label htmlFor="shipTo">Site Name</label>
              <MultiSelectionDropDown
                data={filters.allSiteNames}
                values={siteNameSelectedValues}
                onChange={onSiteNameSelection}
                filterable={true}
                showError={showError}
                customStyle={{ minWidth: "10vw", minHeight: "3vh" }}
                maxSelection={1}
              />
            </span>
            <span className="filter">
              <label htmlFor="region">Material</label>
              <MultiSelectionDropDown
                data={filters.products}
                values={productSelectedValues}
                onChange={onProductSelection}
                showError={showError}
                filterable={false}
                placeholder="Select ..."
                customStyle={{ minWidth: "10vw", minHeight: "3vh" }}
              />
            </span>
            {selectedTab === GainLossTabOptions.TANK_GROUP && (
              <span className="filter">
                <label htmlFor="region">Tank Group</label>
                <MultiSelectionDropDown
                  data={filters.tankGroup}
                  values={tankSelectedValues}
                  showError={showError}
                  onChange={onTankSelection}
                  filterable={false}
                  placeholder="Select ..."
                  customStyle={{ minWidth: "7vw", minHeight: "3vh" }}
                />
              </span>
            )}
          </div>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                fetchGainLossData();
              }}
              disabled={filterDataLoading}
            >
              {filterDataLoading ? "Fetching..." : "Search"}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchGainLossData(true);
              }}
              disabled={filterDataLoading || !appliedFilters}
            >
              Refresh
            </Button>
          </span>
        </div>
      </div>
    );
  };

  const showGridToolBar = () => {
    return (
      <div className="gridToolbar">
        {showHeaders()}
        {showHeaderBox()}
      </div>
    );
  };

  const BoxItem = ({
    item,
    showIcon = false,
    errorIcon = false,
    link = false,
    linkAction,
  }) => {
    let icon =
      showIcon && !errorIcon && require("../../../../../assets/warning.png");
    if (showIcon && errorIcon) {
      icon = require("../../../../../assets/error.png");
    }
    const value = item?.count;
    const valueAvailable =
      (value || value === 0) && value !== -Infinity && value !== Infinity;
    return (
      <div className="boxContainer">
        <span className="highlighter"></span>
        <span className="box-title">{item?.title}</span>
        {link && (
          <Button
            className="ship-to-link-btn"
            onClick={linkAction}
            disabled={filterDataLoading}
          >
            {value}
          </Button>
        )}
        {!link && valueAvailable && (
          <span className="box-count">{item?.count}</span>
        )}
        {!link && !valueAvailable && showIcon && (
          <Tooltip openDelay={100} position="left" anchorElement="target">
            <img
              className={errorIcon ? "errorIcon" : "warningIcon"}
              title={"Data not received!"}
              src={icon}
              alt="Warning"
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const showHeaderBox = () => {
    const site = filters?.selectedShipTo?.[0];
    return (
      <div className="headerContainer">
        <div className="header-box-container">
          <BoxItem
            item={{
              title: "Region",
              count: site?.region,
            }}
          />
          <BoxItem
            item={{
              title: "Ship To",
              count: site?.globalSiteId,
            }}
            link={true}
            linkAction={() => {
              setShowSiteInfoModal(true);
            }}
          />
          <BoxItem
            item={{
              title: "OBN",
              count: site?.obn,
            }}
          />
          <BoxItem
            item={{
              title: "SLOC",
              count: site?.sloc,
            }}
          />
          <BoxItem
            item={{
              title: "Plant",
              count: site?.plant,
            }}
          />
        </div>
        <Button
          className="refresh-btn"
          onClick={exportData}
          disabled={!gainLossData?.length}
        >
          Export
        </Button>
      </div>
    );
  };

  const showFooterBox = () => {
    return (
      <div className="footer-box-container">
        <BoxItem
          item={{
            title: "Opening Stock",
            count: footerItem?.openingStock,
          }}
          showIcon={true}
          errorIcon={true}
        />
        <BoxItem
          item={{
            title: "Closing Stock",
            count: footerItem?.closingStock,
          }}
          showIcon={true}
          errorIcon={true}
        />
        <BoxItem
          item={{
            title: "Sales",
            count: footerItem?.sales,
          }}
          showIcon={true}
        />
        <BoxItem
          item={{
            title: "Deliveries",
            count: footerItem?.deliveries,
          }}
        />
        <BoxItem
          item={{
            title: "Variance",
            count: footerItem?.differences,
          }}
          showIcon={true}
        />
        <BoxItem
          item={{
            title: "Variance%",
            count: footerItem?.diffPercent,
          }}
          showIcon={true}
        />
      </div>
    );
  };

  return (
    <div className="gain-loss-screen-container data-grid">
      <div className="row ">
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {leakTabComponent()}
          {showGridToolBar()}
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={filters?.selectedShipTo?.[0]?.id}
              countryCode={filters?.selectedShipTo?.[0]?.countryCode}
              close={closeModal}
            />
          )}
          <div className="data-grid-content">
            <AppDataGrid
              pageable={true}
              filterable={true}
              expandable={false}
              sortable={false}
              take={DEFAULT_PAGE_SIZE}
              numericSortFields={["tankGroupName"]}
              style={{ height: footerItem ? "54vh" : "60vh" }}
              data={gainLossData}
              columnsToShow={colsToShow(
                gainLossData,
                selectedTab,
                currentRegion
              )}
              exportFileName="Gain Loss Overview"
              ref={exportGridRef}
              exportData={gainLossExportData}
              columnsToExport={colsToExport(selectedTab, currentRegion)}
              noRecordsMessage="No data found! Please try with different filter selections."
              // rowHeight={30}
              // total={gainLossData?.total}
              // scrollable={"virtual"}
              // pageSize={DEFAULT_INITIAL_PAGE_SIZE}
            />
          </div>
          {footerItem && showFooterBox()}
          {submitLoading && <LoadingPanel message="Please wait..." />}
          {filterDataLoading && <LoadingPanel message="Please wait..." />}
        </div>
      </div>
    </div>
  );
};

export { GainLossOverviewScreen };
