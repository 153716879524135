import React from "react";
import "./out-of-tolerance-trend-analysis.scss";
import { OutOfToleranceTrendAnalysisV2 } from "./out-of-tolerance-trend-analysis-v2";

const OutOfToleranceTrendAnalysisContainerV2 = (props) => {
  return (
    <>
      <div className="out-of-trend-analysis-screen-container">
        <div className="row content-container">
          <OutOfToleranceTrendAnalysisV2 {...props} />
        </div>
      </div>
    </>
  );
};

export { OutOfToleranceTrendAnalysisContainerV2 };
