import { createPortal } from 'react-dom';
import { Loader } from "@progress/kendo-react-indicators";

const overlay = document.querySelector("#overlay-container");

const LoadingOverlay = (props) => {
    return createPortal(
        <div id="overlay">
            <Loader style={{
                position: "relative",
                top: "40%",
                left: "50%",
                right: "50%"
            }} size="medium" themeColor="warning" type="converging-spinner" />
            <span id="text">{props.message || "Loading..."}</span>
        </div>,
        overlay
    );
};

export { LoadingOverlay };