import {
  NotificationGroup,
  Fade,
  Notification,
} from "@progress/kendo-react-all";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeNotification } from "../../../state-management/actions";
import { store } from "../../../state-management/store";

const CustomNotificationsContainer = () => {
  const [showNotification, setShowNotification] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = store.subscribe(async () => {
      const notifications = store.getState()["notification"];
      if (notifications.length > 0) {
        setNotifications([...notifications]);
        setShowNotification(true);
        setTimeout(() => {
          dispatch(closeNotification());
        }, 4000);
      } else {
        setNotifications([]);
        setShowNotification(false);
      }
    });
    return () => {
      unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <NotificationGroup
      style={{ position: "absolute", top: "10%", right: "2%" }}
    >
      <Fade>
        {showNotification &&
          notifications.map((notif, index) => {
            return (
              <Notification
                key={index}
                type={notif.type}
                closable
                onClose={() => dispatch(closeNotification())}
              >
                <span>{notif.message}</span>
              </Notification>
            );
          })}
      </Fade>
    </NotificationGroup>
  );
};
export { CustomNotificationsContainer };
